<template>
<div class="form-row pl-2" v-bind:class="{'shadow-md' : shadow == 1, 'mb-3' : border == 1}">
    <div class="col-md-12 mb-2">
        <span class="fw-700 mb-0"><a :href="'/intern/duties/details/' + dutyLocal.id">
            {{ dutyLocal.title }} {{ dutyLocal.suffix }}
        </a></span><br>
        <small class="text-muted"><span v-if="dutyLocal.orderer_title">{{ dutyLocal.orderer_title }}</span>{{ dutyLocal.orderer_firstname }} {{ dutyLocal.orderer_lastname }}, {{ dutyLocal.orderer_city }}</small><br>
        <span class="badge badge-secondary" v-if="dutyLocal.type == 'Sitzdienst'" style="position: absolute !important; top: 4px !important; right: 10px !important;">Sitzdienst</span>
        <span class="badge badge-secondary" v-if="dutyLocal.type == 'Fahrdienst'" style="position: absolute !important; top: 4px !important; right: 10px !important;">Fahrdienst</span>
    </div>
    <div class="col">
        <!-- Dienst Status hierher - computed color 
            <span class="badge badge-success" v-if="client.status == 'aktiv'">Aktive Praxis</span>
        <span class="badge badge-danger" v-if="client.status == 'inaktiv'">Inaktive Praxis</span> -->
        <div class="form-row mb-2">
            <div class="col-md-5 col-sm-12" style="max-width: 200px;">
                <small>{{ dutyLocal.start | moment("dddd") }}</small><br>
                <strong>{{ dutyLocal.start | moment("DD.MM.YYYY [um] H:mm") }}</strong>
            </div>
            <div class="col-md-1 col-sm-12 rockblue mt-3 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </div>
            <div class="col pl-4">
                <small>{{ dutyLocal.end | moment("dddd") }}</small><br>
                <strong>{{ dutyLocal.end | moment("DD.MM.YYYY [um] H:mm") }}</strong>
            </div>
        </div>
        <div class="form-row mb-1">
            <div
                class="col-md-12" 
                v-if="!hideStatus || dutyLocal.vip || dutyLocal.is_weekend || dutyLocal.is_publicholiday">
                <span class="badge badge-warning float-left mr-3" v-if="!hideStatus">{{ dutyLocal.status_label }}</span>
                <span class="badge badge-danger float-left mr-3" v-if="dutyLocal.vip">VIP-Dienst</span>        
                <span class="badge badge-success float-left mr-3" v-if="dutyLocal.is_weekend">Wochenende</span>
                <span class="badge badge-success float-left" v-if="dutyLocal.is_publicholiday">Feiertag</span>
            </div>
        </div>
        <div class="form-row mb-0" v-if="dutyLocal.notes">
            <p class="fs14 col-md-12 mt-1 mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-1"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg> {{ dutyLocal.notes }}
            </p>
        </div>
        
        <div v-if="enableHiding" class="hidingIcon">
            <a href="#" @click.prevent="hideDuty" class="btn btn-default btn-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
            </a>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: ['duty', 'shadow', 'border', 'enableHiding', 'hideStatus'],
    data() {
        return {
            enableHidingLocal: true,
            dutyLocal: {},
            loading: true,
            action: '/intern/duties/hideForSelector/' + this.duty.id,
            fields: {
                docInCharge: null,
            },
        }
    },
    created() {
        this.enableHidingLocal = this.enableHiding
        this.dutyLocal = this.duty
    },
    methods: {
        hideDuty() {
            this.$confirm('Möchtest du den Dienst für die Zuweisung ausblenden?', {
                confirmButtonText: 'JA',
                cancelButtonText: 'Nein',
                type: 'info',
                center: true
            }).then(() => {
                axios.post(this.action, { timeout: 6000 }).then(response => {                    
                    this.$noty.success("Dienst wurde ausgeblendet!")
                    this.$emit('dutyhidden')
                }).catch(error => {
                    this.$noty.error("Fehler: Die Änderung wurde nicht gespeichert!")
                });
            }).catch(() => {
                this.$noty.warning("Die Änderung wurde nicht gespeichert!")
            });
        },
    }
}
</script>

<style lang="scss">
.hidingIcon {
    position: absolute;
    right: 0;
    top: 18px;

    .btn-default {
        color: #9fb3c8 !important;
    }

    .clear-both {
        clear: both !important;
    }
}
</style>