<template>
  <div>
    <report-reserves-filter-form @dataready="updateReportData" @resetdata="resetReserves"></report-reserves-filter-form>
    <transition name="fade">
      <div v-if="reserves.length" class="mt-5 pt-5 border-top">
        <h3>Ergebnisse</h3>
        <report-reserves-result-table :reserves="reserves" :avg="avg"></report-reserves-result-table>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reserves: [],
      avg: 0
    };
  },
  methods: {
    updateReportData(reserves) {
      this.reserves = [];
      this.reserves = reserves.data;
      this.avg = reserves.avg;
    },
    resetReserves() {
      this.reserves = [];
    }
  }
};
</script>

<style>
</style>