<template>
    <div class="row mt-5 team-member">
        <div class="col-lg-4">
            <img :src="imageurl" :alt="person" class="img circle personalpicture">
        </div>
        <div class="col pt-4">
                <span class="personname">{{ person }}</span>
                <p class="title">{{ persontitle }}</p>
                <div class="bio">
                        <ul class="list-group list-group-flush">
                            <slot></slot>
                        </ul>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        person: String,
        persontitle: String,
        personpicture: String
    },
    computed: {
        imageurl: function () {
        return '/img/team/' + this.personpicture
    }
  }
}
</script>

<style>

</style>
