<template>
    <el-table
        ref="filterTable"
        v-loading="loading"
        stripe
        :data="getTableContent"
        :default-sort="{ prop: 'praxis', order: 'ascending' }"
        style="width: 100%"
    >
        <el-table-column label="Praxis" prop="praxis" sortable>
        </el-table-column>
        <el-table-column label="Gebiet" prop="title" sortable>
        </el-table-column>
        <el-table-column
            label="Ära"
            prop="is_after_bdonline"
            sortable
            width="90px"
            :filters="[
                { text: 'Vor BD-Online', value: 0 },
                { text: 'Nach BD-Online', value: 1 },
            ]"
            :filter-method="filterEra"
        >
            <template slot-scope="scope">
                <el-tag
                    :type="
                        scope.row.is_after_bdonline === 0
                            ? 'primary'
                            : 'success'
                    "
                    disable-transitions
                    >{{
                        scope.row.is_after_bdonline === 0
                            ? "Vor BDO"
                            : "Nach BDO"
                    }}</el-tag
                >
            </template>
        </el-table-column>
        <el-table-column label="Ort" prop="city" sortable> </el-table-column>
        <el-table-column label="Telefon" prop="phone" sortable>
        </el-table-column>
        <el-table-column label="Praxis ID" prop="customerid" sortable>
        </el-table-column>
        <el-table-column fixed="right" align="right">
            <template slot="header" slot-scope="scope">
                <el-input
                    v-model="search"
                    size="mini"
                    placeholder="Name suchen"
                />
            </template>
            <template slot-scope="scope">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Praxis-Infos öffnen"
                    placement="top"
                >
                    <a :href="'/intern/clients/' + scope.row.id">
                        <info-icon class="table-icon"></info-icon>
                    </a>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Praxis bearbeiten"
                    placement="top"
                    v-if="$can('edit clients')"
                >
                    <a
                        :href="'/intern/clients/edit/' + scope.row.id"
                        v-if="$can('edit clients')"
                    >
                        <edit-2-icon class="table-icon"></edit-2-icon>
                    </a>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="E-Mail an die Praxis"
                    placement="top"
                >
                    <a :href="'mailto:' + scope.row.email">
                        <mail-icon class="table-icon"></mail-icon>
                    </a>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Praxis SOFORT LÖSCHEN - noch nicht verfügbar"
                    placement="top"
                    v-if="$can('edit clients')"
                >
                    <user-x-icon
                        class="table-icon table-icon--red"
                        @click="handleDelete(scope.$index, scope.row)"
                    ></user-x-icon>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {
    InfoIcon,
    Edit2Icon,
    MailIcon,
    UserXIcon,
    MessageSquareIcon,
} from "vue-feather-icons";

export default {
    components: {
        InfoIcon,
        Edit2Icon,
        MailIcon,
        UserXIcon,
        MessageSquareIcon,
    },
    props: ["userelements"],
    data() {
        return {
            loading: true,
            listOfObjects: [],
            search: "",
        };
    },
    computed: {
        getTableContent() {
            var listOfObjectsResult = this.listOfObjects.filter(
                (listOfObjects) => {
                    return listOfObjects.praxis
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                }
            );
            this.loading = false;
            return listOfObjectsResult;
        },
    },
    mounted() {
        this.listOfObjects = Object.keys(this.userelements).map((key) => {
            return this.userelements[key];
        });
    },
    methods: {
        clearFilter() {
            this.$refs.filterTable.clearFilter();
        },
        filterEra(value, row) {
            return row.is_after_bdonline === value;
        },
        // filterHandler(value, row, column) {
        //     const property = column["property"];
        //     return row[property] === value;
        // },
    },
};
</script>


<style scoped lang="scss">
.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;

    &--red {
        stroke: #fe6f5e;
    }
}
</style>
