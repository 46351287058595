<template>
<div>
    <el-switch
        v-model="fields.mailinglistactive"
        :active-text="'Verteiler ' + listname"
        @change="toggleList">
    </el-switch>
</div>
</template>

<script>
import FormMixin from '../../FormMixin';

export default {
    mixins: [ FormMixin ],
    props: ['listname', 'listid', 'recipienttype', 'recipientid', 'subscribedto'],
    data() {
        return {
            action: '/intern/lists/toggle/',
            fields: {
                mailinglistactive: false,
                recipienttype: '',
                list_id: '',
                recipientid: '',
                subscribedto: '',
            },
        }
    },
    mounted () {
        this.fields.recipienttype = this.recipienttype
        this.fields.list_id = this.listid
        this.fields.recipientid = this.recipientid
        this.fields.subscribedto = this.subscribedto
        
        if(this.subscribedto !== null && this.subscribedto !== '') {
            this.fields.mailinglistactive = true
        }
    },
    methods: {
        toggleList(newval) {
            this.submit()
        },
        handleResponseResult(response) {
            this.$noty.success("Änderung im Verteiler gespeichert!<br>Vor weiteren Änderungen an der Liste, bitte Seite aktualisieren.");
        },
    }
}
</script>

<style>

</style>