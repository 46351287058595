<template>
    <div class="row mt-4">
        <div class="col-md-12 mt-3">
            <h2>Status</h2>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <!-- <h4>Dienst-Status</h4> -->
                    <div class="row">
                        <status-element
                            status="10_open"
                            status-label="offen"
                            :selected="status == '10_open'"
                        />
                        <status-element
                            status="15_prebooked"
                            status-label="vorgemerkt"
                            :selected="status == '15_prebooked'"
                        />
                        <status-element
                            status="20_assigned"
                            status-label="zugeteilt"
                            :selected="status == '20_assigned'"
                        />
                        <status-element
                            status="30_performed"
                            status-label="geleistet"
                            :selected="status == '30_performed'"
                        />
                        <status-element
                            status="40_invoice_received"
                            status-label="erhalten"
                            :selected="status == '40_invoice_received'"
                        />
                        <status-element
                            status="50_checked"
                            status-label="abgrechnet"
                            :selected="status == '50_checked'"
                        />
                        <status-element
                            status="60_done"
                            status-label="bezahlt"
                            :selected="status == '60_done'"
                        />
                        <status-element
                            v-if="status == '100_cancelled'"
                            status="100_cancelled"
                            status-label="storniert"
                            :selected="status == '100_cancelled'"
                        />
                    </div>
                    <div class="row text-center" v-if="$can('edit duty')">
                        <button
                            class="btn btn-danger mx-auto mt-3"
                            @click="resetStatusTo30Done()"
                        >
                            Status RESET auf Geleistet zur Abrechnung
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["status", "dutyid"],
    data() {
        return {
            action: "/intern/duties/resetToBillable/",
            requestPending: true,
        };
    },
    computed: {
        resettable() {
            return this.status.substring(0, 2) < 30;
        },
    },
    methods: {
        async resetStatusTo30Done() {
            var url = this.action + this.dutyid;
            axios
                .post(url)
                .then((response) => {
                    this.$noty.success("Status erfolgreich zurückgesetzt!");
                    console.log("Status has been reset");
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 405) {
                        this.$noty.error(
                            "Aus diesem Status kann man nicht wechseln!"
                        );
                    } else {
                        this.$noty.error(
                            "Fehler beim Status ändern! Bitte noch einmal versuchen."
                        );
                    }
                });
        },
    },
};
</script>

<style>
</style>
ax
