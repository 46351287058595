<template>
<div class="form-row mb-4">
  <div class="col">
    <el-date-picker
      v-model="value7"
      type="datetimerange"
      align="right"
      format="dd.MM.yyyy HH:mm"
      start-placeholder="Vertretungsbeginn"
      end-placeholder="Vertretungsende"
      :default-time="['12:00:00', '12:00:00']">
    </el-date-picker>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        value6: '',
        value7: ''
      };
    }
  };
</script>