<template>
    <div
        class="col"
        v-if="
            (this.duty.orderer.email || this.duty.orderer.client.email) &&
            this.duty.status == '20_assigned'
        "
    >
        <div class="card mt-4">
            <div class="card-body quick-action" @click="open">
                <svg
                    v-if="!duty.reminder_mail_date"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="
                        feather feather-message-circle
                        quick-action--icon
                        mr-1
                    "
                >
                    <path
                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                    />
                </svg>
                <span v-if="duty.reminder_mail_date" class="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        style="vertical-align: -webkit-baseline-middle"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="
                            feather feather-check-circle
                            quick-action--icon
                            mr-1
                        "
                    >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                    erledigt {{ duty.reminder_mail_date | formatDate }}
                </span>
                <br />2. Erinnern per Mail
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["duty"],
    data() {
        return {
            action: "/intern/duties/log7DayReminder/" + this.duty.id,
            fields: {
                submit_date: null,
            },
            // mailurl: 'mailto:' + this.duty.orderer.client.email,
            bodyone:
                "Sehr%20geehrte%2Fr%20Frau%2FHerr%20...%0D%0A%0D%0Adiese%20Mail%20dient%20als%20freundliche%20Erinnerung%2C%20dass%20ich%20als%20Vertreter%2Fin%20des%20%C3%84RZTETEAM%20LUPPE%20Ihren%20kommenden%20Notdienst%20am%20",
            bodytwo:
                "%20wie%20vereinbart%20%C3%BCbernehmen%20werde.%20Bitte%20ignorieren%20Sie%20daher%20Mails%20der%20KV%20bez%C3%BCglich%20dieses%20Dienstes.%0D%0A%0D%0ANach%20dem%20Dienst%20werde%20ich%20Ihnen%20die%20Unterlagen%20%2F%20Rechnungen%20zukommen%20lassen.%0D%0A%0D%0ABei%20Fragen%20kontaktieren%20Sie%20mich%20gerne.%0D%0A%0D%0AIch%20w%C3%BCnsche%20Ihnen%20eine%20gute%20Zeit%20und%20gr%C3%BCsse%20Sie%20herzlich.%20%0A%0A" +
                encodeURIComponent(this.duty.doc_on_duty.fullname) +
                "%20%0AMobil:%20" +
                encodeURIComponent(this.duty.doc_on_duty.phone_mobile),
        };
    },
    computed: {
        mailbody() {
            return;
        },
        mailurl() {
            if (
                this.duty.orderer.client.registration_path === "Arzt" &&
                this.duty.orderer.email
            ) {
                return "mailto:" + this.duty.orderer.email;
            } else {
                if (this.duty.orderer.client.email) {
                    // wenn anmelden über arzt, nimm arzt, sonst nimm praxis
                    return "mailto:" + this.duty.orderer.client.email;
                }
            }

            return "#";
        },
        mailurlWithSubjectAndBody() {
            return (
                this.mailurl +
                "?subject=Ärzteteam Luppe - Ihr Dienst am " +
                this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
                "&body=" +
                this.bodyone +
                this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
                this.bodytwo
            );
        },
    },
    methods: {
        open() {
            this.openMailProgram();
            this.$prompt(
                "Dein E-Mail-Programm hat sich geöffnet und einen Textvorschlag präsentiert.<br><br><strong>Wann hast du die E-Mail zu Erinnerung 7 Tage vor dem Dienst verschickt?</strong>",
                "Erinnerung zum Dienst",
                {
                    confirmButtonText: "Bestätigen",
                    cancelButtonText: "Nein, noch nicht",
                    inputType: "date",
                    roundButton: true,
                    inputPattern:
                        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                    inputErrorMessage: "Ungültiges Datum",
                    center: true,
                    dangerouslyUseHTMLString: true,
                }
            )
                .then((value) => {
                    this.fields.submit_date = value.value;
                    axios
                        .post(this.action, this.fields, { timeout: 6000 })
                        .then((response) => {
                            this.$noty.success("Erinnerung wurde vermerkt!");
                        })
                        .catch((error) => {
                            this.$noty.error(
                                "Erinnerung konnte nicht vermerkt werden!"
                            );
                        });
                })
                .catch(() => {
                    this.$noty.error(
                        "Erinnerung konnte nicht vermerkt werden!"
                    );
                });
        },
        openMailProgram() {
            // const url = window.URL.new(this.mailurl)
            const link = document.createElement("a");
            link.href = this.mailurlWithSubjectAndBody;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>

<style>
</style>
