<template>
    <div
        class="form-row pl-2 pb-3 pt-2 bg-white"
        v-bind:class="{ 'shadow-md': shadow == 1, 'mb-5': border == 1 }"
    >
        <div class="col-md-3">
            <span
                class="fw-700 mb-0"
                :class="{ 'text-striked': duty.cancellation_type }"
            >
                <a :href="'/intern/duties/details/' + duty.id"
                    >{{ duty.orderer.client.area.title }} {{ duty.suffix }}</a
                >
            </span>
            <br />
            <small class="text-muted"
                >{{ duty.orderer.fullname }},
                {{ duty.orderer.client.city }}</small
            >
            <br />
            <div class="form-row mt-2 ml-0 mb-3">
                <span
                    class="badge badge-secondary"
                    v-if="duty.type == 'Sitzdienst'"
                    >Sitzdienst</span
                >
                <span
                    class="badge badge-secondary"
                    v-if="duty.type == 'Fahrdienst'"
                    >Fahrdienst</span
                >
            </div>
        </div>
        <div class="col">
            <!-- Dienst Status hierher - computed color
            <span class="badge badge-success" v-if="client.status == 'aktiv'">Aktive Praxis</span>
      <span class="badge badge-danger" v-if="client.status == 'inaktiv'">Inaktive Praxis</span>-->
            <div class="form-row mb-2">
                <div class="col-md-5 col-sm-12" style="max-width: 190px">
                    <small>{{ duty.start | moment("dddd") }}</small>
                    <br />
                    <strong>{{
                        duty.start | moment("DD.MM.YYYY [um] H:mm")
                    }}</strong>
                    <br />
                    <small class="rockblue">START</small>
                </div>
                <div
                    class="col-md-1 col-sm-12 d-none d-md-block d-lg-block d-xl-block rockblue mt-3 text-center"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-right"
                    >
                        <polyline points="9 18 15 12 9 6" />
                    </svg>
                </div>
                <div class="col pl-4">
                    <small>{{ duty.end | moment("dddd") }}</small>
                    <br />
                    <strong>{{
                        duty.end | moment("DD.MM.YYYY [um] H:mm")
                    }}</strong>
                    <br />
                    <small class="rockblue">ENDE</small>
                </div>
            </div>
            <div class="form-row mb-2">
                <span class="badge badge-warning float-left mr-3">{{
                    duty.status_label
                }}</span>
                <span class="badge badge-danger float-left mr-3" v-if="duty.vip"
                    >VIP-Dienst</span
                >
                <span
                    class="badge badge-success float-left mr-3"
                    v-if="duty.is_weekend"
                    >Wochenende</span
                >
                <span
                    class="badge badge-success float-left"
                    v-if="duty.is_publicholiday"
                    >Feiertag</span
                >
            </div>
            <div class="form-row mb-2 d-flex align-middle">
                <div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Anmeldung per E-Mail"
                        placement="top"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-radio text-icon d-inline-block"
                            alt="Anmeldung per E-Mail"
                        >
                            <circle cx="12" cy="12" r="2"></circle>
                            <path
                                d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"
                            ></path>
                        </svg>
                    </el-tooltip>
                </div>
                <small v-if="duty.registration_mail_date">{{
                    duty.registration_mail_date | moment("DD.MM.YYYY")
                }}</small
                ><small v-else>-</small>

                <div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Bestätigung durch Praxis"
                        placement="top"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            class="feather feather-user-check text-icon ml-4 d-inline-block"
                        >
                            <path
                                d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                            ></path>
                            <circle cx="8.5" cy="7" r="4"></circle>
                            <path d="m17 11 2 2 4-4"></path>
                        </svg>
                    </el-tooltip>
                </div>
                <small v-if="duty.confirmed_date">{{
                    duty.confirmed_date | moment("DD.MM.YYYY")
                }}</small
                ><small v-else>-</small>

                <div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Erinnerung per E-Mail"
                        placement="top"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-message-circle text-icon ml-4 d-inline-block"
                        >
                            <path
                                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                            ></path>
                        </svg>
                    </el-tooltip>
                </div>
                <small v-if="duty.reminder_mail_date">{{
                    duty.reminder_mail_date | moment("DD.MM.YYYY")
                }}</small
                ><small v-else>-</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["duty", "shadow", "border"],
};
</script>

<style>
</style>
