<template>
  <div class="card mb-5">
    <div class="card-body">
      <h3>Neues Gebiet anlegen</h3>
      <p
        class="rockblue subhead mb-2 subhead-sm"
      >Gebiete werden genutzt, um Informationen zu den Eigenheiten des Gebietes und z.B. Hotels an die Vertreter bereitzustellen und zudem, um Kunden einem Gebiet zuzuordnen. So ist eine einfacherer Verteilung möglich.</p>

      <div class="form-row mb-4 mt-4">
        <div class="col">
          <label for="title">Name des Gebietes</label>
          <input
            type="text"
            class="form-control"
            :class="errors && errors.title ? 'form-error' : ''"
            id="title"
            v-model="fields.title"
          />
          <form-error v-if="errors && errors.title" :errortext="errors.title[0]"></form-error>
        </div>
      </div>

      <div class="form-row mb-4 mt-4">
        <div class="col">
          <label for="title">Beschreibung</label>
          <vue-editor v-model="fields.description" :editorToolbar="customToolbar"></vue-editor>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="col">
          <label for="fileupload">
            Gebiets-Info als PDF
            <br />
            <span
              class="text-danger"
              v-if="editmode"
            >Achtung: Die bestehende Datei wird überschrieben</span>
          </label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              @change="onFileChange"
              :class="errors && errors.fileupload ? 'form-error' : ''"
              id="fileupload"
              placeholder="Gebietsinfo PDF"
            />
            <label for="fileupload" class="custom-file-label">Datei auswählen...</label>
            <form-error v-if="errors && errors.fileupload" :errortext="errors.fileupload[0]"></form-error>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" v-if="area">
        <input type="hidden" name="areaid" :value="areadata.id" id="areaid" ref="areaid" />
        <div class="col">
          <label for="my-files">Weitere Anhänge</label>
          <file-pond
            name="uploadfile"
            ref="pond"
            :server="server"
            class-name="my-pond"
            label-idle="Ergänzende Dateien (Bilder, PDF) hier ablegen..."
            allow-multiple="false"
            accepted-file-types="image/*, application/pdf"
            v-bind:files="fields.files"
            v-on:init="handleFilePondInit"
          />
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="col">
          <button
            class="btn btn-primary"
            :disabled="!loaded"
            @click.prevent="submit"
          >Gebiet speichern</button>
          <a href="/intern/areas/" class="btn btn-default" v-if="editmode">Zurück zur Liste</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

import vueFilePond, { setOptions } from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

// setOptions({
//     server: {
//         process: {
//             headers: {
//                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
//             }
//         }
//     }
// });

export default {
  components: {
    VueEditor,
    FilePond
  },
  props: ["area", "csrf"],
  data() {
    return {
      action: "/intern/areas",
      fields: {
        title: "",
        description: "",
        fileupload: null,
        files: []
      },
      errors: {},
      success: false,
      loaded: true,
      resultset: {},
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        [{ header: "1" }, { header: "2" }, { header: "3" }]
      ],
      areadata: [],
      editmode: false,
      uploadurl: "",
      server: {}
    };
  },
  mounted() {
    if (this.area) {
      this.areadata = JSON.parse(this.area);
      this.fields.title = this.areadata.title;
      this.fields.description = this.areadata.description;
      this.editmode = true;
      this.uploadurl = "/intern/areas/" + this.areadata.id + "/upload";
      this.server = {
        url: this.uploadurl,
        process: {
          headers: {
            "X-CSRF-TOKEN": this.csrf
          }
        }
      };
    }
  },
  methods: {
    handleResponseResult() {
      // location.reload()
      this.$noty.success("Das Gebiet wurde gespeichert!");
      window.location.replace("/intern/areas/");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fields.fileupload = e.target.files[0];
    },
    handleFilePondInit: function() {
      console.log("FilePond has initialized");
      this.$refs.pond.getFiles();
    },
    submit() {
      var app = this;
      if (this.loaded) {
        this.loaded = false;
        this.success = false;
        this.errors = {};

        if (this.editmode) {
          this.action = "/intern/areas/update/" + this.areadata.id;
        }

        let formData = new FormData();
        formData.append("title", this.fields.title);
        formData.append("description", this.fields.description);
        if (this.fields.fileupload) {
          formData.append(
            "fileupload",
            this.fields.fileupload,
            this.fields.name
          );
        }

        axios
          .post(this.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            this.loaded = true;
            this.success = true;
            if (app.editmode) {
              app.handleResponseResult();
            } else {
              app.fields = {
                title: "",
                description: "",
                fileupload: null
              };
              app.$emit("itemAdded", response.data);
            }
          })
          .catch(error => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.selectedChoice {
  border: 2px solid #00d995;
  // box-shadow: 0px 1px 35px 0px rgba(0, 217, 149, 0.3);
  box-shadow: none;

  background: #9caebc;
  color: #f5f6f7;
  font-weight: 600;
  border: none;

  svg {
    // stroke: #1C2F51;
    stroke: #f5f6f7;
  }
}

.selectCard {
  padding: 10px;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.inactiveCard {
  border: 2px solid #f5f6f7;
  box-shadow: none !important;
}
</style>
