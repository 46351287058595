<template>
    <div
        class="col"
        v-if="
            (this.duty.orderer.email || this.duty.orderer.client.email) &&
            this.duty.status == '20_assigned'
        "
    >
        <div class="card mt-4">
            <div class="card-body quick-action" @click="open">
                <svg
                    v-if="!duty.confirmed_date"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    style="vertical-align: -webkit-baseline-middle"
                    class="feather feather-user-check quick-action--icon mr-1"
                    viewBox="0 0 24 24"
                >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                    <circle cx="8.5" cy="7" r="4" />
                    <path d="m17 11 2 2 4-4" />
                </svg>

                <span v-if="duty.confirmed_date" class="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        style="vertical-align: -webkit-baseline-middle"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="
                            feather feather-check-circle
                            quick-action--icon
                            mr-1
                        "
                    >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                    erledigt {{ duty.confirmed_date | formatDate }}
                </span>
                <br />Bestätigung Praxis
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["duty"],
    data() {
        return {
            action: "/intern/duties/confirmationLog/" + this.duty.id,
            fields: {
                submit_date: null,
            },
        };
    },
    computed: {},
    methods: {
        open() {
            this.$prompt(
                "Wann hat die Praxis den Dienst dir gegenüber noch einmal bestätigt?",
                "Bestätigung deiner Vertretung",
                {
                    confirmButtonText: "Speichern",
                    cancelButtonText: "Abbrechen",
                    inputType: "date",
                    roundButton: true,
                    inputPattern:
                        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                    inputErrorMessage: "Ungültiges Datum",
                    center: true,
                }
            )
                .then((value) => {
                    // console.log(value.value);
                    this.fields.submit_date = value.value;
                    axios
                        .post(this.action, this.fields, { timeout: 6000 })
                        .then((response) => {
                            this.$noty.success("Bestätigung wurde vermerkt!");
                        })
                        .catch((error) => {
                            this.$noty.error(
                                "Bestätigung konnte nicht vermerkt werden!"
                            );
                        });
                })
                .catch(() => {
                    this.$noty.error(
                        "Bestätigung konnte nicht vermerkt werden!"
                    );
                });
        },
    },
};
</script>

<style>
</style>
