<template>
  <div id="infoItemList">
      <div v-if="categories.length == 0" class="row">
        <div class="col">
          <img src="/img/undraw_blank_canvas.svg" class="img img-fluid" />
        </div>
      </div>
      <div v-else>
        <div id="searchfield" class="mt-4 pull-right text-right">
          <el-input
                v-model="search"
                prefix-icon="el-icon-search"
                size="medium"
                placeholder="Unterlage suchen"/>
        </div>
        <div v-for="cat in getCategoryContent"  v-show="cat.infoitems.length > 0" :key="cat.id" class="mt-5">
        <div class="category-title">
          <h2 :ref="'cat-title-' + cat.id">{{ cat.title }}</h2>
        </div>
        
        <div class="category-infoitems-list">
            <div v-for="infoItem in cat.infoitems" :key="infoItem.id" :id="'infoitem_' + infoItem.id" :categoryId="cat.id" :infoItemId="infoItem.id" class="card mt-4 mb-4">
              <div class="card-body">
                <div class="infoitem__title">
                  {{ infoItem.title }} 
                </div>
                <div class="infoitem__description mb-3">{{ infoItem.description }}</div>
                <div class="infoitem__file" :id="'infoitem__file' + infoItem.id" v-if="infoItem.filename">
                  <a :href="'/intern/infoitems/' + infoItem.id + '/file/download'" @click="downloadStartedInfo(infoItem)">
                    <download-cloud-icon class="infoitem__file--icon"></download-cloud-icon> <strong>{{ infoItem.filename }}</strong>
                  </a>
                </div>
                <div class="infoitem__link" :id="'infoitem__link' + infoItem.id"  v-if="infoItem.linkurl">
                  <arrow-up-right-icon class="infoitem__file--icon"></arrow-up-right-icon><a :href="infoItem.linkurl" target="_blank">{{ infoItem.linkurl }}</a>
                </div>

                <template v-if="!infoItem.filename">
                  <div class="card" v-if="!infoItem.linkurl">
                      <a :href="'/intern/infoitems/' + infoItem.id + '/changeFileOrLink'" class="btn btn-info btn-sm">Datei oder Link hinzufügen</a>
                  </div>
                </template>
              </div> 
            </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
import { ShuffleIcon, MenuIcon, DownloadCloudIcon, ArrowUpRightIcon, Trash2Icon } from "vue-feather-icons";

export default {
  components: {
    ShuffleIcon,
    MenuIcon,
    DownloadCloudIcon,
    ArrowUpRightIcon,
    Trash2Icon
  },
  props: [
    'listdata'
  ],
  data() {
    return {
      categories: [],
      search: ''
    };
  },
  computed: {
      getCategoryContent() { 
          var listOfObjectsResult = this.categories.filter((categories) => {
              // return categories.title.toLowerCase().includes(this.search.toLowerCase());
              return categories.infoitems.filter((infoitem) => infoitem.title.toLowerCase().includes(this.search.toLowerCase())).length
          });
          
          return listOfObjectsResult;
      },
  },
  mounted () {
    this.categories = JSON.parse(this.listdata);
  },
  methods: {
    downloadStartedInfo(infoItem) {
      this.$noty.success("Download von " + infoItem.filename + " gestartet!")
    }
  }
};
</script>

<style lang="scss" scoped>

#searchfield {
  width: 100%;
  max-width: 300px;
}

.infoitem__title {
  font-weight: bold;

  .dropdown-toggle:after { content: none }
}

.infoitem__description {
  color: #9fb3c8;
  font-size: 14px;
  margin-top: 12px;
}

.infoitem--icons {
  float: right;

  .icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;
  }

  .moveicon {
    cursor: move;
    cursor: -webkit-grabbing;
  }

  .dropdown-item {
    
    font-size: 14px;
    line-height: 24px;
    padding-left: 12px;

    .icon {
      margin-left: 0;
      margin-right: 6px;
    }

    .icon-red {
      stroke: #FE6F5E;
    }
  }
}

.infoitem__file {
  &--icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    margin-right: 8px;
  }
}
</style>
