<template>
<div class="row mt-4">
    <div class="col-md-12 mt-4">
        <h2>Vertretungsgebiet {{ area.title }}</h2>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h4 class="mb-4">Hauptinfos / Hotels</h4>
                <transition name="fade">
                    <div v-if="area.description && !showmore" v-html="area.description.substring(0,180)+'...'"></div>
                </transition>
                
                <transition name="fade">
                    <div v-html="area.description" v-if="showmore"></div>
                </transition>

                <button class="btn btn-secondary btn-sm" v-if="!showmore" @click="showmore = !showmore">Mehr Infos zum Gebiet...</button>
                <button class="btn btn-secondary btn-sm" v-if="showmore" @click="showmore = !showmore">Weniger Infos zum Gebiet...</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['area'],
    data() {
        return {
            showmore: false,
        }
    }
}
</script>

<style>

</style>