<template>
    <el-table
        :data="getTableContent"
        :default-sort="{ prop: 'fullnameReverse', order: 'ascending' }"
        style="width: 100%"
    >
        <el-table-column label="Name" prop="fullnameReverse" sortable>
        </el-table-column>
        <el-table-column label="E-Mail" prop="email" sortable>
        </el-table-column>
        <el-table-column label="Ort" prop="city" sortable> </el-table-column>
        <el-table-column
            label="Nach BD Online"
            prop="is_after_bdonline"
            sortable
        >
            <template slot-scope="scope">
                <span
                    class="badge badge-info"
                    v-if="scope.row.is_after_bdonline"
                    >Nach BD Online</span
                >
            </template>
        </el-table-column>
        <el-table-column align="right">
            <template slot="header" slot-scope="scope">
                <el-input
                    v-model="search"
                    size="mini"
                    placeholder="Name suchen"
                />
            </template>
            <template slot-scope="scope">
                <a :href="'/intern/users/details/' + scope.row.id">
                    <info-icon class="table-icon"></info-icon>
                </a>
                <a
                    :href="'/intern/users/edit/' + scope.row.id"
                    v-if="$can('edit users')"
                >
                    <edit-2-icon class="table-icon"></edit-2-icon>
                </a>
                <a :href="'mailto:' + scope.row.email">
                    <mail-icon class="table-icon"></mail-icon>
                </a>
                <!-- Send SMS - Rückrufbitte?! -->
                <user-x-icon
                    class="table-icon table-icon--red"
                    v-if="$can('edit users') && !scope.row.deleted_at"
                    @click="handleDelete(scope.row.id)"
                ></user-x-icon>
                <user-plus-icon
                    class="table-icon"
                    v-if="$can('edit users') && scope.row.deleted_at"
                    @click="handleRestore(scope.row.id)"
                ></user-plus-icon>
                <a
                    :href="'/impersonate/take/' + scope.row.id"
                    v-if="$can('view telescope')"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-aperture table-icon"
                    >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                        <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                        <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                        <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                        <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                        <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
                    </svg>
                </a>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {
    InfoIcon,
    Edit2Icon,
    MailIcon,
    UserXIcon,
    MessageSquareIcon,
    UserPlusIcon,
} from "vue-feather-icons";

export default {
    components: {
        InfoIcon,
        Edit2Icon,
        MailIcon,
        UserXIcon,
        MessageSquareIcon,
        UserPlusIcon,
    },
    props: ["userelements"],
    data() {
        return {
            loading: false,
            listOfObjects: [],
            search: "",
        };
    },
    computed: {
        getTableContent() {
            var listOfObjectsResult = this.listOfObjects.filter(
                (listOfObjects) => {
                    return listOfObjects.fullname
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                }
            );

            return listOfObjectsResult;
        },
    },
    mounted() {
        this.listOfObjects = Object.keys(this.userelements).map((key) => {
            return this.userelements[key];
        });
    },
    methods: {
        handleDelete(id) {
            this.$confirm(
                "Möchtest du den Vertreter löschen? Du kannst ihn/sie später wiederherstellen.",
                "Warnung",
                {
                    confirmButtonText: "Ja, jetzt löschen",
                    cancelButtonText: "Abbrechen",
                    type: "warning",
                }
            )
                .then(() => {
                    console.log(id);
                    // Delete the person and refresh page
                    var url = "/intern/users/delete/" + id;
                    axios
                        .delete(url)
                        .then((response) => {
                            console.log("User deleted");
                            location.reload();
                            window.scroll(0, 0);
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$noty.error(
                                "Fehler beim Löschen! Bitte noch einmal versuchen."
                            );
                        });
                })
                .catch(() => {
                    this.$noty.info("Löschen abgebrochen!");
                });
        },
        handleRestore(id) {
            this.$confirm(
                "Möchtest du den Vertreter wiederbeleben? Er/sie hat danach wieder Zugriff auf das System!",
                "Warnung",
                {
                    confirmButtonText: "Ja, jetzt wiederbeleben",
                    cancelButtonText: "Abbrechen",
                    type: "warning",
                }
            )
                .then(() => {
                    console.log(id);
                    // Delete the person and refresh page
                    var url = "/intern/users/restore/" + id;
                    axios
                        .post(url)
                        .then((response) => {
                            console.log("User restored");
                            location.reload();
                            window.scroll(0, 0);
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$noty.error(
                                "Fehler beim Wiederbeleben! Bitte noch einmal versuchen."
                            );
                        });
                })
                .catch(() => {
                    this.$noty.info("Wiederbeleben abgebrochen!");
                });
        },
    },
};
</script>


<style scoped lang="scss">
.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;

    &--red {
        stroke: #fe6f5e;
    }
}
</style>
