<template>
  <div>
    <div>
      <el-table
        v-loading="loading"
        stripe
        :data="duties"
        :default-sort="{prop: 'start', order: 'ascending'}"
        :row-class-name="tableRowClassName"
        style="width: 100%"
      >
        <el-table-column label="Monat" prop="start" sortable width="114px">
          <template slot-scope="scope">
            {{ scope.row.start | moment('MMM') }}
            <span v-if="scope.row.vip" class="text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-alert-triangle ml-1"
              >
                <path
                  d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12" y2="17" />
              </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Start" prop="start" sortable width="120px">
          <template slot-scope="scope">{{ scope.row.start | moment('DD.MM.YY - ddd') }}</template>
        </el-table-column>
        <el-table-column label="Dienst" prop="dutytitle" sortable></el-table-column>
        <el-table-column label="Typ" prop="type" sortable width="110px"></el-table-column>

        <el-table-column label="Zeiten" prop="start" width="115px">
          <template
            slot-scope="scope"
          >{{ scope.row.start | moment('HH:mm') }} - {{ scope.row.end | moment('HH:mm') }}</template>
        </el-table-column>
        <el-table-column label="Praxis" prop="orderer" sortable></el-table-column>
        <el-table-column label="Interne Notiz" prop="internal_note" sortable></el-table-column>
        <el-table-column label="Notiz" prop="notes" sortable></el-table-column>
        <el-table-column fixed="right" align="right" width="50px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Dienst-Infos öffnen" placement="top">
              <a :href="'/intern/duties/details/' + scope.row.id">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-info table-icon"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="16" x2="12" y2="12" />
                  <line x1="12" y1="8" x2="12" y2="8" />
                </svg>
              </a>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["duties"],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      let momentdate = this.$moment(row.start);
      if (
        momentdate.isoWeekday() == 6 ||
        momentdate.isoWeekday() == 7 ||
        row.ispublicholiday
      ) {
        return "highlight-row";
      }
      return "";
    }
  }
};
</script>

<style scoped lang="scss">
.table-icon {
  width: 18px;
  height: 18px;
  stroke: #102b43;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
  margin-left: 12px;

  &--red {
    stroke: #fe6f5e;
  }
}
</style>