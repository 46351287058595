<template>
  <div>
    <form @submit.prevent="submit">
      <div class="form-row mb-3">
        <div class="col-md-12 col-lg-6">
          <el-select
            v-model="fields.months"
            focus
            reserve-keyword
            placeholder="Zeitraum wählen"
            class="w-100"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <form-error v-if="errors && errors.months" :errortext="errors.months[0]"></form-error>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button class="btn btn-primary mr-3" :disabled="loading" type="submit">Report erstellen</button>
          <button class="btn btn-default" @click.prevent="clearForm">Neuer Report</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormMixin from "../../../../FormMixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      action: "/intern/reports/reserves/data",
      fields: {
        months: ""
      },
      options: [
        {
          value: "3",
          label: "3 Monate"
        },
        {
          value: "6",
          label: "6 Monate"
        },
        {
          value: "9",
          label: "9 Monate"
        },
        {
          value: "12",
          label: "12 Monate"
        }
      ],
      reserves: [],
      loading: false
    };
  },
  methods: {
    handleResponseResult(response) {
      this.reserves = [];
      this.$noty.success("Report erstellt!");
      this.pushReserve(response);
    },
    pushReserve(value) {
      this.$emit("dataready", value);
    },
    clearForm() {
      this.fields = {
        months: ""
      };

      this.$emit("resetdata");
    }
  }
};
</script>

<style>
</style>