<template>
    <div class="col">
        <div class="card  mt-4">
                <div class="card-body quick-action" @click="dialogFormVisible = true">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square quick-action--icon"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg><br>
                SMS senden
            </div>
        </div>

        <el-dialog :title="'SMS an ' + receiveruser.fullname" :visible.sync="dialogFormVisible">
            <form @submit.prevent="submit">
                <div class="row mb-4">
                    <div class="col text-center">
                        <textarea name="smstext" id="smstext" rows="6" class="form-control"
                        :class="errors && errors.smstext ? 'form-error' : ''" placeholder="Dein SMS Text" v-model="fields.smstext">
                        </textarea>
                        <form-error v-if="errors && errors.smstext" :errortext="errors.smstext[0]"></form-error>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <button class="btn btn-primary btn-block" type="submit">SMS senden</button> 
                    </div>
                </div>
            </form>
        </el-dialog>
    </div>
</template>

<script>

import FormMixin from '../../FormMixin';

export default {
      
    mixins: [ FormMixin ],

    props: ['receiveruser'],

    data() {
        return {
            action: '/intern/sms/send/' + this.receiveruser.id,
            fields: {
                smstext: '',
            },
            dialogFormVisible: false,
        };
    },

      computed: {
        //
      },

      methods: {
        handleResponseResult(response) {
            this.fields.smstext = '';
            this.dialogFormVisible = false;
            this.$noty.success("SMS verschickt!");
        }
      },
    };
</script>

<style lang="scss" scoped>

</style>
