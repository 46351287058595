<template>
    <div id="areaList">
        <area-create-form
            v-if="$can('edit areas')"
            @itemAdded="pushElement"
        ></area-create-form>
        <div v-if="areas.length == 0" class="row">
            <div class="col">
                <img src="/img/undraw_blank_canvas.svg" class="img img-fluid" />
            </div>
        </div>
        <div v-else>
            <div class="card">
                <div class="cord-body">
                    <div class="list-group list-group-flush">
                        <a
                            v-for="area in areas"
                            v-show="areas.length > 0"
                            :key="'content_' + area.id"
                            :id="'area_list_' + area.id"
                            :href="'#area_' + area.id"
                            class="list-group-item list-group-item-action"
                        >
                            {{ area.title }}
                        </a>
                    </div>
                </div>
            </div>
            <transition-group name="bounce" tag="div">
                <div
                    v-for="area in areas"
                    v-show="areas.length > 0"
                    :key="area.id"
                    :id="'area_' + area.id"
                    class="mt-4 mb-4 card"
                >
                    <!-- <div v-for="area in getCategoryContent"  v-show="areas.length" :key="area.id" :id="'area_' + area.id" class="mt-4 mb-4 card"> -->

                    <div class="card-body">
                        <div class="area__title">
                            {{ area.title }}
                            <div class="infoitem--icons pull-right">
                                <el-dropdown v-if="$can('edit areas')">
                                    <span class="el-dropdown-link">
                                        <!-- <i class="el-icon-more el-icon--right"></i> -->
                                        <menu-icon class="icon"></menu-icon>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <a
                                                :href="
                                                    '/intern/areas/edit/' +
                                                    area.id
                                                "
                                            >
                                                <edit-2-icon
                                                    class="
                                                        icon icon-red
                                                        mr-1
                                                        ml-0
                                                    "
                                                ></edit-2-icon
                                                >Eintrag bearbeiten
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    deleteArea(area)
                                                "
                                            >
                                                <trash-2-icon
                                                    class="
                                                        icon icon-red
                                                        mr-1
                                                        ml-0
                                                    "
                                                ></trash-2-icon
                                                >Eintrag löschen
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="area.filename">
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    deleteFile(area)
                                                "
                                            >
                                                <file-minus-icon
                                                    class="
                                                        icon icon-red
                                                        mr-1
                                                        ml-0
                                                    "
                                                ></file-minus-icon
                                                >Datei löschen
                                            </a>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div
                            class="area__description mb-3"
                            v-html="area.description"
                        ></div>
                        <div
                            class="area__file"
                            :id="'area__file' + area.id"
                            v-if="area.filename"
                        >
                            <a
                                :href="
                                    '/intern/areas/' +
                                    area.id +
                                    '/file/download'
                                "
                                @click="downloadStartedInfo(area)"
                            >
                                <download-cloud-icon
                                    class="area__file--icon"
                                ></download-cloud-icon>
                                <strong>{{ area.filename }}</strong>
                            </a>
                        </div>
                        <div
                            class="area__file"
                            v-for="file in area.mediafiles"
                            :key="file.id"
                            @click="downloadStartedInfoMedia(file.file_name)"
                        >
                            <a :href="'/intern/download/' + file.id">
                                <download-cloud-icon
                                    class="area__file--icon"
                                ></download-cloud-icon>
                                <strong>{{ file.file_name }}</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import {
    ShuffleIcon,
    MenuIcon,
    Edit2Icon,
    DownloadCloudIcon,
    FileMinusIcon,
    ArrowUpRightIcon,
    Trash2Icon,
} from "vue-feather-icons";

export default {
    components: {
        ShuffleIcon,
        MenuIcon,
        Edit2Icon,
        DownloadCloudIcon,
        FileMinusIcon,
        ArrowUpRightIcon,
        Trash2Icon,
    },
    props: ["listdata"],
    data() {
        return {
            areas: [],
            search: "",
        };
    },
    computed: {
        getCategoryContent() {
            var listOfObjectsResult = this.areas.filter((areas) => {
                return areas.title
                    .toLowerCase()
                    .includes(this.search.toLowerCase());
                // return areas.filter((area) => area.title.toLowerCase().includes(this.search.toLowerCase())).length
            });

            return listOfObjectsResult;
        },
    },
    mounted() {
        // this.areas = JSON.parse(this.listdata);
        axios
            .get("/intern/areas/data")
            .then((response) => (this.areas = response.data));
    },
    methods: {
        pushElement(value) {
            this.areas.unshift(value);
            this.$noty.success("Gebiet wurde erfolgreich hinzugefügt!");
        },
        downloadStartedInfo(area) {
            this.$noty.success("Download von " + area.filename + " gestartet!");
        },
        downloadStartedInfoMedia(title) {
            this.$noty.success("Download von " + title + " gestartet!");
        },
        deleteArea(area) {
            var url = "/intern/areas/" + area.id;
            axios
                .delete(url)
                .then((response) => {
                    let itemDiv = document.getElementById("area_" + area.id);
                    itemDiv.parentNode.removeChild(itemDiv);
                    this.$noty.success("Gebiet wurde erfolgreich gelöscht!");
                })
                .catch((error) => {
                    console.log(error);
                    this.$noty.error(
                        "Fehler beim Löschen! Bitte noch einmal versuchen."
                    );
                });
        },
        deleteFile: function (area) {
            var url = "/intern/areas" + "/" + area.id + "/deleteFile";
            axios
                .post(url, {
                    areaId: area.id,
                })
                .then((response) => {
                    console.log("File deleted");
                    let itemDiv = document.getElementById(
                        "area__file" + area.id
                    );
                    itemDiv.parentNode.removeChild(itemDiv);
                    area.filename = null;
                    this.$noty.success("Datei wurde erfolgreich gelöscht!");
                })
                .catch((error) => {
                    console.log(error);
                    this.$noty.error(
                        "Fehler beim Löschen! Bitte noch einmal versuchen."
                    );
                });
        },
    },
};
</script>

<style lang="scss" scoped>
#searchfield {
    width: 100%;
    max-width: 300px;
}

.area__title {
    font-weight: bold;

    .dropdown-toggle:after {
        content: none;
    }
}

.area__description {
    // color: #9fb3c8;
    font-size: 14px;
    margin-top: 12px;
}

.infoitem--icons {
    float: right;

    .icon {
        width: 16px;
        height: 16px;
        stroke: #9fb3c8;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        cursor: pointer;
        margin-left: 12px;
    }

    .moveicon {
        cursor: move;
        cursor: -webkit-grabbing;
    }

    .dropdown-item {
        font-size: 14px;
        line-height: 24px;
        padding-left: 12px;

        .icon {
            margin-left: 0;
            margin-right: 6px;
        }

        .icon-red {
            stroke: #fe6f5e;
        }
    }
}

.area__file {
    // border: 1px solid #efefef;
    padding: 12px 18px;
    margin-bottom: 12px;
    width: fit-content;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.1),
        0 18px 36px -18px rgba(0, 0, 0, 0.15),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    border-radius: 8px;
    border: none;

    &--icon {
        width: 16px;
        height: 16px;
        stroke: #9fb3c8;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        margin-right: 8px;
    }
}

.list-group a:hover {
    background-color: #ff6e21 !important;
    color: #fff !important;
}
</style>
