<template>
    <!-- <div class="col" v-if="$can('edit duty') && (this.duty.status == '10_open' || this.duty.status == '20_assigned' || this.duty.status == '30_performed' || this.duty.status == '100_cancelled')"> -->
    <div class="col" v-if="$can('edit duty')">
        <div class="card  mt-4">
            <div class="card-body quick-action" @click="dialogFormVisible = !dialogFormVisible" v-if="this.duty.status != '100_cancelled'">

                    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 quick-action--icon mr-1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    <br> Stornieren

            </div>
            <div class="card-body quick-action" v-if="this.duty.status == '100_cancelled'">
                <span v-if="duty.cancellation_type == 'LP'" class="text-danger">Von Luppe<br>storniert</span>
                <span v-if="duty.cancellation_type == 'PL'" class="text-danger">Von Praxis<br>storniert</span>
                <span v-if="duty.cancellation_type == 'ERR'" class="text-danger">Dienst<br>gelöscht</span><br>
                <span v-if="duty.cancellation_type == 'unbesetzt' || duty.cancellation_type == 'SYSTEM'" class="text-danger">Dienst<br>unbesetzt</span><br>
                <small class="text-muted">{{ duty.cancellation_date | formatDate }}</small>
                <button class="btn btn-sm btn-outline-primary mt-2" @click="uncancel">
                    Stornierung aufheben
                </button>
            </div>
        </div>

        <el-dialog title="Dienst stornieren" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="Wer hat abgesagt?" class="text-strong">
                <form-error v-if="errors && errors.cancellation_type" :errortext="errors.cancellation_type[0]"></form-error>
                <el-select v-model="form.cancellation_type" placeholder="Bitte wählen..." class="w-100p">
                    <el-option label="Praxis hat Luppe abgesagt" value="PL"></el-option>
                    <el-option label="Luppe hat Praxis abgesagt" value="LP"></el-option>
                    <el-option-group
                        label="System-Löschen">
                    <el-option label="Dienst war eine Fehleingabe - Löschen und behalten" value="ERR"></el-option>
                    <el-option label="Dienst war eine Fehleingabe - KOMPLETT Löschen" value="DEL"></el-option>
                    </el-option-group>
                </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Abbrechen</el-button>
                <el-button type="primary" @click="onSubmit">Dienst stornieren</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['duty'],
    data() {
        return {
            action: '/intern/duties/cancel/' + this.duty.id,
            dialogFormVisible: false,
            errors: {},
            form: {
                cancellation_type: '',
            },
        };
    },
    computed: {

    },
    methods: {
        onSubmit() {
            this.errors = {};
            axios.post(this.action, this.form, { timeout: 6000 }).then(response => {
                this.$noty.success("Dienst wurde storniert!")
                this.form = {cancellation_type: ''}

                if(response.data.redirect) {
                    window.location.replace(response.data.redirect);
                }

                this.dialogFormVisible = false
                this.$emit('duty-updated', response.data)
            }).catch(error => {
                this.$noty.error("Fehler beim Stornieren!");
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {}
                }
            });
        },
        uncancel() {
            axios.post(`/intern/duties/${this.duty.id}/uncancel`)
                .then(response => {
                    this.$noty.success("Stornierung wurde aufgehoben!")
                    this.$emit('duty-updated', response.data)
                })
                .catch(error => {
                    this.$noty.error("Fehler beim Aufheben der Stornierung!");
                });
        }
    }
}
</script>

<style>

</style>
