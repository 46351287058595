<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-row mb-3">
                <div class="col">
                  <el-select
                    v-model="fields.doc_on_duty_id"
                    filterable
                    remote
                    focus
                    ref="doc"
                    reserve-keyword
                    placeholder="Arzt (Vertreter)"
                    :remote-method="findDocOnDuty"
                    :loading="loading"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.lastname + ', ' + item.name + ' ' + item.deleted_label"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <form-error
                    v-if="errors && errors.doc_on_duty_id"
                    :errortext="errors.doc_on_duty_id[0]"
                  ></form-error>
                </div>
              </div>

              <div class="form-row mb-3">
                <div class="col">
                  <el-switch v-model="fields.prebooking" active-text="Nur vormerken"></el-switch>
                </div>
              </div>

              <div class="form-row mb-3">
                <div class="col">
                  <el-date-picker
                    v-model="fields.start"
                    type="date"
                    :picker-options="{
                                        firstDayOfWeek: 1
                                    }"
                    format="dd.MM.yyyy"
                    value-format="yyyy-MM-dd"
                    start-placeholder="Start"
                    class="w-100"
                  ></el-date-picker>
                  <form-error v-if="errors && errors.start" :errortext="errors.start[0]"></form-error>
                </div>
              </div>

              <div class="form-row mb-3">
                <div class="col text-center">
                  <input
                    type="text"
                    class="form-control"
                    :class="errors && errors.notes ? 'form-error' : ''"
                    id="notes"
                    placeholder="Notiz zum Dienst / Bonus"
                    v-model="fields.notes"
                  />
                  <form-error v-if="errors && errors.notes" :errortext="errors.notes[0]"></form-error>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                  <button
                    class="btn btn-primary btn-block"
                    :disabled="loading"
                    type="submit"
                  >Reserve speichern</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <reserves-list-table
              :duties="duties"
              v-on:reservedeleted="removereserve($event)"
              withdelete="true"
            ></reserves-list-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h3>Reserven letzte 6 Monate</h3>
            <reserves-list-table :duties="reservesPast"></reserves-list-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from "../../../FormMixin";

export default {
  components: {
    //
  },
  props: ["reservesFuture", "reservesPast"],
  mixins: [FormMixin],
  data() {
    return {
      action: "/intern/reserves",

      fields: {
        doc_on_duty_id: "",
        start: "",
        notes: "",
        prebooking: false
      },
      options: [],
      loading: false,
      duties: []
    };
  },

  computed: {
    startDateWithTime() {
      return "";
    }
  },
  mounted() {
    this.$refs.doc.$el.focus();
    this.duties = this.reservesFuture;
  },
  methods: {
    handleResponseResult(response) {
      this.$noty.success("Reserve hinzugefügt!");
      this.clearForm();
      this.pushDuty(response);
    },
    clearForm() {
      this.fields = {
        doc_on_duty_id: "",
        start: "",
        notes: ""
      };
      this.$nextTick(() => this.$refs.doc.focus());
    },
    pushDuty(value) {
      this.duties.unshift(value);
    },
    removereserve(id) {
      console.log(id + "deleting");
      this.duties.find(duty => {
        if (duty.id === id) {
          this.duties.splice(this.duties.indexOf(duty), 1);
        }
      });
      // Vue.delete(this.duties, index)
    },
    findDocOnDuty(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;

          if (query.length > 2) {
            axios
              .get("/intern/reserves/searchUser", { params: { query: query } })
              .then(response => {
                this.options = response.data;
              });
          }
        }, 200);
      } else {
        this.options = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon__chevron {
  padding: 6px 12px;
}

.el-select {
  display: input;
  width: 100%;
}
</style>


<style scoped lang="scss">
</style>
