<template>
<div class="form-row pb-3" v-bind:class="{ 'shadow-md': shadow == 1, 'mb-5': border == 1 }">
    <div class="col d-none d-lg-block d-xl-block col-lg-2" style="min-width: 130px">
        <time datetime="2014-09-20" class="icon">
            <em>{{ duty.start | moment("dddd") }}</em>
            <strong>{{ duty.start | moment("MMMM") }}</strong>
            <span>{{ duty.start | moment("D") }}</span>
        </time>
        <div class="text-center text-danger" v-if="duty.vip">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle">
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12" y2="17"></line>
            </svg>
            <br /><span class="small">VIP-DIENST</span>
        </div>
    </div>
    <div class="col">
        <h2 class="fw-700 mb-0">
            <a :href="'/intern/duties/details/' + duty.id">
                {{ duty.orderer.client.area.title }} {{ duty.suffix }}
            </a>
        </h2>
        <small class="text-muted">{{ duty.orderer.fullname }},
            {{ duty.orderer.client.city }}</small><br />

        <!-- Dienst Status hierher - computed color
                <span class="badge badge-success" v-if="client.status == 'aktiv'">Aktive Praxis</span>
            <span class="badge badge-danger" v-if="client.status == 'inaktiv'">Inaktive Praxis</span> -->
        <div class="form-row mt-2 ml-0 mb-3">
            <span class="badge badge-secondary" v-if="duty.type == 'Sitzdienst'">Sitzdienst</span>
            <span class="badge badge-secondary" v-if="duty.type == 'Fahrdienst'">Fahrdienst</span>
            <span class="badge badge-success ml-2" v-if="duty.is_weekend">Wochenende</span>
            <span class="badge badge-success ml-2" v-if="duty.is_publicholiday">Feiertag</span>
            <span class="badge badge-info ml-2" v-if="duty.is_after_bdonline">Nach BD Online</span>
            <span class="badge badge-success ml-2" v-if="duty.selfassigned">Selbst zugewiesen</span>
        </div>
        <div class="form-row mt-2 mb-2">
            <div class="col-md-4 col-sm-12" style="max-width: 190px">
                <small>{{ duty.start | moment("dddd") }}</small><br />
                <strong>{{
                            duty.start | moment("DD.MM.YYYY [um] H:mm")
                        }}</strong><br />
                <small class="rockblue">START</small>
            </div>
            <div class="col-md-1 col-sm-12 d-none d-md-block d-lg-block d-xl-block rockblue mt-3 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </div>
            <div class="col pl-4">
                <small>{{ duty.end | moment("dddd") }}</small><br />
                <strong>{{
                            duty.end | moment("DD.MM.YYYY [um] H:mm")
                        }}</strong><br />
                <small class="rockblue">ENDE</small>
            </div>
        </div>

        <span class="badge badge-warning">{{ duty.status_label }}</span>
    </div>
</div>
</template>

<script>
export default {
    props: ["duty", "shadow", "border"],
};
</script>

<style>

</style>
