<template>
<div class="form-row mt-5">
  <div class="col card mr-3 p-3">
      <div class="card-header">
        <h3 class="mb-0 text-left"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus-circle mr-2"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>Offene Dienste</h3>
      </div>
      <div class="card-body pt-0" id="openDutyDiv">
        <transition-group
        enter-active-class="animated slideInRight"
        leave-active-class="animated bounceOutRight"
        v-if="getDuties.length > 0">
        <div v-for="(e, key) in getDuties" :key="e.id" :ref="'duty-' + e.id" :id="'duty-' + e.id">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="item-index">{{ ++key }}</div>
                    <duty-intro-compact-ultra :duty="e" :enable-hiding="true" :hide-status="true" :border="true" v-on:dutyhidden="removeDuty(e.id)"></duty-intro-compact-ultra>
                    <duty-map-doctor :duty="e" :docsRaw="docsRaw" v-on:status-changed="e.status = $event"></duty-map-doctor>
                </div>
            </div>
        </div>
        </transition-group>
        <div v-else class="text-center">
          <img src="/img/undraw_Booked.svg" alt="Alle Dienste verteilt" class="img img-fluid mt-5" width="300px"><br>
        </div>
        <div class="text-center">
          <a :href="reloadLink" class="btn btn-primary btn-sm mt-4">Neue Dienste nachladen...</a>
        </div>
      </div>
  </div>
<div class="col card mr-3 p-3">
      <div class="card-header pb-0">
        <h3 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle mr-2"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>Verteilte Dienste</h3>
      </div>
      <div class="card-body">
        <transition-group
        enter-active-class="animated slideInRight"
        leave-active-class="animated bounceOutRight"
        v-if="getDoneDuties.length > 0">

        <div v-for="e in getDoneDuties" :key="e.id" :ref="'duty-' + e.id" :id="'duty-' + e.id">
            <div class="card mb-3">
                <div class="card-body">
                    <duty-intro-compact-ultra :duty="e" :enable-hiding="true" :hide-status="true" v-on:dutyhidden="removeDuty(e.id)"></duty-intro-compact-ultra>
                    <duty-map-doctor :duty="e" :docsRaw="docsRaw"></duty-map-doctor>
                </div>
            </div>
        </div>
        </transition-group>
        <div v-else class="text-center">
          <img src="/img/undraw_not_found.svg" alt="Noch keine Dienste verteilt" class="img img-fluid mt-5" width="300px"><br>
        </div>
      </div>
  </div>
</div>
</template>

<script>

// import VueConfetti from 'vue-confetti'
// Vue.use(VueConfetti)

export default {
  props: ['docsRaw', 'duties', 'isfullscreen'],
  data() {
    return {
      loading: true,
      localDuties: {}
      // docs: [],
    }
    // https://vue-select.org/guide/values.html#transforming-selections
  },
  created() {
    this.localDuties = this.duties
  },
  computed: {
      reloadLink() {
        if(this.isfullscreen) {
          return '/intern/duties/dutyselectorfullscreen'
        }

        return '/intern/duties/dutyselector'
      },
      getDuties() {
          var listOfObjectsResult = this.localDuties.filter((duties) => {
              return duties.status == "10_open";
          });
          this.loading = false;
        //   if(listOfObjectsResult.length == 0)
        //   {
        //     this.$confetti.start({
        //       particlesPerFrame: 0.4,
        //       particles: [
        //         {
        //           type: 'rect',
        //           size: '7',
        //         }
        //       ],
        //     });
        //     setTimeout(() => this.$confetti.stop(), 5000);
        //   }
          return listOfObjectsResult;
      },
      getDoneDuties() {
          var listOfObjectsResult = this.localDuties.filter((duties) => {
              return duties.status == "20_assigned";
          });

          this.loading = false;
          return listOfObjectsResult;
      }
  },
  methods: {
      removeDuty(id) {
        document.getElementById("duty-" + id).style.display = "none";
      }
  }
}
</script>

<style>
.item-index {
    position: absolute;
    right: -6px;
    top: -6px;
    background: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 800;
    padding-top: 7px;
    -webkit-font-smoothing: antialiased;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 8px -5px rgba(0, 0, 0, 0.03);
}
</style>
