var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"filterTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","data":_vm.getTableContent,"default-sort":{ prop: 'praxis', order: 'ascending' }}},[_c('el-table-column',{attrs:{"label":"Praxis","prop":"praxis","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Gebiet","prop":"title","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Ära","prop":"is_after_bdonline","sortable":"","width":"90px","filters":[
            { text: 'Vor BD-Online', value: 0 },
            { text: 'Nach BD-Online', value: 1 },
        ],"filter-method":_vm.filterEra},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.is_after_bdonline === 0
                        ? 'primary'
                        : 'success',"disable-transitions":""}},[_vm._v(_vm._s(scope.row.is_after_bdonline === 0
                        ? "Vor BDO"
                        : "Nach BDO"))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Ort","prop":"city","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Telefon","prop":"phone","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Praxis ID","prop":"customerid","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-input',{attrs:{"size":"mini","placeholder":"Name suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Praxis-Infos öffnen","placement":"top"}},[_c('a',{attrs:{"href":'/intern/clients/' + scope.row.id}},[_c('info-icon',{staticClass:"table-icon"})],1)]),_vm._v(" "),(_vm.$can('edit clients'))?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Praxis bearbeiten","placement":"top"}},[(_vm.$can('edit clients'))?_c('a',{attrs:{"href":'/intern/clients/edit/' + scope.row.id}},[_c('edit-2-icon',{staticClass:"table-icon"})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"E-Mail an die Praxis","placement":"top"}},[_c('a',{attrs:{"href":'mailto:' + scope.row.email}},[_c('mail-icon',{staticClass:"table-icon"})],1)]),_vm._v(" "),(_vm.$can('edit clients'))?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Praxis SOFORT LÖSCHEN - noch nicht verfügbar","placement":"top"}},[_c('user-x-icon',{staticClass:"table-icon table-icon--red",on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }