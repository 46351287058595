<template>
    <div id="chart">
        <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    props: ['chartdata', 'reserveschartdata'],
    components: {
        apexchart: VueApexCharts,
    },
    data: function() {
        return {
            series: [{
                name: 'Dienste',
                data: this.chartdata
            },
            {
                name: 'Reserven',
                data: this.reserveschartdata
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    stacked: false,
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 0
                },
                stroke: {
                    curve: 'smooth'
                },
                yaxis: {
                    min: 0,
                    labels: {
                        style: {
                            colors: '#8e8da4',
                        },
                        offsetX: 0,
                        formatter: function(val) {
                            return (val).toFixed(0);
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 8,
                    // min: new Date("16/10/2021").getTime(),
                    // max: new Date().getTime(),
                    labels: {
                        rotate: -15,
                        rotateAlways: true,
                        formatter: function(val, timestamp) {
                            // return moment(new Date(timestamp)).format("DD MMM YYYY")
                            let returndate = new Date(timestamp)
                            const enUSFormatter = new Intl.DateTimeFormat('de-DE');
                            return enUSFormatter.format(returndate)
                            // return returndate.getDate + "." + returndate.getMonth + "." + returndate.getFullYear
                        }
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }


    },
}
</script>

<style>

</style>
