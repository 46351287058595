<template>
    <div>
        <p>
            Bitte gib hier die
            <strong>€ Summe (gesamt) vom Dienstbogen</strong> für den Dienst an.
        </p>
        <form @submit.prevent="submit" class="form-inline">
            <input
                type="text"
                v-model="fields.dutysum"
                v-money="money"
                id="dutysum"
                class="form-control"
                style="width: 200px"
                :disabled="denyedit == 'deny'"
            />
            <button
                type="submit"
                class="btn btn-primary ml-2"
                :disabled="denyedit == 'deny'"
            >
                Speichern
            </button>
            <form-error
                v-if="errors && errors.dutysum"
                :errortext="errors.dutysum[0]"
            ></form-error>
        </form>
    </div>
</template>

<script>
import { InfoIcon } from "vue-feather-icons";
import FormMixin from "../../../FormMixin";
import { VMoney } from "v-money";

export default {
    mixins: [FormMixin],
    components: {
        InfoIcon,
    },
    props: ["duty", "denyedit"],
    data() {
        return {
            action: "/intern/duties/" + this.duty.id + "/billing/billingSum",
            confirmationdate: null,
            errors: null,
            fields: {
                dutysum: null,
            },
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false /* doesn't work with directive */,
            },
        };
    },
    mounted() {
        this.fields.dutysum = this.duty.billing_sum;
    },
    methods: {
        handleResponseResult(response) {
            this.$noty.success("Summe gepseichert!");
            this.fields.dutysum = response.billingSum;
            this.$emit("sumentered", this.fields.dutysum);
            // location.reload()
        },
    },
    directives: { money: VMoney },
};
</script>
