<template>
  <div class="col rockblue" :class="selected ? 'text-primary' : ''">
    <span class="status-label">{{ statusLabel }}</span>
    <br />
    <span class="status-verb">{{ statusVerb }}</span>
  </div>
</template>

<script>
export default {
  props: ["status", "statusLabel", "selected"],
  computed: {
    statusVerb() {
      if (this.status == "10_open" || this.status == "15_prebooked")
        return "ZUTEILUNG";
      else if (
        this.status == "20_assigned" ||
        this.status == "30_performed" ||
        this.status == "100_cancelled"
      )
        return "DIENST";
      else return "ABRECHNUNG";
    }
  }
};
</script>

<style>
.status-label {
  font-size: 1.4rem;
}

.status-verb {
  font-size: 0.8rem;
}
</style>