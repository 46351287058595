<template>
  <div>
    <div v-if="reserves.length > 0">
      <el-table
        v-loading="loading"
        stripe
        id="report-reserves-results"
        :data="reserves"
        :default-sort="{prop: 'reserves_count', order: 'descending'}"
        style="width: 100%"
      >
        <el-table-column label="Name" prop="fullnameReverse" sortable></el-table-column>
        <el-table-column label="E-Mail" prop="email" sortable></el-table-column>
        <el-table-column label="Ort" prop="city" sortable></el-table-column>
        <el-table-column label="Reserven im Zeitraum" prop="reserves_count" sortable>
          <template slot-scope="scope">
            <i>
              {{ scope.row.reserves_count }}
              <small
                v-if="(scope.row.reserves_count - avg).toFixed(2) > 0"
                class="text-success ml-2"
              >{{ (scope.row.reserves_count - avg).toFixed(2) }}</small>
              <small
                v-else
                class="text-danger ml-2"
              >{{ (scope.row.reserves_count - avg).toFixed(2) }}</small>
            </i>
          </template>
        </el-table-column>
        <el-table-column label="Dienste im Zeitraum" prop="duties_count" sortable>
          <template slot-scope="scope">
            <i v-if="scope.row.duties_count > 0">
              {{ scope.row.duties_count }} <small v-if="scope.row.duties_count > 0 && scope.row.reserves_count > 0">somit {{ (scope.row.duties_count / scope.row.reserves_count).toFixed(2) }} Dienste pro Reserve</small>
            </i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reserves", "avg"],
  data() {
    return {
      loading: false
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.table-icon {
  width: 18px;
  height: 18px;
  stroke: #102b43;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
  margin-left: 12px;

  &--red {
    stroke: #fe6f5e;
  }
}
</style>
