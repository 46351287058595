<template>
    <div>
        <report-filter-form-past-duties
            @dataready="updateReportData"
            @resetdata="resetDuties"
            :areas="areas"
        ></report-filter-form-past-duties>
        <transition name="fade">
            <div v-if="duties.length" class="mt-5 pt-5 border-top">
                <h3>{{ count }} Ergebnisse</h3>
                <report-table :duties="duties"></report-table>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: ["areas"],
    data() {
        return {
            duties: [],
            avg: 0,
        };
    },
    computed: {
        count: function () {
            return this.duties.length;
        },
    },
    methods: {
        updateReportData(duties) {
            this.duties = [];
            this.duties = duties.data;
        },
        resetDuties() {
            this.duties = [];
        },
    },
};
</script>

<style>
</style>
