<template>
    <div class="wrapper">
        <div class="FormDate" @keyup.capture="updateValue" @load="updateValue">
            <input
                v-if="showDay"
                ref="day"
                v-model="day"
                class="FormDate__input FormDate__input--day"
                type="number"
                placeholder="dd"
                @input="updateDay"
                @blur="day = day.padStart(2, 0)"
            />
            <span v-if="showDay && showMonth" class="FormDate__divider">/</span>
            <input
                v-if="showMonth"
                ref="month"
                v-model="month"
                class="FormDate__input FormDate__input--month"
                type="number"
                placeholder="mm"
                @input="updateMonth"
                @blur="month = month.padStart(2, 0)"
            />
            <span
                v-if="showYear && (showDay || showMonth)"
                class="FormDate__divider"
                >/</span
            >
            <input
                v-if="showYear"
                ref="year"
                v-model="year"
                class="FormDate__input FormDate__input--year"
                type="number"
                placeholder="yyyy"
                @blur="year = year.padStart(4, 0)"
            />
        </div>

        <input
            type="number"
            min="0"
            max="23"
            class="timeinput form-control"
            id="hour"
            placeholder="hh"
            v-model="hour"
            @keyup.capture="updateValue"
        />
        <input
            type="number"
            min="0"
            max="59"
            class="timeinput form-control"
            id="minute"
            placeholder="mm"
            v-model="minute"
            @keyup.capture="updateValue"
        />
        <!-- {{ returnvalue }} -->
    </div>
</template>

<script>
export default {
    name: `FormDate`,
    props: {
        value: {
            //   type: [Number, String, Object],
            required: true,
        },
        showDay: {
            type: Boolean,
            default: true,
        },
        showMonth: {
            type: Boolean,
            default: true,
        },
        showYear: {
            type: Boolean,
            default: true,
        },
    },
    created() {},
    mounted() {
        this.updateValue();
        //  this.$emit(`input`);
    },
    data() {
        return {
            day: `${
                this.value
                    ? ("0" + new Date(this.value).getDate()).slice(-2)
                    : ``
            }`,
            // month: `${this.value ? new Date(this.value).getMonth() : ``}`,
            month: `${this.value ? this.$moment(this.value).format("MM") : ``}`,
            year: `${this.value ? new Date(this.value).getFullYear() : ``}`,
            //   hour: `${this.value ? new Date(this.value).getHours() : ``}`,
            //   minute: `${this.value ? new Date(this.value).getMinutes() : ``}`,
            hour: `${
                this.value
                    ? ("0" + new Date(this.value).getHours()).slice(-2)
                    : ``
            }`,
            minute: `${
                this.value
                    ? ("0" + new Date(this.value).getMinutes()).slice(-2)
                    : ``
            }`,
            returnvalue: "",
        };
    },
    watch: {
        year(current, prev) {
            if (current > 9999) this.year = prev;
        },
    },
    methods: {
        updateDay() {
            if (!this.day.length || parseInt(this.day, 10) < 4) return;
            if (this.showMonth) this.$refs.month.select();
            else if (this.showYear) this.$refs.year.select();
        },
        updateMonth() {
            if (!this.month.length || parseInt(this.month, 10) < 2) return;
            if (this.showYear) this.$refs.year.select();
        },
        updateValue() {
            // const timestamp = this.$moment(`${this.year.padStart(4, 0)}-${this.month}-${this.day}`);
            // const timestamp = this.$moment([this.year.padStart(4, 0),this.month-1,this.day, this.hours, this.minutes]);
            const timestamp = this.$moment([
                this.year.padStart(4, 0),
                this.month - 1,
                this.day,
                this.hour,
                this.minute,
            ]).format("YYYY-MM-DD HH:mm:ss");

            //   if (Number.isNaN(timestamp)) return;
            this.returnvalue = timestamp;
            this.$emit(`input`);
        },
    },
};
</script>

<style lang="scss">
@use "sass:math";
.wrapper {
    display: inline-flex;
}

.timeinput {
    max-width: 64px;
    display: inline-flex;
    padding-top: 5px;
    margin-left: 4px;
}

.FormDate {
    $spacing: 0.25em;

    display: inline-flex;
    position: relative;
    overflow: hidden;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    // 1. Hide the spinner button in Chrome, Safari and Firefox.
    &__input {
        padding: $spacing;
        padding-right: math.div($spacing, 2);
        padding-left: math.div($spacing, 2);
        border: none;
        text-align: center;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield; // 1
        appearance: textfield;

        &::-webkit-inner-spin-button {
            display: none; // 1
        }

        &:first-child {
            padding-left: $spacing;
        }

        &:last-child {
            padding-right: $spacing;
        }

        &:focus {
            outline: none;
        }

        &--day,
        &--month {
            width: 3em;
        }

        &--year {
            width: 4em;
        }
    }

    &__divider {
        // padding-top: $spacing;
        // padding-bottom: $spacing;
        pointer-events: none;
    }
}
</style>
