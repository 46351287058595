<template>
<div>
    <form v-on:submit.prevent="search">
    <div class="input-group input-group-sm mb-3">
        <input v-model="searchterm" type="text" class="form-control" placeholder="Suchbegriff..." aria-label="Siuchbegriff" aria-describedby="basic-addon2">
        <div class="input-group-append">
            <button type="submit" class="btn btn-sm btn-info">
                <svg style="width: 14px; height: 14px;" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
        </div>
    </div>
    </form>
</div>
</template>

<script>
export default {
    data () {
         return {
            searchterm: ''
         }
    },
    methods: {
        search() {
            window.find(this.searchterm, false);
        }
    }
}
</script>

<style>

</style>
