<template>
  <div id="infoItemList">
    <!-- <div v-if="categories.length == 0" class="row">
      <div class="col">
        <img src="/img/undraw_blank_canvas.svg" class="img img-fluid" />
      </div>
    </div> -->
    <!-- <div  v-for="cat in categories" :key="cat.id" :id="'infoitem_' + cat.id" class="mt-4 mb-4"> -->
    
    <draggable 
          class="drag-area" 
          :list="getCategoryContent"
          handle=".moveCategoryicon" 
          animation="200"
          easing="cubic-bezier(1, 0, 0, 1)"
          @change="updateCategories">
        
        <div v-for="(cat,index) in categories" :key="cat.id" class="mt-5">
        <div class="category-title">
          <div class="infoitem--icons pull-right">
            <a href="#" @click.prevent="startEditing(index)">
              <edit-2-icon 
                class="icon" 
              ></edit-2-icon>
            </a>
            <shuffle-icon class="icon moveicon moveCategoryicon"></shuffle-icon>
          </div>
          <h2 :ref="'cat-title-' + cat.id" v-show="editOffset != index">{{ cat.title }}</h2>
          <input v-show="editOffset==index" type="text" 
             :id = "'item-cat-'+index"
             @keydown.enter="updatePost" @keydown.esc="cancelEditing"
             class="form-control" v-model="cat.title">
        </div>
        
        <div class="category-infoitems-list">
          <draggable 
              class="drag-area" 
              group="infoitemgroup"
              :list="cat.infoitems"
              handle=".moveicon" 
              animation="200"
              easing="cubic-bezier(1, 0, 0, 1)"
              @sort="updateInfoitemOrder"
              @add="assignNewCategory"
              :id="cat.id">
            <div v-for="(infoItem, index) in cat.infoitems" :key="infoItem.id" :id="'infoitem_' + infoItem.id" :categoryId="cat.id" :infoItemId="infoItem.id" class="card mt-4 mb-4">
              <div class="card-body">
                <div class="infoitem__title">
                  <div class="infoitem--icons pull-right">
                    <shuffle-icon class="icon moveicon"></shuffle-icon>
                    <div class="dropdown float-right">
                      <a class="dropdown-toggle" href="#" role="button" :id="'dropdownMenuLink' + infoItem.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,10">
                        <menu-icon class="icon"></menu-icon>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" href="#" @click.prevent="deleteRecord(infoItem.id)"><trash-2-icon class="icon icon-red"></trash-2-icon> Eintrag löschen</a>
                        <a class="dropdown-item" @click.prevent="deleteFile(infoItem)" v-if="infoItem.filename" href="#"><file-minus-icon class="icon icon-red"></file-minus-icon> Datei löschen</a>
                        <a class="dropdown-item" @click.prevent="deleteLink(infoItem)" v-if="infoItem.linkurl" href="#"><trash-2-icon class="icon icon-red"></trash-2-icon> Link löschen</a>
                      </div>
                    </div>
                  </div>
                  <click-edit 
                    v-bind:text="infoItem.title" 
                    v-bind:objectId="infoItem.id"
                    placeholder="Enter text value" 
                    v-on:text-updated-blur="updatePostInfoItemTitle" 
                    v-on:text-updated-enter="updatePostInfoItemTitle">
                  </click-edit>
                </div>
                <div class="infoitem__description mb-3">
                  <click-edit 
                    v-bind:text="infoItem.description" 
                    v-bind:objectId="infoItem.id"
                    placeholder="Beschreibung eingeben" 
                    v-on:text-updated-blur="updatePostInfoItemDescription" 
                    v-on:text-updated-enter="updatePostInfoItemDescription">
                  </click-edit>
                </div>
                <div class="infoitem__file" :id="'infoitem__file' + infoItem.id" v-if="infoItem.filename">
                  <a :href="'/intern/infoitems/' + infoItem.id + '/file/download'" @click="downloadStartedInfo(infoItem)">
                    <download-cloud-icon class="infoitem__file--icon"></download-cloud-icon> <strong>{{ infoItem.filename }}</strong>
                  </a>
                </div>
                <div class="infoitem__link" :id="'infoitem__link' + infoItem.id"  v-if="infoItem.linkurl">
                  <arrow-up-right-icon class="infoitem__file--icon"></arrow-up-right-icon><a :href="infoItem.linkurl" target="_blank">{{ infoItem.linkurl }}</a>
                </div>

                <template v-if="!infoItem.filename">
                  <div class="card" v-if="!infoItem.linkurl">
                      <a :href="'/intern/infoitems/' + infoItem.id + '/changeFileOrLink'" class="btn btn-info btn-sm">Datei oder Link hinzufügen</a>
                  </div>
                </template>
              </div> 
            </div>
          </draggable>
        </div>
        </div>
    </draggable>
    </div>
</template>

<script>
import { ShuffleIcon, MenuIcon, Edit2Icon, DownloadCloudIcon, FileMinusIcon, ArrowUpRightIcon, Trash2Icon } from "vue-feather-icons";
import draggable from "vuedraggable";

export default {
  components: {
    ShuffleIcon,
    MenuIcon,
    Edit2Icon,
    DownloadCloudIcon,
    FileMinusIcon,
    ArrowUpRightIcon,
    Trash2Icon,
    draggable
  },
  props: [
    'listdata'
  ],
  data() {
    return {
      categories: [],
      editCat: {},
      editOffset: -1,
      editCatOri: {},
      search: ''
    };
  },
  computed: {
      getCategoryContent() { 
          var listOfObjectsResult = this.categories.filter((categories) => {
              // return categories.title.toLowerCase().includes(this.search.toLowerCase());
              return categories.infoitems.filter((infoitem) => infoitem.title.toLowerCase().includes(this.search.toLowerCase())).length
          });
          
          return listOfObjectsResult;
      },
  },
  mounted () {
    this.categories = JSON.parse(this.listdata);
  },
  methods: {
    startEditing(index) {
      this.editOffset = index
      this.editCat = this.categories[index]
      this.editCatOri = JSON.parse(JSON.stringify(this.editCat))
      // set focus ke element input
      this.$nextTick(function(){
        console.log('item-cat-'+this.editOffset)
        document.getElementById('item-cat-'+this.editOffset).focus()
      }.bind(this))
    },
    updatePost() {
      axios
        .patch("/intern/categories/title/" + this.editCat.id, {
          title: this.editCat.title
        })
        .then(response => {
          // console.log(response.data);
          this.$noty.success("Titel der Kategorie erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Ändern des Titels! Bitte noch einmal versuchen.")
      });
      this.editOffset = -1
      this.editCatOri = {}
      this.editCat = {}
    },
    cancelEditing() {
      this.$set(this.categories, this.editOffset, this.editCatOri)
      this.editOffset = -1
      this.editCatOri = {}
      this.editCat = {}
    },
    updatePostInfoItemTitle(text, objectId) {
      axios
        .patch("/intern/infoitems/" + objectId + "/updateTitle", {
          title: text
        })
        .then(response => {
          // console.log(response.data);
          this.$noty.success("Titel der Unterlage erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Ändern des Titels! Bitte noch einmal versuchen.")
      });
    },
    updatePostInfoItemDescription(text, objectId) {
      axios
        .patch("/intern/infoitems/" + objectId + "/updateDescription", {
          description: text
        })
        .then(response => {
          // console.log(response.data);
          this.$noty.success("Beschreibung der Unterlage erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Ändern der Beschreibung! Bitte noch einmal versuchen.")
      });
    },
    updateCategories() {
      this.categories.map((category, index) => {
        category.sort_order = index + 1;
      });

      axios
        .put("/intern/categories/sort", {
          categories: this.categories
        })
        .then(response => {
          // console.log(response.data);
          this.$noty.success("Sortierung der Kategorien erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler bei der Sortierung der Kategorien! Bitte noch einmal versuchen.")
        });
    },
    updateInfoitemOrder(data) {
      let sortCat = this.categories.find(category => category.id == data.item.attributes.categoryid.value)

      sortCat.infoitems.map((infoItem, index) => {
        infoItem.sort_order = index + 1;
      });

      axios
        .put("/intern/infoitems/sort", {
          infoItems: this.categories.find(category => category.id == data.item.attributes.categoryid.value).infoitems,
        })
        .then(response => {
          console.log(response.data);
          this.$noty.success("Sortierung erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler bei der Sortierung! Bitte noch einmal versuchen.")
        });
    },

    assignNewCategory(data) {
      axios
        .put("/intern/infoitems/changeCategory/" + data.item.attributes.infoItemId.value, {
          infoItemId: data.item.attributes.infoItemId.value,
          newCategoryId: data.to.id,
          newPosition: data.newIndex + 1,
        })
        .then(response => {
          console.log(response.data);
          this.$noty.success("Kategorie erfolgreich angepasst!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler bei der Kategorieänderung! Bitte noch einmal versuchen.")
        });
    },

    downloadStartedInfo(infoItem) {
      this.$noty.success("Download von " + infoItem.filename + " gestartet!")
    },

    deleteRecord: function(id) {
      var url = "/intern/infoitems" + "/" + id;
      axios.delete(url)
        .then(response => {
          console.log('Item deleted');
          let itemDiv = document.getElementById("infoitem_" + id);
          itemDiv.parentNode.removeChild(itemDiv);   
          this.$noty.success("Eintrag wurde erfolgreich gelöscht!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Löschen! Bitte noch einmal versuchen.")
        });
    },

    deleteFile: function(infoItem) {
      var url = "/intern/infoitems" + "/" + infoItem.id + "/deleteFile";
      axios.post(url, {
          infoItemId: infoItem.id
        })
        .then(response => {
          console.log('File deleted');
          let itemDiv = document.getElementById("infoitem__file" + infoItem.id);
          itemDiv.parentNode.removeChild(itemDiv);   
          infoItem.filename = null;
          this.$noty.success("Datei wurde erfolgreich gelöscht!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Löschen! Bitte noch einmal versuchen.")
        });
    },

    deleteLink: function(infoItem) {
      var url = "/intern/infoitems" + "/" + infoItem.id + "/deleteLink";
      axios.post(url, {
          infoItemId: infoItem.id
        })
        .then(response => {
          console.log('Link deleted');
          let itemDiv = document.getElementById("infoitem__link" + infoItem.id);
          itemDiv.parentNode.removeChild(itemDiv);   
          infoItem.linkurl = null;
          this.$noty.success("Link wurde erfolgreich gelöscht!")
        })
        .catch(error => {
          console.log(error);
          this.$noty.error("Fehler beim Löschen! Bitte noch einmal versuchen.")
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.infoitem__title {
  // color: #ff6e21;
  font-weight: bold;

  .dropdown-toggle:after { content: none }
}

.infoitem__description {
  color: #9fb3c8;
  font-size: 14px;
  margin-top: 12px;
}

.infoitem--icons {
  float: right;

  .icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;
  }

  .moveicon {
    cursor: move;
    cursor: -webkit-grabbing;
  }

  .dropdown-item {
    
    font-size: 14px;
    line-height: 24px;
    padding-left: 12px;

    .icon {
      margin-left: 0;
      margin-right: 6px;
    }

    .icon-red {
      stroke: #FE6F5E;
    }
  }
}

.infoitem__file {
  &--icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    margin-right: 8px;
  }
}
</style>
