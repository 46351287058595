<template>
    <form @submit.prevent="submit">
        <div class="row mb-4">
            <div class="col text-center">
                <input type="text" class="form-control" :class="errors && errors.description ? 'form-error' : ''" id="title" placeholder="Benenne die Fahrt" v-model="fields.description">
                <form-error v-if="errors && errors.description" :errortext="errors.description[0]"></form-error>
            </div>
        </div>

        

        <div class="row mt-3">
            <div class="col">
                <button class="btn btn-outline-primary btn-block" type="submit">Fahrt speichern</button> 
            </div>
        </div>

        </div>
    </form>
</template>

<script>

import FormMixin from '../../FormMixin';

export default {
      
      mixins: [ FormMixin ],

      data() {
        return {
            action: '/trip',
            fields: {
                description: '',
                oneway: 'oneway',
                triptype: 'business', // umschreiben in Type
                startaddress: 'Fake Start', // Dummy
                destinationaddress: 'Fake Ende', // Dummy
                startLongitude: '',
                startLatitude: '',
                destinationLongitude: '',
                destinationLatitude: '',
                distance: '',
                perKilometer: 0.30, // Dummy
                totalCharge: this.perKilometer * this.showdistance,
                fastbillCustomerId: '123456', // Dummy
                customerName: 'Dummy', // Dummy
                tripDate: '2018-11-21'  // Dummy
            },
        };
      },

      computed: {
        test() {
            return '';
        },
      },

      methods: {
        handleResponseResult(response) {
            // Reset Form Fields
            location.reload()
        }
      },
    };
</script>

<style lang="scss" scoped>


</style>
