<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="submit">
                            <div class="form-row mb-3">
                                <div class="col">
                                    <el-select v-model="fields.person_orderer_id" filterable remote focus ref="doc" reserve-keyword placeholder="Arzt (Kunde)" :remote-method="findDocOnDuty" :loading="loading">
                                        <el-option v-for="item in options" :key="item.id" :label="item.lastname + ', ' + item.firstname + ' · ' + item.client.city + ' · ' + item.client.praxis" :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <form-error v-if="errors && errors.person_orderer_id" :errortext="errors.person_orderer_id[0]"></form-error>
                                </div>
                            </div>
                            <div class="form-row mb-3">
                                <div class="col">
                                    <input type="date" v-model="fields.edit_start" class="form-control d-inline-flex mr-3" style="width: 180px;" :min="minDateStart" :max="maxDateStart">
                                    <input type="number" min="0" max="23" v-model="fields.edit_start_h" class="form-control d-inline-flex" style="width: 64px;"> :
                                    <input type="number" min="0" max="59" v-model="fields.edit_start_m" class="form-control d-inline-flex" style="width: 64px;">
                                    <span class="rockblue icon__chevron"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                                                        <polyline points="9 18 15 12 9 6"></polyline>
                                                    </svg></span>
                                    <input type="date" v-model="fields.edit_end" class="form-control d-inline-flex mr-3" style="width: 180px;" :min="minDateEnd" :max="maxDateEnd">
                                    <input type="number" min="0" max="23" v-model="fields.edit_end_h" class="form-control d-inline-flex" style="width: 64px;"> :
                                    <input type="number" min="0" max="59" v-model="fields.edit_end_m" class="form-control d-inline-flex" style="width: 64px;">
                                    <form-error v-if="errors && errors.start" :errortext="errors.start[0]"></form-error>
                                    <form-error v-if="errors && errors.end" :errortext="errors.end[0]"></form-error>
                                </div>
                                <transition name="fade">
                                    <div class="col-md-2 pr-2 pt-2 text-right" v-if="weekendcalc">
                                        <span class="badge badge-primary">Wochenende</span>
                                    </div>
                                </transition>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col text-center">
                                    <input type="text" class="form-control" :class="errors && errors.notes ? 'form-error' : ''" id="notes" placeholder="Notiz zum Dienst / Bonus" v-model="fields.notes">
                                    <form-error v-if="errors && errors.notes" :errortext="errors.notes[0]"></form-error>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col text-center">
                                    <input type="text" class="form-control" :class="errors && errors.suffix ? 'form-error' : ''" id="suffix" placeholder="Zusatz-Name (z.B. West)" v-model="fields.suffix">
                                    <form-error v-if="errors && errors.suffix" :errortext="errors.suffix[0]"></form-error>
                                </div>
                                <div class="col text-center">
                                    <input type="text" class="form-control" :class="errors && errors.internal_note ? 'form-error' : ''" id="internal_note" placeholder="Interne Notiz" v-model="fields.internal_note">
                                    <form-error v-if="errors && errors.internal_note" :errortext="errors.internal_note[0]"></form-error>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col pl-2">
                                    <el-switch v-model="fields.vip" active-color="#5cb85c" inactive-color="#9FB3C8" active-text="VIP Dienst" inactive-text="">
                                    </el-switch>
                                    <form-error v-if="errors && errors.vip" :errortext="errors.vip[0]"></form-error>
                                </div>
                                <div class="col pl-2">
                                    <el-switch v-model="fields.type" active-color="#9FB3C8" inactive-color="#9FB3C8" inactive-text="Fahrdienst" inactive-value="Fahrdienst" active-value="Sitzdienst" active-text="Sitzdienst">
                                    </el-switch>
                                    <form-error v-if="errors && errors.type" :errortext="errors.type[0]"></form-error>
                                </div>

                            </div>

                            <div class="row mt-3">
                                <div class="col">
                                    <button class="btn btn-primary btn-block" :disabled="loading" type="submit">Dienst speichern</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <transition-group name="bounce">
                    <div class="card mb-3" v-for="duty in duties" :key="duty.id">
                        <div class="card-body">
                            <duty-intro :duty="duty"></duty-intro>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '../../../FormMixin';
// import FormDate from './partials/FormDate.vue';
import { TheMask } from 'vue-the-mask';

export default {
    components: {
        TheMask,
    },
    mixins: [FormMixin],
    data() {
        return {
            action: '/intern/duties/mass',

            fields: {
                person_orderer_id: '',
                type: 'Fahrdienst',
                start: '',
                end: '',
                edit_start: '',
                edit_start_h: '',
                edit_start_m: '',
                edit_end: '',
                edit_end_h: '',
                edit_end_m: '',
                notes: '',
                internal_note: '',
                suffix: '',
                vip: false,
            },
            options: [],
            loading: false,
            duties: [],
        };
    },

    computed: {
        startDateWithTime() {
            return '';
        },
        minDateStart() {
            return this.$moment().subtract(3, 'months').format("YYYY-MM-DD");
        },
        maxDateStart() {
            return this.$moment().add(37, 'months').format("YYYY-MM-DD");
        },
        minDateEnd() {
            if (this.fields.edit_start) {
                return this.fields.edit_start
            }
            return this.$moment().subtract(3, 'months').format("YYYY-MM-DD");
        },
        maxDateEnd() {
            return this.$moment().add(38, 'months').format("YYYY-MM-DD");
        },
        weekendcalc() {
            return this.$moment(this.fields.start).isoWeekday() == 6 || this.$moment(this.fields.start).isoWeekday() == 7 || this.$moment(this.fields.end).isoWeekday() == 6 || this.$moment(this.fields.end).isoWeekday() == 7;
        },
        startdate() {
            return this.$moment(String(this.fields.edit_start)).hour(this.fields.edit_start_h).minute(this.fields.edit_start_m).format("YYYY-MM-DD HH:mm:00")
        },
        enddate() {
            return this.$moment(String(this.fields.edit_end)).hour(this.fields.edit_end_h).minute(this.fields.edit_end_m).format("YYYY-MM-DD HH:mm:00")
        }
    },
    mounted() {
        this.$refs.doc.$el.focus()
    },
    methods: {
        submit() {
            if (this.loaded) {
                this.fields.start = this.startdate
                this.fields.end = this.enddate
                this.loaded = false;
                this.success = false;
                this.errors = {};

                axios.post(this.action, this.fields, { timeout: 6000 }).then(response => {
                    this.loaded = true
                    this.success = true
                    this.handleResponseResult(response.data)
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                    }
                });
            }
            this.loading = false;
        },
        handleResponseResult(response) {
            this.$noty.success("Dienst hinzugefügt!")
            this.clearForm()
            this.pushDuty(response)
        },
        clearForm() {
            this.fields = {
                person_orderer_id: '',
                type: 'Fahrdienst',
                start: '',
                edit_start: '',
                edit_start_h: '',
                edit_start_m: '',
                edit_end: '',
                edit_end_h: '',
                edit_end_m: '',
                end: '',
                notes: '',
                internal_note: '',
                suffix: '',
                vip: false,
            };
            this.$nextTick(() => this.$refs.doc.focus());
        },
        pushDuty(value) {
            this.duties.unshift(value)
        },
        findDocOnDuty(query) {
            if (query !== '') {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;

                    if (query.length > 2) {
                        axios.get('/intern/duties/searchDoc', { params: { query: query } }).then(response => {
                            this.options = response.data;
                        });
                    }
                }, 200);
            } else {
                this.options = [];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.icon__chevron {
    padding: 6px 12px;
}

.el-select {
    display: input;
    width: 100%;
}
</style>
