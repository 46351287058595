<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="Leitstellen Report" name="first">
                <report-filter-form
                    :areas="areas"
                    @dataready="updateReportData"
                    @resetdata="resetDuties"
                ></report-filter-form>
                <transition name="fade">
                    <div v-if="duties.length" class="mt-5 pt-5 border-top">
                        <h3>Ergebnisse</h3>
                        <report-table :duties="duties"></report-table>
                    </div>
                </transition>
            </el-tab-pane>
            <el-tab-pane label="Vergangene Dienste" name="second">
                <report-past-duties :areas="areas" />
            </el-tab-pane>
            <el-tab-pane label="Reservenübersicht Vertreter" name="third">
                <report-reserves />
            </el-tab-pane>
            <el-tab-pane label="Unterlagen Vertreter" name="forth">
                <report-user-files />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    props: ["areas"],
    data() {
        return {
            duties: [],
            activeName: "first",
        };
    },
    methods: {
        updateReportData(duties) {
            this.duties = [];
            this.duties = duties.data;
        },
        resetDuties() {
            this.duties = [];
        },
    },
};
</script>

<style>
</style>
