<template>
    <div>
        <div v-if="duties.length > 0">
            <div
                v-for="e in duties"
                :key="e.id"
                :ref="'duty-' + e.id"
                :id="'duty-' + e.id"
            >
                <div class="card mb-3">
                    <div class="card-body">
                        <duty-intro-compact-ultra
                            :duty="e"
                            :enable-hiding="false"
                            :hide-status="false"
                        ></duty-intro-compact-ultra>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-center" v-else>
            <img
                src="/img/undraw_events.svg"
                class="img img-fluid"
                width="300px"
                alt=""
            />
            <br />Keine Dienste aus der Vergangenheit gefunden.
        </p>
    </div>
</template>

<script>
// Get List of Duties
export default {
    props: ["clientid"],
    data() {
        return {
            duties: [],
            url: "/intern/clients/details/" + this.clientid + "/getDutyHistory",
        };
    },
    mounted() {
        setTimeout(() => {
            axios.get(this.url).then((response) => {
                this.duties = response.data;
            });
        }, 300);
    },
};
</script>

<style>
</style>
