<template>
<div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <duty-intro :shadow="0" :border="0" :duty="duty" :dutyCountLifetime="dutyCountLifetime"></duty-intro>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div v-if="duty.notes" class="col">
                            <h4 class="mb-3">Notizen zum Dienst</h4>
                            <div class="multilinefield">
                                {{ duty.notes }}
                            </div>
                        </div>
                        <div v-else class="col">
                            <h4>Keine Notizen vorhanden</h4>
                        </div>
                        <div v-if="duty.internal_note && $can('edit duty')" class="col">
                            <h4 class="mb-3">
                                INTERNE Notizen zum Dienst (Kernteam)
                            </h4>
                            <div class="multilinefield">
                                {{ duty.internal_note }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row d-print-none">
        <div class="col">
            <div class="card mt-4">
                <div class="card-body quick-action" onclick="window.print()">
                    <printer-icon class="quick-action--icon"></printer-icon>
                    <br />Drucken
                </div>
            </div>
        </div>
        <!-- <div class="col">
                <div class="card  mt-4">
                    <div class="card-body quick-action">
                        <log-out-icon class="quick-action--icon"></log-out-icon><br>
                        VCard
                    </div>
                </div>
          </div>-->
        <div class="col">
            <div class="card mt-4">
                <div class="card-body quick-action" @click="downloadIcs">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar quick-action--icon">
                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                        <line x1="16" y1="2" x2="16" y2="6" />
                        <line x1="8" y1="2" x2="8" y2="6" />
                        <line x1="3" y1="10" x2="21" y2="10" />
                    </svg>
                    <br />In Kalender
                </div>
            </div>
        </div>

        <duty-registration-button :duty="duty"></duty-registration-button>
        <duty-has-been-confirmed-by-doc-button :duty="duty" />
        <duty-week-before-reminder-button :duty="duty"></duty-week-before-reminder-button>
        <duty-changed-doc-info-mail-button :duty="duty" :previousdoc="previousdoc" :previousdocdata="previousdocdata"></duty-changed-doc-info-mail-button>

        <duty-cancellation-button :duty="duty" v-on:duty-updated="updateDuty"></duty-cancellation-button>

        <div class="col" v-if="$can('edit duty')">
            <div class="card mt-4">
                <div class="card-body quick-action" @click="editFormVisible = true">
                    <edit-2-icon class="quick-action--icon"></edit-2-icon>
                    <br />Bearbeiten
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card mt-4">
                <div class="card-body quick-action" @click="startRouteFinder">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map quick-action--icon">
                        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6" />
                        <line x1="8" y1="2" x2="8" y2="18" />
                        <line x1="16" y1="6" x2="16" y2="22" />
                    </svg>
                    <br />Route berechnen
                </div>
            </div>
        </div>

        <sms-send-form v-if="duty.doc_on_duty" :receiveruser="duty.doc_on_duty"></sms-send-form>
    </div>

    <!-- <booking-map  v-if="duty.orderer.client.lat" :area="duty.orderer.client.area" :lat="parseFloat(this.duty.orderer.client.lat)" :lng="parseFloat(this.duty.orderer.client.lng)"></booking-map> -->

    <!-- <GmapMap
                            :center="mapCenter"
                            :zoom="10"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: true,
                                disableDefaultUi: false
                            }"
                            :streetViewControl="false"
                            map-type-id="roadmap"
                            style="width: 100%; height: 300px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="false"
                                :title="duty.orderer.client.praxis"
                                @click="mapCenter=m.position"
                            />
        </GmapMap>-->
    <!-- </div>
                </div>
            </div>
        </div>-->

    <div class="row mt-4">
        <div class="col-md-12">
            <h2>ATL Vertreter</h2>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <duty-map-doctor :duty="duty" :docs-raw="docs" v-on:status-changed="localStatus = $event" />
                </div>
            </div>
        </div>
        <div class="col-md-6" v-if="$can('edit duty')">
            <div class="card">
                <div class="card-body">
                    <duty-history :duty="duty.id" />
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12 mt-3">
            <h2>Vertretener Kollege / Kollegin</h2>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h4 class="mb-3">Vertretung für...</h4>
                    {{ duty.orderer.fullname }}
                    <br />
                    <span v-if="duty.orderer.lanr" class="text-muted fs-14">LANR.
                        {{
                                duty.orderer.lanr
                                    .replace(/[^\dA-Z]/g, "")
                                    .replace(/(.{3})/g, "$1 ")
                                    .trim()
                            }}</span>
                    <span v-if="duty.orderer.username" class="text-muted fs-14"><br />
                        BENUTZERNAME: {{ duty.orderer.username }}</span>
                    <span v-if="duty.orderer.birthday" class="text-muted fs-14">
                        <br />
                        GEBURTSDATUM:
                        {{ duty.orderer.birthday | moment("L") }}
                    </span><br>
                    <span class="badge badge-info">{{ dutyCountLifetime }} Dienste bisher</span>
                    <h5 class="mb-3 mt-3">Praxis-Adresse</h5>
                    <address>
                        <a :href="'/intern/clients/details/' + duty.orderer.client.id" class="text-primary">
                            {{ duty.orderer.client.praxis }}
                        </a>
                        <br />
                        {{ duty.orderer.client.street }}
                        <br />
                        {{ duty.orderer.client.zip }}
                        {{ duty.orderer.client.city }}
                    </address>
                    <span class="badge badge-secondary" v-if="duty.orderer.client.speciality">{{ duty.orderer.client.speciality }}</span>
                    <div v-if="duty.orderer.client.invoicing_address">
                        <br />
                        <el-alert title="Achtung abweichende Rechnungsadresse vorhanden" type="warning" show-icon>
                        </el-alert>
                        <h5 class="mb-3 mt-3">Rechnungs-Adresse</h5>
                        <p class="multilinefield">
                            {{ duty.orderer.client.invoicing_address }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h4 class="mb-3">Kontaktdaten</h4>
                    <table class="table table-borderless" v-if="
                                duty.orderer.client.phone ||
                                duty.orderer.client.phone_mobile ||
                                duty.orderer.client.fax ||
                                duty.orderer.client.email
                            ">
                        <tr v-if="duty.orderer.client.registration_path">
                            <td width="32px" class="text-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-radio table-icon">
                                    <circle cx="12" cy="12" r="2" />
                                    <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" />
                                </svg>
                            </td>
                            <td class="facts">
                                <strong>Anmelden über
                                    {{
                                            duty.orderer.client
                                                .registration_path
                                        }}</strong>
                            </td>
                        </tr>
                        <tr v-if="duty.orderer.client.email">
                            <td width="32px" class="text-left">
                                <send-icon class="table-icon"></send-icon>
                            </td>
                            <td>
                                <a :href="
                                            'mailto:' +
                                            duty.orderer.client.email
                                        ">{{ duty.orderer.client.email }}</a>
                            </td>
                        </tr>
                        <tr v-if="duty.orderer.client.phone_mobile">
                            <td width="32px" class="text-left">
                                <smartphone-icon class="table-icon"></smartphone-icon>
                            </td>
                            <td>
                                <a :href="
                                            'tel:' +
                                            duty.orderer.client.phone_mobile
                                        ">{{
                                            duty.orderer.client.phone_mobile
                                        }}</a>
                            </td>
                        </tr>
                        <tr v-if="duty.orderer.client.phone">
                            <td width="32px" class="text-left">
                                <phone-icon class="table-icon"></phone-icon>
                            </td>
                            <td>
                                <a :href="
                                            'tel:' + duty.orderer.client.phone
                                        ">{{ duty.orderer.client.phone }}</a>
                            </td>
                        </tr>
                        <tr v-if="duty.orderer.client.fax">
                            <td width="32px" class="text-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text table-icon">
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                    <polyline points="14 2 14 8 20 8" />
                                    <line x1="16" y1="13" x2="8" y2="13" />
                                    <line x1="16" y1="17" x2="8" y2="17" />
                                    <polyline points="10 9 9 9 8 9" />
                                </svg>
                            </td>
                            <td>
                                <a :href="'tel:' + duty.orderer.client.fax">{{ duty.orderer.client.fax }}</a>
                            </td>
                        </tr>
                    </table>
                    <div v-else>
                        <span class="text-danger font-italic">Es wurden keine Kontaktdaten hinterlegt.</span>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4" v-if="duty.orderer.client.notes">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard primary-icon">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                            <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                        </svg>
                        Notizen
                    </h4>
                    <div class="row">
                        <div v-if="duty.orderer.client.notes" class="mt-3 col">
                            <h5>Notizen zur Praxis</h5>
                            <div class="multilinefield">
                                {{ duty.orderer.client.notes }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4" v-if="
                this.dutyprop.orderer.client.lat &&
                this.dutyprop.orderer.client.lng
            ">
        <div class="col">
            <div class="card">
                <div class="card-body p-0">
                    <GmapMap :center="mapCenter" :zoom="10" :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: true,
                                disableDefaultUi: false,
                            }" :streetViewControl="false" map-type-id="roadmap" style="width: 100%; height: 300px">
                        <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" :title="duty.orderer.client.praxis" @click="mapCenter = m.position" />
                    </GmapMap>
                </div>
            </div>
        </div>
    </div>

    <duty-details-areainfo :area="duty.orderer.client.area" />
    <a name="billing" />
    <invoice-upload-box :duty="duty" :csrf="csrf" />

    <status-bar :status="localStatus" :dutyid="duty.id"></status-bar>

    <el-dialog title="Dienst bearbeiten" :visible.sync="editFormVisible" width="80%" v-if="$can('edit duty')">
        <duty-update-form :dutyprop="duty" v-on:duty-updated="updateDuty" :start="duty.start" :end="duty.end"></duty-update-form>
    </el-dialog>
</div>
</template>

<script>
import {
    PhoneIcon,
    PrinterIcon,
    Edit2Icon,
    SendIcon,
    SmartphoneIcon,
} from "vue-feather-icons";
import InvoiceUploadBox from "../invoiceupload/invoice-upload-box.vue";
import DutyHasBeenConfirmedByDocButton from "./partials/DutyHasBeenConfirmedByDocButton.vue";
// import FormMixin from '../../../FormMixin';

export default {
    name: "duty-details",
    components: {
        PrinterIcon,
        SendIcon,
        Edit2Icon,
        SmartphoneIcon,
        PhoneIcon,
        InvoiceUploadBox,
        DutyHasBeenConfirmedByDocButton,
    },
    props: [
        "dutyCountLifetime",
        "dutyprop",
        "calendarlinkprop",
        "docs",
        "previousdoc",
        "previousdocdata",
        "csrf",
    ],
    data() {
        return {
            loading: false,
            localStatus: "",
            duty: {},
            calendarlink: "",
            reverseSmsOrder: true,
            editFormVisible: false,
            mapCenter: {
                lat: parseFloat(this.dutyprop.orderer.client.lat),
                lng: parseFloat(this.dutyprop.orderer.client.lng),
            },
            markers: [{
                position: {
                    lat: parseFloat(this.dutyprop.orderer.client.lat),
                    lng: parseFloat(this.dutyprop.orderer.client.lng),
                },
            }, ],
        };
    },
    computed: {
        // googlecalendarlink() {
        //     // https://stackoverflow.com/questions/10488831/link-to-add-to-google-calendar
        //     link = '<a href="http://www.google.com/calendar/render?
        //             action=TEMPLATE
        //             &text=[event-title]
        //             &dates=[start-custom format='Ymd\\THi00\\Z']/[end-custom format='Ymd\\THi00\\Z']
        //             &details=[description]
        //             &location=[location]
        //             &trp=false
        //             &sprop=
        //             &sprop=name:"
        //             target="_blank" rel="nofollow">Add to my calendar</a>'
        //     return link;
        // }
    },
    created() {
        this.duty = this.dutyprop;
        this.localStatus = this.duty.status;
        this.calendarlink = this.calendarlinkprop;
    },
    mounted() {},
    methods: {
        // handleResponseResult(response) {
        //     this.fields.smstext = '';
        //     this.dialogFormVisible = false;
        //     this.$noty.success("SMS verschickt!");
        //     // location.reload()
        // },
        redirectToEditPage(clientId) {
            window.location.href = "/intern/clients/edit/" + clientId;
        },
        forceFileDownload(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "dienst-termin.ics"); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
        downloadIcs() {
            axios({
                    method: "get",
                    url: this.calendarlink,
                    responseType: "arraybuffer",
                })
                .then((response) => {
                    this.forceFileDownload(response);
                })
                .catch(() => console.log("error occured"));
        },
        startRouteFinder() {
            var url =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                encodeURIComponent(
                    this.duty.orderer.client.street +
                    "," +
                    this.duty.orderer.client.zip +
                    "," +
                    this.duty.orderer.client.city
                );
            var win = window.open(url, "_blank");
            win.focus();
        },
        updateDuty(data) {
            this.calendarlink = data.link;
            this.duty = data.duty;
            this.localStatus = data.duty.status;
            this.editFormVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.multilinefield {
    white-space: pre;
    word-wrap: break-word;
}

h5 {
    color: #9fb3c8;
}

.table th,
.table td {
    padding: 0.2rem;
}

.table a,
.facts {
    text-decoration: none;
    color: #555555;
    font-size: 14px;
}

.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer; // margin-left: 12px;

    &--red {
        stroke: #fe6f5e;
    }
}

.muted-icon {
    width: 14px;
    height: 14px;
    stroke: #6c757d;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;

    &--big {
        width: 16px;
        height: 16px;
    }
}

.primary-icon {
    width: 16px;
    height: 16px;
    stroke: #ff6e21;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;
    margin-top: -4px;
}
</style>
