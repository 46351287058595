<template>
  <div>
    <div v-if="duties.length > 0">
      <div v-for="duty in duties" :key="duty.id">
        <duty-intro-compact :duty="duty" :shadow="true" :border="true"></duty-intro-compact>
      </div>
    </div>
    <div v-else class="pt-4 pb-4 text-center">
      <img src="/img/undraw_tree.svg" alt="Keine Diente" class="img img-fluid mb-3 low-opacity" width="300px"><br>
      Keine passenden Dienste gefunden<br>
      <a href="/intern/duties/open" class="btn btn-primary btn-sm mt-3">Offene Dienste ansehen</a>
    </div>
  </div>
</template>

<script>
export default {
    props: ['duties']
}
</script>

<style>
.low-opacity {
  opacity: 0.4;
}
</style>