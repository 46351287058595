<template>
    <div v-loading="loading">
        <div
            v-for="file in files"
            :key="file.id"
            :id="'filebox_' + file.id"
            class="mb-3 file__link"
        >
            <div class="d-flex flex-row align-items-center">
                <file-text-icon class="file__link-icon" />
                <div>
                    <a
                        :href="'/intern/download/' + file.id"
                        @click="downloadStartedInfo(file.file_name)"
                    >
                        {{ file.file_name }}
                        <br />
                        <span class="text-muted file__link-size"
                            >vom {{ file.created_at }}</span
                        >
                    </a>
                </div>
                <div v-if="allowdeleting != 'deny'">
                    <x-icon
                        size="1.5x"
                        class="file__link-icon ml-5 mr-0 clickable"
                        @click="deleteFile(file.id)"
                    ></x-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FileTextIcon, XIcon } from "vue-feather-icons";

export default {
    components: {
        FileTextIcon,
        XIcon,
    },
    props: ["url", "collection", "allowdeleting", "isbillingform"],
    data() {
        return {
            files: [],
            loading: false,
        };
    },
    mounted() {
        this.loading = true;
        axios
            .get(this.url)
            .then((response) => (this.files = response.data))
            .then((this.loading = false));
    },
    methods: {
        downloadStartedInfo(title) {
            this.$noty.success("Download von " + title + " gestartet!");
        },
        deleteFile: function (fileid) {
            this.loading = true;
            var url = "/intern/media/" + fileid;
            if (this.isbillingform) {
                var url = "/intern/duties/billing/upload/" + fileid;
            }
            axios
                .delete(url, {
                    fileid: fileid,
                })
                .then((response) => {
                    console.log("File deleted");
                    let itemDiv = document.getElementById("filebox_" + fileid);
                    itemDiv.parentNode.removeChild(itemDiv);
                    this.$noty.success("Datei wurde erfolgreich gelöscht!");
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$noty.error(
                        "Fehler beim Löschen! Bitte noch einmal versuchen."
                    );
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.file__link {
    border: 2px solid #efefef;
    border-radius: 8px;
    padding: 12px 18px;
    width: fit-content;
}

a {
    color: #333;
    font-weight: 600;
    font-size: 14px;
}

.file__link-size {
    font-size: 12px;
    font-weight: normal;
}

.file__link-icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    margin-right: 14px;
}

.clickable {
    cursor: pointer;
}
</style>
