<template>
    <div>
        <!-- TODO: Edit möglich wenn Coreteam oder Eigentümer + Status 30_performed -->
        <div v-show="code != 'ok'" class="alert alert-primary">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currectColor"
                strokeWidth="{2}"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
            </svg>
            {{ code }}
        </div>
        <form @submit.prevent="submit">
            <button
                type="submit"
                class="btn w-100p btn-primary"
                :disabled="!loaded || duty.status != '30_performed'"
            >
                Abrechnung jetzt einsenden
            </button>
        </form>
    </div>
</template>

<script>
import FormMixin from "../../../FormMixin";

export default {
    mixins: [FormMixin],
    components: {},
    props: ["duty", "comment"],
    data() {
        return {
            action: "/intern/duties/" + this.duty.id + "/billing/submit",
            code: "ok",
            fields: {
                comment: null,
            },
        };
    },
    watch: {
        comment(newVal, oldVal) {
            this.fields.comment = newVal;
        },
    },
    mounted() {
        this.fields.comment = this.comment;
    },
    methods: {
        handleResponseResult(response) {
            if (response.code == "ok") {
                this.code == "ok";
                this.$noty.success("Die Abrechnung wurde eingereicht!");
            } else {
                this.code =
                    "Summe GKV und Abrechnungsbogen-Datei müssen vor dem Einsenden eingegeben werden!";
            }
        },
    },
};
</script>
