<template>
  <div
    class="col"
    v-if="(this.duty.orderer.email || this.duty.orderer.client.email) && this.duty.status == '20_assigned' && this.previousdoc"
  >
    <div class="card mt-4">
      <div class="card-body quick-action" @click="open">
        <svg
          v-if="!duty.change_info_mail_date"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-repeat quick-action--icon mr-1"
        >
          <polyline points="17 1 21 5 17 9" />
          <path d="M3 11V9a4 4 0 0 1 4-4h14" />
          <polyline points="7 23 3 19 7 15" />
          <path d="M21 13v2a4 4 0 0 1-4 4H3" />
        </svg>
        <span v-if="duty.change_info_mail_date" class="text-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            style="vertical-align: -webkit-baseline-middle;"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-check-circle quick-action--icon mr-1"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
            <polyline points="22 4 12 14.01 9 11.01" />
          </svg>
          erledigt
        </span>
        <br />Info Vertreter-wechsel
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["duty", "previousdoc", "previousdocdata"],
  data() {
    return {
      action: "/intern/duties/logDocChangeMail/" + this.duty.id,
      // mailurl: 'mailto:' + this.duty.orderer.client.email,
      bodyone: "Sehr%20geehrte/r%20",
      bodytwo:
        "%2C%0D%0A%0D%0Aaus%20organisatorischen%20Gr%C3%BCnden%20hat%20es%20innerhalb%20des%20%C3%84rzteteam%20Luppe%20einen%20Vertreter-Tausch%20gegeben%3A%20nicht%20" +
        this.previousdocdata.old_doc_name +
        "%20wie%20urspr%C3%BCnglich%20gedacht%2C%20sondern%20ich%20werde%20Ihren%20Dienst%20am%20",
      bodythree:
        "%20verbindlich%20%C3%BCbernehmen.%20Bitte%20entschuldigen%20Sie%20mit%20diesem%20Tausch%20eventuell%20entstandene%20Umst%C3%A4nde.%0D%0A%0D%0ABitte%20beachten%20Sie%2C%20dass%20Ihr%20Name%20im%20Plan%20eingetragen%20bleibt.%20Daher%20bekommen%20Sie%20in%20der%20Woche%20vor%20dem%20Dienst%20eine%20Erinnerungsmail%20von%20der%20KV%2C%20welche%20Sie%20beruhigt%20ignorieren%20k%C3%B6nnen.%0D%0A%0D%0AIch%20werde%20die%20RLS%20%C3%BCber%20die%20Vertretung%20vor%20Dienstbeginn%20informieren.%0D%0A%0D%0ABei%20Fragen%20kontaktieren%20Sie%20mich%20gerne.%0D%0A%0D%0AIch%20w%C3%BCnsche%20Ihnen%20eine%20gute%20Zeit%20und%20gr%C3%BCsse%20Sie%20herzlich%2C%0D%0A%0D%0A%0D%0A%E2%80%A6%E2%80%A6.%20(Name%20%2B%20Handynummer)"
    };
  },
  computed: {
    mailbody() {
      return;
    },
    mailurl() {
      if (
        this.duty.orderer.client.registration_path === "Arzt" &&
        this.duty.orderer.email
      ) {
        return "mailto:" + this.duty.orderer.email;
      } else {
        if (this.duty.orderer.client.email) {
          // wenn anmelden über arzt, nimm arzt, sonst nimm praxis
          return "mailto:" + this.duty.orderer.client.email;
        }
      }

      return "#";
    },
    mailurlWithSubjectAndBody() {
      return (
        this.mailurl +
        "?subject=Vertreterwechsel%20f%C3%BCr%20Ihren%20Notdienst%20am%20" +
        this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
        "&body=" +
        this.bodyone +
        this.duty.orderer.fullname +
        this.bodytwo +
        this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
        this.bodythree
      );
    }
  },
  methods: {
    open() {
      this.openMailProgram();
      this.$confirm(
        "Dein E-Mail-Programm hat sich geöffnet und einen Textvorschlag präsentiert.<br><br><strong>Hast du die E-Mail zu Erinnerung 7 Tage vor dem Dienst verschickt?</strong>",
        "Erinnerung zum Dienst",
        {
          confirmButtonText: "JA",
          cancelButtonText: "Nein, noch nicht",
          type: "info",
          center: true,
          dangerouslyUseHTMLString: true
        }
      )
        .then(() => {
          axios
            .post(this.action, { timeout: 6000 })
            .then(response => {
              this.$noty.success("Erinnerung wurde vermerkt!");
            })
            .catch(error => {
              this.$noty.error("Erinnerung konnte nicht vermerkt werden!");
            });
        })
        .catch(() => {
          this.$noty.error("Erinnerung konnte nicht vermerkt werden!");
        });
    },
    openMailProgram() {
      // const url = window.URL.new(this.mailurl)
      const link = document.createElement("a");
      link.href = this.mailurlWithSubjectAndBody;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style>
</style>
