<template>
    <div
        class="col"
        v-if="
            (this.duty.orderer.email || this.duty.orderer.client.email) &&
            this.duty.status == '20_assigned'
        "
    >
        <div class="card mt-4">
            <div class="card-body quick-action" @click="open">
                <svg
                    v-if="!duty.registration_mail_date"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-radio quick-action--icon"
                >
                    <circle cx="12" cy="12" r="2" />
                    <path
                        d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"
                    />
                </svg>
                <span v-if="duty.registration_mail_date" class="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        style="vertical-align: -webkit-baseline-middle"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="
                            feather feather-check-circle
                            quick-action--icon
                            mr-1
                        "
                    >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                        <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                    erledigt {{ duty.registration_mail_date | formatDate }}
                </span>
                <br />1. Anmelden per Mail
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["duty"],
    data() {
        return {
            action: "/intern/duties/logRegistration/" + this.duty.id,
            fields: {
                submit_date: null,
            },
            // mailurl: 'mailto:' + this.duty.orderer.client.email,
            bodyone:
                "Sehr%20geehrte.....%2C%0A%0Aich%20freue%20mich%2C%20Ihnen%20hiermit%20mitteilen%20zu%20k%C3%B6nnen%2C%20dass%20ich%20als%20Vertreter%20des%20%C3%84rzteteam%20Luppe%20Ihren%20Dienst%20am%20",
            bodytwo:
                "%20verbindlich%20%C3%BCbernehmen%20werde.%0A%20%0ABitte%20beachten%20Sie%2C%20dass%20Ihr%20Name%20in%20dem%20BD%20Online%20eingetragen%20bleibt.%20%0ASie%20bekommen%20daher%20in%20der%20Woche%20vor%20dem%20Dienst%20eine%20Erinnerungsmail%20von%20der%20KV%2C%20welche%20Sie%20beruhigt%20ignorieren%20k%C3%B6nnen.%20%0A%0AIch%20werde%20mich%20in%20der%20Woche%20vor%20dem%20Vertretungstermin%20erneut%20mit%20Ihnen%20in%20Verbindung%20setzen%20-%20selbstverst%C3%A4ndlich%20k%C3%B6nnen%20Sie%20mich%20auch%20jederzeit%20kontaktieren.%0A%0AIch%20werde%20die%20RLS%20%C3%BCber%20die%20Vertretung%20informieren.%20%0A%0ABitte%20best%C3%A4tigen%20Sie%20mir%20pers%C3%B6nlich%20diese%20definitive%20%C3%9Cbernahme%20Ihres%20o.g.%20Dienstes%20per%20Mail%20oder%20per%20Telefon%20-%20Herzlichen%20Dank!%20%0A%0A" +
                encodeURIComponent(this.duty.doc_on_duty.fullname) +
                "%20%0AMobil:%20" +
                encodeURIComponent(this.duty.doc_on_duty.phone_mobile),
        };
    },
    computed: {
        mailbody() {
            return;
        },
        mailurl() {
            if (
                this.duty.orderer.client.registration_path === "Arzt" &&
                this.duty.orderer.email
            ) {
                return "mailto:" + this.duty.orderer.email;
            } else {
                if (this.duty.orderer.client.email) {
                    // wenn anmelden über arzt, nimm arzt, sonst nimm praxis
                    return "mailto:" + this.duty.orderer.client.email;
                }
            }

            return "#";
        },
        mailurlWithSubjectAndBody() {
            return (
                this.mailurl +
                "?subject=Ärzteteam Luppe - Ihr Dienst am " +
                this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
                "&body=" +
                this.bodyone +
                this.$moment(this.duty.start).format("dddd [den] DD.MM.YYYY") +
                this.bodytwo
            );
        },
    },
    methods: {
        open() {
            this.openMailProgram();
            this.$prompt(
                "Dein E-Mail-Programm hat sich geöffnet und einen Textvorschlag präsentiert.<br><br><strong>Wann hast du die E-Mail zu Anmeldung verschickt?</strong>",
                "Anmeldung zum Dienst",
                {
                    confirmButtonText: "Bestätigen",
                    cancelButtonText: "Nein, noch nicht",
                    inputType: "date",
                    roundButton: true,
                    inputPattern:
                        /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                    inputErrorMessage: "Ungültiges Datum",
                    center: true,
                    dangerouslyUseHTMLString: true,
                }
            )
                .then((value) => {
                    this.fields.submit_date = value.value;
                    axios
                        .post(this.action, this.fields, { timeout: 6000 })
                        .then((response) => {
                            this.$noty.success("Anmeldung wurde vermerkt!");
                        })
                        .catch((error) => {
                            this.$noty.error(
                                "Anmeldung konnte nicht vermerkt werden!"
                            );
                        });
                })
                .catch(() => {
                    this.$noty.error("Anmeldung konnte nicht vermerkt werden!");
                });
        },
        openMailProgram() {
            // const url = window.URL.new(this.mailurl)
            const link = document.createElement("a");
            link.href = this.mailurlWithSubjectAndBody;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>

<style>
</style>
