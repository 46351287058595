<template>
    <div>
        <form @submit.prevent="submit">
            <div class="form-row mb-3">
                <div class="col-md-12 col-lg-6">
                    <el-select
                        v-model="fields.area"
                        filterable
                        remote
                        focus
                        reserve-keyword
                        placeholder="Gebiet"
                        class="w-100"
                    >
                        <el-option
                            v-for="item in areas"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <form-error
                        v-if="errors && errors.area"
                        :errortext="errors.area[0]"
                    ></form-error>
                </div>
            </div>

            <div class="form-row mb-3">
                <div class="col-md-12 col-lg-6">
                    <el-select
                        v-model="fields.type"
                        placeholder="Typen"
                        class="w-100"
                    >
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <form-error
                        v-if="errors && errors.type"
                        :errortext="errors.type[0]"
                    ></form-error>
                </div>
            </div>

            <div class="form-row mb-3">
                <div class="col-md-12 col-lg-6">
                    <el-date-picker
                        @change="updateDates"
                        v-model="dates"
                        type="daterange"
                        :picker-options="{
                            firstDayOfWeek: 1,
                        }"
                        format="dd.MM.yyyy"
                        value-format="yyyy-MM-dd"
                        range-separator="bis"
                        start-placeholder="Start"
                        end-placeholder="Ende"
                        class="w-100"
                    >
                    </el-date-picker>
                    <form-error
                        v-if="errors && errors.start"
                        :errortext="errors.start[0]"
                    ></form-error>
                    <form-error
                        v-if="errors && errors.end"
                        :errortext="errors.end[0]"
                    ></form-error>
                </div>
            </div>

            <div class="form-row mb-4">
                <div class="col-md-12 col-lg-6 pl-2">
                    <el-switch
                        v-model="fields.open"
                        active-color="#5cb85c"
                        inactive-color="#9FB3C8"
                        inactive-text="Nur Vergebene"
                        active-text="Mit Abgesagten"
                    >
                    </el-switch>
                    <form-error
                        v-if="errors && errors.open"
                        :errortext="errors.open[0]"
                    ></form-error>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <button
                        class="btn btn-primary mr-3"
                        :disabled="loading"
                        type="submit"
                    >
                        Report erstellen
                    </button>
                    <button class="btn btn-default" @click.prevent="clearForm">
                        Neuer Report
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import FormMixin from "../../../FormMixin";

export default {
    mixins: [FormMixin],
    props: ["areas"],
    data() {
        return {
            action: "/intern/duties/report/duty-history/data",
            fields: {
                type: "Beides",
                start: "",
                end: "",
                area: "",
                open: true,
            },
            dates: [],
            typeOptions: [
                {
                    value: "Beides",
                    label: "Fahrdienst + Sitzdienst",
                },
                {
                    value: "Fahrdienst",
                    label: "Fahrdienst",
                },
                {
                    value: "Sitzdienst",
                    label: "Sitzdienst",
                },
            ],
            loading: false,
            // duties: [],
        };
    },
    // computed: {
    //   dateOffset() {
    //         return this.$moment(this.dates[0]).hour(0).minute(0)
    //   },
    // },
    methods: {
        handleResponseResult(response) {
            this.duties = [];
            this.$noty.success("Report erstellt!");
            // this.clearForm()
            this.pushDuty(response);
        },
        updateDates() {
            this.fields.start = this.dates[0];
            this.fields.end = this.dates[1];
        },
        pushDuty(value) {
            // this.duties.unshift(value)
            this.$emit("dataready", value);
        },
        clearForm() {
            this.fields = {
                type: "Beides",
                start: "",
                end: "",
                area: "",
                open: true,
            };

            this.dates = [];
            this.$emit("resetdata");
        },
    },
};
</script>

<style>
.el-date-editor .el-range-separator {
    width: 30px;
}
</style>
