<template>
    <div>
        <div id="doctors" v-if="doctors">
            <div
                class="card text-left mb-3"
                v-for="p in doctors"
                :key="p.id"
                :id="'person_' + p.id"
            >
                <div class="card-body">
                    <h5>{{ p.title }} {{ p.firstname }} {{ p.lastname }}</h5>
                    <div class="fs-14" v-if="p.lanr">
                        LANR.
                        {{
                            p.lanr
                                .replace(/[^\dA-Z]/g, "")
                                .replace(/(.{3})/g, "$1 ")
                                .trim()
                        }}
                    </div>
                    <span class="mb-4 badge badge-secondary">Arzt</span>

                    <table
                        class="table table-borderless"
                        v-if="
                            p.phone ||
                            p.phone_mobile ||
                            p.fax ||
                            p.email ||
                            p.birthday ||
                            p.username
                        "
                    >
                        <tr v-if="p.birthday">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="Geburtstag"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-star table-icon"
                                    >
                                        <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                        />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td class="facts">{{ p.birthday | formatDate }}</td>
                        </tr>
                        <tr v-if="p.email">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="E-Mail"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-send table-icon"
                                    >
                                        <line x1="22" y1="2" x2="11" y2="13" />
                                        <polygon
                                            points="22 2 15 22 11 13 2 9 22 2"
                                        />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td>
                                <a :href="'mailto:' + p.email">{{ p.email }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.phone_mobile">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="Mobiltelefon"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-smartphone table-icon"
                                    >
                                        <rect
                                            x="5"
                                            y="2"
                                            width="14"
                                            height="20"
                                            rx="2"
                                            ry="2"
                                        />
                                        <line x1="12" y1="18" x2="12" y2="18" />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone_mobile">{{
                                    p.phone_mobile
                                }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.phone">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="Telefon"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-phone table-icon"
                                    >
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                                        />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone">{{ p.phone }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.fax">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="Fax"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-file-text table-icon"
                                    >
                                        <path
                                            d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                                        />
                                        <polyline points="14 2 14 8 20 8" />
                                        <line x1="16" y1="13" x2="8" y2="13" />
                                        <line x1="16" y1="17" x2="8" y2="17" />
                                        <polyline points="10 9 9 9 8 9" />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone">{{ p.phone }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.username">
                            <td width="32px" class="text-left">
                                <el-tooltip
                                    effect="dark"
                                    content="Benutzername BDOnline"
                                    placement="top"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-key table-icon"
                                    >
                                        <path
                                            d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                                        />
                                    </svg>
                                </el-tooltip>
                            </td>
                            <td>{{ p.username }}</td>
                        </tr>
                    </table>

                    <div class="pb-3">
                        <contactlist-management-form
                            v-if="$can('edit clients') && p.email"
                            :listname="listname"
                            :listid="listid"
                            recipienttype="person"
                            :recipientid="p.id"
                            :subscribedto="p.subscribed"
                        />
                    </div>
                </div>
                <div
                    class="card-footer text-pebble text-center"
                    v-if="$can('edit people')"
                >
                    <a :href="'/intern/clients/person/editDoctor/' + p.id">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="action-icon feather feather-edit-2 mr-2"
                        >
                            <path d="M16 3l5 5L8 21H3v-5L16 3z" />
                        </svg>
                    </a>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deletePerson(p.id)"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="action-icon feather feather-trash-2"
                    >
                        <path
                            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <p v-if="$can('edit people')">
            <a
                :href="'/intern/clients/addDoctor/' + client_id"
                class="btn btn-default"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="mr-1 feather feather-plus-circle"
                >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="16" y2="12" /></svg
                >Arzt hinzufügen
            </a>
        </p>

        <div
            id="assistants"
            v-if="assistants.length > 0"
            class="mt-4 text-left"
        >
            <div
                class="card text-left mb-3"
                v-for="p in assistants"
                :key="p.id"
                :id="'person_' + p.id"
            >
                <div class="card-body">
                    <h5>{{ p.title }} {{ p.firstname }} {{ p.lastname }}</h5>
                    <span class="mb-4 badge badge-secondary">Helferin</span>

                    <table
                        class="table table-borderless"
                        v-if="
                            p.phone ||
                            p.phone_mobile ||
                            p.fax ||
                            p.email ||
                            p.birthday
                        "
                    >
                        <tr v-if="p.email">
                            <td width="32px" class="text-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-send table-icon"
                                >
                                    <line x1="22" y1="2" x2="11" y2="13" />
                                    <polygon
                                        points="22 2 15 22 11 13 2 9 22 2"
                                    />
                                </svg>
                            </td>
                            <td>
                                <a :href="'mailto:' + p.email">{{ p.email }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.phone_mobile">
                            <td width="32px" class="text-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-smartphone table-icon"
                                >
                                    <rect
                                        x="5"
                                        y="2"
                                        width="14"
                                        height="20"
                                        rx="2"
                                        ry="2"
                                    />
                                    <line x1="12" y1="18" x2="12" y2="18" />
                                </svg>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone_mobile">{{
                                    p.phone_mobile
                                }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.phone">
                            <td width="32px" class="text-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-phone table-icon"
                                >
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                                    />
                                </svg>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone">{{ p.phone }}</a>
                            </td>
                        </tr>
                        <tr v-if="p.fax">
                            <td width="32px" class="text-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-file-text table-icon"
                                >
                                    <path
                                        d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                                    />
                                    <polyline points="14 2 14 8 20 8" />
                                    <line x1="16" y1="13" x2="8" y2="13" />
                                    <line x1="16" y1="17" x2="8" y2="17" />
                                    <polyline points="10 9 9 9 8 9" />
                                </svg>
                            </td>
                            <td>
                                <a :href="'tel:' + p.phone">{{ p.phone }}</a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    class="card-footer text-pebble text-center"
                    v-if="$can('edit people')"
                >
                    <a :href="'/intern/clients/person/editAssistant/' + p.id">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="action-icon feather feather-edit-2 mr-2"
                        >
                            <path d="M16 3l5 5L8 21H3v-5L16 3z" />
                        </svg>
                    </a>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deletePerson(p.id)"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="action-icon feather feather-trash-2"
                    >
                        <path
                            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <p v-if="$can('edit people')">
            <a
                :href="'/intern/clients/addAssistant/' + client_id"
                class="btn btn-default"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="mr-1 feather feather-plus-circle"
                >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="8" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="16" y2="12" /></svg
                >Helferin hinzufügen
            </a>
        </p>
    </div>
</template>

<script>
export default {
    props: ["people", "client_id", "listname", "listid"],
    data() {
        return {
            returnvalue: 0,
        }
    },
    computed: {
        doctors() {
            return this.people.filter((person) => {
                return person.type == "Arzt";
            });
        },
        assistants() {
            return this.people.filter((person) => {
                return person.type == "Helferin";
            });
        },
    },
    methods: {
        async deletePerson(id) {
            try {
                // Check for duties
                const dutiesResponse = await fetch("/intern/clients/person/checkForDuties/" + id);
                const hasDuties = await dutiesResponse.json();

                if (hasDuties) {
                    this.$noty.error("Person kann nicht gelöscht werden, da noch Dienste zugewiesen sind.");
                    return;
                }

                // If no duties, ask for confirmation
                this.$confirm(
                    "Diese Aktion wird die Person dauerhaft löschen. Fortfahren?",
                    "Warnung",
                    {
                        confirmButtonText: "Ja, löschen",
                        cancelButtonText: "Abbrechen",
                        type: "warning",
                    }
                )
                .then(() => {
                    // Delete the person and refresh page
                    var url = "/intern/clients/person/delete/" + id;
                    axios
                        .delete(url)
                        .then((response) => {
                            console.log("Item deleted");
                            let itemDiv = document.getElementById(
                                "person_" + id
                            );
                            itemDiv.parentNode.removeChild(itemDiv);
                            this.$noty.success(
                                "Person wurde erfolgreich gelöscht!"
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$noty.error(
                                "Fehler beim Löschen! Bitte noch einmal versuchen."
                            );
                        });
                })
                .catch(() => {
                    this.$noty.info("Löschen abgebrochen!");
                });
            } catch (error) {
                console.error("Fehler bei der Prüfung auf existierende Dienste!", error);
                this.$noty.error("Fehler bei der Prüfung auf existierende Dienste!");
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table th,
.table td {
    padding: 0.2rem;
}

.table a,
.facts {
    text-decoration: none;
    color: #555555;
    font-size: 14px;
}

.fs-14 {
    font-size: 14px;
}

h5 {
    color: #ff6e21;
}

.card {
    // border: 2px solid #FF6E21;
    // border-top: 6px solid #9FB3C8;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-body {
    padding-top: 6px;
    padding-bottom: 0px;
}

.card-footer {
    background: #e4eaf0;
    border: 0;
    padding: 12px;
}

.text-pebble {
    color: #9fb3c8;
}

svg {
    width: 16px;
    height: 16px;
}
</style>
