<template>
    <div class="error-text text-left">
            <svg class="error-icon">
            <use xlink:href="/svg/feather-sprite.svg#zap"/>
        </svg> {{ errortext }}
    </div>
</template>

<script>
export default {
    props: {
        errortext: String,
    }
}
</script>

<style>
    .error-text {
        color: #e3342f;
        font-size: 0.8rem;
        line-height: 16px;
        margin-top: 10px;
        text-transform: uppercase;
    }

    .error-icon {
        width: 16px;
        height: 16px;
        fill: none;
        stroke: #e3342f;
        margin-left: 16px;
        margin-right: 4px;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
</style>

