<template>
<div>
    <form @submit.prevent="submit">
        <!-- <div class="form-row mb-3">
            <div class="col">
                <el-select
                    v-model="duty.orderer.id"
                    filterable
                    remote
                    focus
                    ref="doc"
                    reserve-keyword
                    placeholder="Arzt (Kunde)"
                    :remote-method="findDocOnDuty"
                    :loading="loading">
                    <el-option
                        :key="duty.orderer.id"
                        :value="duty.orderer.id"
                        :label="duty.orderer.lastname + ', ' + duty.orderer.firstname">
                    </el-option>
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.lastname + ', ' + item.firstname"
                    :value="item.id">
                    </el-option>
                </el-select>
                <form-error v-if="errors && errors.person_orderer_id" :errortext="errors.person_orderer_id[0]"></form-error>
            </div>
        </div> -->
        <div class="form-row mb-3">
            <div class="col">
                <input type="text" v-mask="'##.##.####'" v-model="edit_start" class="form-control d-inline-flex mr-3" style="width: 130px;">
                <input type="number" min="0" max="23" v-model="edit_start_h" class="form-control d-inline-flex" style="width: 64px;"> :
                <input type="number" min="0" max="59" v-model="edit_start_m" class="form-control d-inline-flex" style="width: 64px;">
            <!-- <form-date v-model="duty.start" /> -->
            <span class="rockblue icon__chevron"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg></span>
            <!-- <form-date v-model="duty.end"/> -->
            <input type="text" v-mask="'##.##.####'" v-model="edit_end" class="form-control d-inline-flex mr-3" style="width: 130px;">
                <input type="number" min="0" max="23" v-model="edit_end_h" class="form-control d-inline-flex" style="width: 64px;"> :
                <input type="number" min="0" max="59" v-model="edit_end_m" class="form-control d-inline-flex" style="width: 64px;">
            <form-error v-if="errors && errors.start" :errortext="errors.start[0]"></form-error>
            <form-error v-if="errors && errors.end" :errortext="errors.end[0]"></form-error>
            </div>
            <transition name="fade">
                <div class="col-md-2 pr-2 pt-2 text-right" v-if="weekendcalc">
                    <span class="badge badge-primary">Wochenende</span>
                </div>
            </transition>
        </div>

        <div class="form-row mb-3">
            <div class="col text-center">
                <input type="text" class="form-control" :class="errors && errors.notes ? 'form-error' : ''" id="notes" placeholder="Notiz zum Dienst / Bonus" v-model="duty.notes">
                <form-error v-if="errors && errors.notes" :errortext="errors.notes[0]"></form-error>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="col text-center">
                <input type="text" class="form-control" :class="errors && errors.suffix ? 'form-error' : ''" id="suffix" placeholder="Zusatz-Name (z.B. West)" v-model="duty.suffix">
                <form-error v-if="errors && errors.suffix" :errortext="errors.suffix[0]"></form-error>
            </div>
            <div class="col text-center">
                <input type="text" class="form-control" :class="errors && errors.internal_note ? 'form-error' : ''" id="internal_note" placeholder="Interne Notiz" v-model="duty.internal_note">
                <form-error v-if="errors && errors.internal_note" :errortext="errors.internal_note[0]"></form-error>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="col pl-2">
                <el-switch
                    v-model="duty.vip"
                    active-color="#5cb85c"
                    inactive-color="#9FB3C8"
                    active-text="VIP Dienst"
                    inactive-text="">
                </el-switch>
                <form-error v-if="errors && errors.vip" :errortext="errors.vip[0]"></form-error>
            </div>
            <div class="col pl-2">
                <el-switch
                    v-model="duty.type"
                    active-color="#9FB3C8"
                    inactive-color="#9FB3C8"
                    inactive-text="Fahrdienst"
                    inactive-value="Fahrdienst"
                    active-value="Sitzdienst"
                    active-text="Sitzdienst">
                </el-switch>
                <form-error v-if="errors && errors.type" :errortext="errors.type[0]"></form-error>
            </div>

        </div>

        <div class="row mt-3">
            <div class="col">
                <button class="btn btn-primary btn-block" :disabled="loading" type="submit">Dienst speichern</button>
            </div>
        </div>
    </form>

</div>
</template>

<script>

import FormMixin from '../../../../FormMixin';
// import FormDate from './FormDate.vue';
import {TheMask} from 'vue-the-mask';

export default {
    props: ['dutyprop', 'start', 'end'],
    components: {
        TheMask,
    },
    mixins: [ FormMixin ],
      data() {
        return {
            action: '/intern/duties/' + this.dutyprop.id,

            options: [],
            loading: false,
            duties: [],
            duty: {},
            selected: [],
            edit_start: '',
            edit_start_h: '',
            edit_start_m: '',
            edit_end: '',
            edit_end_h: '',
            edit_end_m: '',

            errors: {},
            success: false,
            loaded: true,
            resultset: {},
        };
      },

      computed: {
        startDateWithTime() {
            return '';
        },
        weekendcalc() {
            return this.$moment(this.duty.start).isoWeekday() == 6 || this.$moment(this.duty.start).isoWeekday()==7 || this.$moment(this.duty.end).isoWeekday() == 6 || this.$moment(this.duty.end).isoWeekday() == 7;
        },
        startdate() {
            return this.$moment(String(this.edit_start), "DD.MM.YYYY").hour(this.edit_start_h).minute(this.edit_start_m).format("YYYY-MM-DD HH:mm:00")
        },
        enddate() {
            return this.$moment(String(this.edit_end), "DD.MM.YYYY").hour(this.edit_end_h).minute(this.edit_end_m).format("YYYY-MM-DD HH:mm:00")
        }
      },
      created() {
          this.duty = this.dutyprop
          this.edit_start = this.$moment(this.duty.start).format("DD.MM.YYYY")
          this.edit_start_h = this.$moment(this.duty.start).format("HH")
          this.edit_start_m = this.$moment(this.duty.start).format("mm")

          this.edit_end = this.$moment(this.duty.end).format("DD.MM.YYYY")
          this.edit_end_h = this.$moment(this.duty.end).format("HH")
          this.edit_end_m = this.$moment(this.duty.end).format("mm")

          if(this.duty.vip == 1) {
              this.duty.vip = true
          }
      },
      methods: {
        submit() {
            if (this.loaded) {
                this.duty.start = this.startdate
                this.duty.end = this.enddate
                this.loaded = false;
                this.success = false;
                this.errors = {};

                axios.put(this.action, this.duty, { timeout: 6000 }).then(response => {
                    this.loaded = true
                    this.success = true
                    this.handleResponseResult(response.data)
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                    }
                });
            }
            this.loading = false;
        },
        handleResponseResult(response) {
            this.$noty.success("Dienst geändert!")
            // this.clearForm()
            this.$emit('duty-updated', response)
        },
        findDocOnDuty(query) {
            if (query !== '') {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;

                if(query.length > 2){
                    axios.get('/intern/duties/searchDoc',{params: {query: query}}).then(response => {
                       this.options = response.data;
                    //    let i = this.options.map(item => item.id).indexOf(this.duty.orderer.id) // find index of your object
                    //    console.log(this.options)
                    //    this.options.splice(i, 1) // remove it from array
                    });
                }
            }, 200);
            } else {
            this.options = [];
            }
        }
      },
    };
</script>

<style lang="scss" scoped>

.icon__chevron {
    padding: 6px 12px;
}

.el-select {
        display: input;
    width: 100%;
}
</style>
