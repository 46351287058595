/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
// window.Vue = require('vue');

import VueNoty from 'vuejs-noty'
Vue.use(VueNoty)

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/de'

Vue.use(ElementUI, {
    locale
})

import bootstrapselect from 'bootstrap-select';

import moment from 'moment/moment';
// const moment = require('moment')
require('moment/locale/de');

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);

Vue.use(require('vue-moment'), {
    moment
})

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDHf-kzzVFrUJkvGgRHl--NVd2Mof1Jm2g',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})

/**
 * VUE Filters for Numbers and Dates
 */
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format("LL")
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('form-anfrage', require('./components/website/FormAnfrage.vue').default);
// Vue.component('example', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

$('.custom-file-input').on('change', function() {
    let fileName = $(this).val().split('\\').pop();
    let label = $(this).siblings('.custom-file-label');

    if (label.data('default-title') === undefined) {
        label.data('default-title', label.html());
    }

    if (fileName === '') {
        label.removeClass("selected").html(label.data('default-title'));
    } else {
        label.addClass("selected").html(fileName);
    }
});

window.Vue = Vue;

let token = document.head.querySelector('meta[name="user-id"]');
if (token) {
    window.userid = token.content;
} else {
    console.error('UserID token not found');
}
