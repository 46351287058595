<template>
    <div class="col text-right">
        <a
            v-if="!usersinfo.length && requestPending"
            href="#"
            @click.prevent="openFavorites"
            alt="Vertreter anzeigen, die das Gebiet als Favorit gewählt haben"
        >
            <star-icon size="1.5x" class="icon-orange text-icon"></star-icon>
            Favoriten anzeigen</a
        >
        <strong
            v-if="!usersinfo.length && !requestPending"
            class="text-left text-danger"
            >Keine passenden Vertreter gefunden!</strong
        >
        <strong v-if="usersinfo.length" class="text-left"
            >Passende Vertreter</strong
        >
        <div
            v-if="usersinfo.length"
            class="mt-2 bg-rockblue rounded p-2 text-left"
        >
            <ul class="list-group list-group-flush">
                <li
                    v-for="user in usersinfo"
                    :key="user.id"
                    class="list-group-item"
                >
                    <a
                        :href="'/intern/users/details/' + user.id"
                        target="_blank"
                        ><user-icon size="1.5x" class="text-icon"></user-icon
                    ></a>

                    <el-tooltip
                        class="ml-3"
                        effect="dark"
                        :content="
                            user.favorites_additional_info != ''
                                ? user.favorites_additional_info
                                : 'Keine zusätzlichen Infos'
                        "
                        placement="right-end"
                    >
                        <span class="clickable">
                            {{ user.lastname }}, {{ user.name }}
                        </span>
                    </el-tooltip>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { StarIcon, UserIcon, FileTextIcon } from "vue-feather-icons";

export default {
    props: ["areaId", "holiday", "weekend", "type"],
    components: {
        StarIcon,
        UserIcon,
        FileTextIcon,
    },
    data() {
        return {
            action: "/intern/duties/assignDoc/getFavorites/",
            usersinfo: [],
            requestPending: true,
        };
    },
    mounted() {
        this.action =
            this.action +
            this.areaId +
            "/" +
            this.weekend +
            "/" +
            this.holiday +
            "/" +
            this.type;
    },
    methods: {
        async openFavorites() {
            await fetch(this.action)
                .then(async (response) => {
                    const data = await response.json();

                    if (!response.ok) {
                        const error =
                            (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                    this.usersinfo = await data.users;
                    this.requestPending = false;
                })
                .catch((error) => {
                    console.error("Fehler beim Abruf der Favoriten!", error);
                    this.$noty.error("Fehler beim Abruf der Favoriten!");
                    this.favorites = [];
                });
        },
    },
};
</script>

<style>
</style>
