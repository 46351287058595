<template>
  <tbody>
    <template v-for="(day, index) in duties" v-bind:class="isWeekendClass(index)">
      <tr v-for="(line, indexline) in day" :key="indexline" v-bind:class="isWeekendClass(index)">
        <th scope="row" class="divider-border p-2">
          <span>{{ index | moment("DD. dd") }}</span>
        </th>

        <template v-for="duty in line">
          <td
            class="fs-14 p-2 min-width-120 divider-border-tiny clickable"
            v-bind:class="isHighlightedClass(duty.start, duty.is_publicholiday, !duty.doc_on_duty, duty.cancellation_type)"
            v-bind:key="duty.id + 'details'"
            @click="openDuty(duty.id)"
          >
            <div :class="{ 'text-striked': duty.cancellation_type}">
              <span v-if="details">{{ duty.type }}</span>
              <span v-if="!details && duty.type == 'Fahrdienst'">FD</span>
              <span v-if="!details && duty.type == 'Sitzdienst'">SD</span>
              <span v-if="details">{{ duty.title }}</span>
              <span v-if="!details">{{ duty.shortname }}</span>
              {{ duty.suffix }}
              <br />

              <span v-if="details">{{ duty.orderer_firstname }} {{ duty.orderer_lastname }}</span>
              <span v-if="!details">{{ duty.orderer_lastname }}</span>
              <br />

              <!-- <span v-if="details">{{ duty.start | moment('DD.MM.YYYY') }}</span> -->

              <small class="text-primary" v-if="duty.vip && details">VIP Dienst</small>
            </div>
          </td>
          <td
            class="fs14 p-2 divider-border min-width-110 clickable"
            v-bind:class="isHighlightedClass(duty.start, duty.is_publicholiday, !duty.doc_on_duty, duty.cancellation_type)"
            v-bind:key="duty.id + 'doc'"
            @click="openDuty(duty.id)"
          >
            <div :class="{ 'text-striked': duty.cancellation_type}">
              {{ showShortDate(duty.start) }} - {{ showShortDate(duty.end) }}
              <br />
              <span v-if="duty.doc_on_duty">{{ duty.doc_on_duty }}</span>
            </div>
          </td>
        </template>

        <reserve-row :day="index" v-if="indexline == 0" />
      </tr>
    </template>
  </tbody>
</template>

<script>
export default {
  props: ["duties", "details", "reserves"],
  methods: {
    isWeekendClass(date) {
      let momentdate = this.$moment(date);
      if (momentdate.isoWeekday() == 6 || momentdate.isoWeekday() == 7) {
        return "isweekend";
      } else {
        return "";
      }
    },
    isHighlightedClass(
      date,
      ispublicholiday = false,
      isopenduty = false,
      iscancelled = false
    ) {
      let momentdate = this.$moment(date);
      let classname = "";
      if (
        momentdate.isoWeekday() == 6 ||
        momentdate.isoWeekday() == 7 ||
        ispublicholiday
      ) {
        classname = classname.concat("isweekend ");
      }

      if (isopenduty && !iscancelled) {
        classname = classname.concat("font-weight-bold ");
      }
      return classname;
    },
    openDuty(id) {
      window.location.href = "/intern/duties/details/" + id;
    },
    isFullHour(date) {
      let momentdate = this.$moment(date);
      if (momentdate.minutes() == "00") {
        return true;
      } else {
        return false;
      }
    },
    showShortDate(date) {
      let momentdate = this.$moment(date);
      if (momentdate.minutes() == "00") {
        return momentdate.format("HH");
      } else {
        return momentdate.format("HH:mm");
      }
    }
  }
};
</script>

<style lang="scss">
.vipColumn {
  background: #d4af37 !important;
}

.isspecial {
  background: #ffdbc8 !important;
}

.isweekend,
.table-bordered .isweekend {
  background: #ffdbc8 !important;
}

.min-width-110 {
  min-width: 110px;
}

.min-width-120 {
  min-width: 120px;
}

.table-striped tr:nth-child(odd) td {
  background-color: #ededea;
  &.isweekend {
    background: #ffdbc8 !important;
  }
}

@media print {
  .table-bordered .isspecial {
    border: 2px solid rgb(245, 142, 87) !important;
  }

  .table-bordered .isweekend {
    border: 2px solid rgb(245, 142, 87) !important;
  }
}
</style>
