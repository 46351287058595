<template>
    <td class="reserve-col" v-loading="loading" v-if="isVisible">
        <template v-if="$can('edit duty')">
            <a v-if="reserve.id" :href="'/intern/reserves/' + reserve.id" class="mr-1">Reserve
            <br />
            <span v-if="reserve.lastname">
            {{ reserve.lastname }}
            <br />
            </span>
            <span v-if="reserve.status_label">{{ reserve.status_label }}</span>
            </a>
        </template>
        <template v-else>
            Reserve
            <br />
            <span v-if="reserve.lastname">
            {{ reserve.lastname }}
            <br />
            </span>
            <span v-if="reserve.status_label">{{ reserve.status_label }}</span>
        </template>
      </td>
</template>

<script>
export default {
    props: ["day"],
    data() {
        return {
            reserve: {},
            loading: false,
            isVisible: true
        };
    },
    mounted: function() {
        this.loading = true;
        let url = "/intern/duties/reserves/" + this.day;
        // console.log(this.day);
        axios
            .get(url, {})
            .then(response => {
                this.reserve = response.data;
                this.loading = false;
                if (!this.reserve.status_label) {
                    this.isVisible = false;
                }
            })
            .catch(error => {
                console.log(error);
                this.$noty.error("Fehler beim Laden von Reserven.");
            });
    },
    methods: {
        isValidDay() {
            let momentdate = this.$moment(this.day);
            if (
                momentdate.isoWeekday() == 6 ||
                momentdate.isoWeekday() == 7 ||
                ispublicholiday
            ) {
                classname = classname.concat("isweekend ");
            }
        }
    }
};
</script>

<style>
.reserve-col {
    background-color: #aed7ff !important;
    color: #333;
}
</style>
