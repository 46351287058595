<template>
  <div>

    <div>
        <!--  v-if="duties.length > 0" -->
      <el-table
        v-loading="loading"
        stripe
        :data="duties"
        :default-sort="{prop: 'start', order: 'ascending'}"
        :row-class-name="tableRowClassName"
        style="width: 100%"
      >
        <!-- <el-table-column label="Monat" prop="start" sortable width="114px">
          <template slot-scope="scope">
            {{ scope.row.start | moment('MMM') }}
            <span v-if="scope.row.type != 'Reserve' && scope.row.vip" class="text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-alert-triangle ml-1"
              >
                <path
                  d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12" y2="17" />
              </svg>
            </span>
            <el-tooltip class="item" effect="dark" content="Ist vorgemerkt!" placement="top">
              <span v-if="scope.row.status == '15_prebooked'" class="text-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="ml-1"
                >
                  <path
                    d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="Start" prop="start" sortable width="120px">
          <template slot-scope="scope">{{ scope.row.start | moment('DD.MM.YY - ddd') }}</template>
        </el-table-column> -->
        <!-- <el-table-column label="Dienst" prop="dutytitle" sortable></el-table-column> -->
        <el-table-column label="Typ" prop="type" sortable width="110px"></el-table-column>

        <!-- <el-table-column label="Zeiten" prop="start" width="115px">
          <template
            slot-scope="scope"
          >{{ scope.row.start | moment('HH:mm') }}<span v-if="scope.row.type != 'Reserve'"> - {{ scope.row.end | moment('HH:mm') }}</span></template>
        </el-table-column> -->
        <!-- <el-table-column label="Praxis" prop="orderer" sortable></el-table-column> -->
        <!-- <el-table-column label="Interne Notiz" prop="internal_note" sortable></el-table-column> -->
        <!-- <el-table-column label="Notiz" prop="notes" sortable></el-table-column> -->
        <el-table-column fixed="right" align="right" width="50px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Dienst-Infos öffnen" placement="top">
              <a :href="'/intern/duties/details/' + scope.row.id">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-info table-icon"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="16" x2="12" y2="12" />
                  <line x1="12" y1="8" x2="12" y2="8" />
                </svg>
              </a>
            </el-tooltip>
            <!--
                <el-tooltip class="item" effect="dark" content="Praxis bearbeiten" placement="top" v-if="$can('edit clients')">
                <a :href="'/intern/clients/edit/' + scope.row.id" v-if="$can('edit clients')">
                <edit-2-icon
                    class="table-icon"
                ></edit-2-icon>
                </a>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="E-Mail an die Praxis" placement="top">
                <a :href="'mailto:' + scope.row.email">
                <mail-icon class="table-icon"></mail-icon>
                </a>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Praxis SOFORT LÖSCHEN - noch nicht verfügbar" placement="top" v-if="$can('edit clients')">
                <user-x-icon
                class="table-icon table-icon--red"
                @click="handleDelete(scope.$index, scope.row)"
                ></user-x-icon>
            </el-tooltip>-->
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
    </div>
    <!-- v-else  -->
    <div class="pt-4 pb-4 text-center">
      <img src="/img/undraw_tree.svg" alt="Keine Diente" class="img img-fluid mb-3" width="300px" />
      <br />Du hast aktuell keine offenen Dienste
      <br />
    </div>
  </div>
</template>

<script>
export default {
  props: ["duties"],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      let momentdate = this.$moment(row.start);
      if (
        momentdate.isoWeekday() == 6 ||
        momentdate.isoWeekday() == 7 ||
        row.is_publicholiday
      ) {
        return "highlight-row";
      }
      return "";
    }
  }
};
</script>

<style scoped lang="scss">
.table-icon {
  width: 18px;
  height: 18px;
  stroke: #102b43;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
  margin-left: 12px;

  &--red {
    stroke: #fe6f5e;
  }
}
</style>
