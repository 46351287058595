<template>
    <file-pond
        name="uploadfile"
        :ref="'pond_' + collection"
        :server="server"
        class-name="my-pond"
        label-idle="Persönliche Dateien (Scan als Bild, PDF) hier ablegen..."
        allow-multiple="false"
        accepted-file-types="image/*, application/pdf"
        v-bind:files="fields.files"
        v-on:init="handleFilePondInit"
        @processfile="handleProcessFile"
    />
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
        // image-transform-output-mime-type="'image/jpeg'" an filepond oben anhängen

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
);
    // FilePondPluginImageTransform


export default {
    components: {
        FilePond,
    },
    props: ["url", "collection", "csrf"],
    data() {
        return {
            server: {},
            pondname: "",
            fields: {
                files: [],
            },
        };
    },
    mounted() {
        (this.server = {
            url: this.url,
            process: {
                headers: {
                    "X-CSRF-TOKEN": this.csrf,
                },
            },
        }),
            (this.pondname = "pond_" + this.collection);
    },
    methods: {
        handleFilePondInit: function () {
            console.log("FilePond has initialized");
        },
        handleProcessFile: function (error, file) {
            if (error) {
                console.error('Error processing file:', error);
                return;
            }
            console.log('File processed, MIME type:', file.fileType);
        },
    },
};
</script>

<style>
.filepond--drop-label {
    font-size: 14px !important;
    background-color: #f5f7fa;
    font-weight: 600;
    border-radius: 8px;
}
</style>
