<template>
<div>
    <transition name="bounce">
        <div class="card-people text-left mb-3" v-if="localDuty.doc_on_duty">
            <div class="card-people-body">
                <h5>
                    {{ localDuty.doc_on_duty.title }} {{ localDuty.doc_on_duty.name }} {{ localDuty.doc_on_duty.lastname }}
                    <span class="rockblue" v-if="localDuty.status == '15_prebooked'">/ VORGEMERKT</span>
                </h5>

                <table class="table table-borderless" v-if="
                                            localDuty.doc_on_duty.phone ||
                                            localDuty.doc_on_duty.phone_mobile ||
                                            localDuty.doc_on_duty.fax ||
                                            localDuty.doc_on_duty.email ||
                                            localDuty.doc_on_duty.birthday
                                        ">
                    <tr v-if="localDuty.doc_on_duty.email">
                        <td width="32px" class="text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send table-icon">
                                <line x1="22" y1="2" x2="11" y2="13" />
                                <polygon points="22 2 15 22 11 13 2 9 22 2" />
                            </svg>
                        </td>
                        <td>
                            <a :href="
                                                        'mailto:' + localDuty.doc_on_duty.email
                                                    ">{{ localDuty.doc_on_duty.email }}</a>
                        </td>
                    </tr>
                    <tr v-if="localDuty.doc_on_duty.phone_mobile">
                        <td width="32px" class="text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone table-icon">
                                <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />
                                <line x1="12" y1="18" x2="12" y2="18" />
                            </svg>
                        </td>
                        <td>
                            <a :href="
                                                        'tel:' +
                                                        localDuty.doc_on_duty.phone_mobile
                                                    ">{{ localDuty.doc_on_duty.phone_mobile }}</a>
                        </td>
                    </tr>
                    <tr v-if="localDuty.doc_on_duty.phone">
                        <td width="32px" class="text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone table-icon">
                                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                            </svg>
                        </td>
                        <td>
                            <a :href="'tel:' + localDuty.doc_on_duty.phone">{{ localDuty.doc_on_duty.phone }}</a>
                        </td>
                    </tr>
                    <tr v-if="localDuty.doc_on_duty.fax">
                        <td width="32px" class="text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text table-icon">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="16" y1="13" x2="8" y2="13" />
                                <line x1="16" y1="17" x2="8" y2="17" />
                                <polyline points="10 9 9 9 8 9" />
                            </svg>
                        </td>
                        <td>
                            <a :href="'tel:' + localDuty.doc_on_duty.phone">{{ localDuty.doc_on_duty.phone }}</a>
                        </td>
                    </tr>
                </table>

                <a href="#" v-if="
                                            localDuty.doc_on_duty &&
                                            $can('delete doconduty') &&
                                            duty.status == '15_prebooked'
                                        " class="btn btn-sm btn-default confirm-button mt-3" @click.prevent="setAsConfirmed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check mr-1">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <polyline points="17 11 19 13 23 9" />
                    </svg>
                    Vertreter bestätigen
                </a>
            </div>
        </div>
    </transition>

    <form action="#" @submit.prevent="submit" v-if="$can('store doconduty')">
        <!-- <v-select v-model="fields.docInCharge" :options="docs" label="fullnameReverse" autocomplete="on" placeholder="Vertreter auswählen..." id="select_doconduty" :reduce="docs => docs.id" style="max-width: 400px; width: 100%">
                      </v-select>-->
        <el-select v-model="fields.docInCharge" filterable placeholder="Vertreter wählen" style="width: 100%">
            <el-option v-for="d in docs" :key="d.id" :label="d.fullnameReverse" :value="d.id"></el-option>
        </el-select>
        <form-error v-if="errors && errors.docInCharge" :errortext="errors.docInCharge[0]"></form-error>
        <div class="form-row mt-3" v-if="duty.status == '10_open' || duty.status == '15_prebooked'">
            <div class="col">
                <el-switch v-model="fields.prebooking" active-text="Nur vormerken"></el-switch>
            </div>
            <duty-map-doctor-favorites-panel :area-id="localDuty.orderer.client.area.id" :weekend="localDuty.is_weekend" :holiday="localDuty.is_publicholiday" :type="localDuty.type" />
        </div>
        <div v-else class="mt-3">
            <duty-map-doctor-favorites-panel :area-id="localDuty.orderer.client.area.id" :weekend="localDuty.is_weekend" :holiday="localDuty.is_publicholiday" :type="localDuty.type" />
        </div>

        <button v-if="!localDuty.doc_on_duty" type="submit" class="btn btn-sm btn-primary mt-3">
            Vertreter zuweisen
        </button>
        <button v-if="localDuty.doc_on_duty" type="submit" class="btn btn-sm btn-primary mt-3">
            Vertreter ändern
        </button>

        <a href="#" v-if="localDuty.doc_on_duty && $can('delete doconduty')" class="btn btn-sm btn-default mt-3" @click.prevent="setAsOpen">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-1">
                <polyline points="3 6 5 6 21 6" />
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
            </svg>
            Vertreter löschen
        </a>
    </form>

    <button v-if="selfAssignmentActivated && !localDuty.doc_on_duty && localDuty.status == '10_open'" class="btn btn-sm btn-primary mt-3" @click.prevent="assignToMe">Ich übernehme den offenen Dienst verbindlich!</button>
</div>
</template>

<script>
import FormMixin from "../../../../FormMixin";
import DutyMapDoctorFavoritesPanel from "./DutyMapDoctorFavoritesPanel.vue";

export default {
    components: {
        DutyMapDoctorFavoritesPanel
    },
    mixins: [FormMixin],
    props: ["duty", "docsRaw"],
    data() {
        return {
            loading: true,
            action: "/intern/duties/assignDoc/" + this.duty.id,
            fields: {
                docInCharge: null,
                prebooking: false,
            },
            docs: [],
            selfAssignmentActivated: false,
            localDuty: {},
            setAsOpenAction: "/intern/duties/setAsOpen/" + this.duty.id,
            assignToMeAction: "/intern/duties/assignToMe/" + this.duty.id,
            confirmPrebookingAction: "/intern/duties/confirmPrebooking/" + this.duty.id,
        };
    },
    created() {
        this.localDuty = this.duty;
        this.fields.prebooking = this.duty.statusa == "15_prebooked";
    },
    mounted() {
        this.docs = Object.keys(this.docsRaw).map((key) => {
            return this.docsRaw[key];
        });
        this.checkSelfassignmentActivated();
    },
    methods: {
        checkSelfassignmentActivated() {
            try {
                axios.get('/intern/duties/assignToMe/checkSelfassignmentActivated').then((response) => {
                    this.selfAssignmentActivated = response.data.status;
                    console.log(response.data.status);
                });
            } catch (error) {
                console.error('Error fetching self assignment status:', error);
            }
        },
        handleResponseResult(response) {
            this.$noty.success("Vertreter wurde zugeordnet!");
            let prebook = this.fields.prebooking;
            this.clearForm();
            this.localDuty.doc_on_duty = response;

            if (prebook) {
                this.localDuty.status = "15_prebooked";
            } else {
                this.localDuty.status = "20_assigned";
            }

            this.$emit("status-changed", this.localDuty.status);
            this.$emit("doc-changed", this.localDuty.doc_on_duty);
        },
        clearForm() {
            this.fields = {
                docInCharge: null,
                prebooking: false,
            };
        },
        setAsOpen() {
            this.$confirm(
                    "Möchtest du den Vertreter aus dem Dienst entfernen und so<br><strong>den Dienst wieder auf die ODL setzen?</strong>",
                    "Vertreter abziehen", {
                        confirmButtonText: "JA",
                        cancelButtonText: "Nein, noch nicht",
                        type: "info",
                        center: true,
                        dangerouslyUseHTMLString: true,
                    }
                )
                .then(() => {
                    axios
                        .post(this.setAsOpenAction, {
                            timeout: 6000
                        })
                        .then((response) => {
                            this.localDuty.doc_on_duty = null;
                            this.localDuty.status = "10_open";
                            this.$noty.success("Dienst ist wieder offen!");
                            this.$emit("status-changed", "10_open");
                        })
                        .catch((error) => {
                            this.$noty.error(
                                "Fehler: Die Änderung wurde nicht gespeichert!"
                            );
                        });
                })
                .catch(() => {
                    this.$noty.warning("Die Änderung wurde nicht gespeichert!");
                });
        },
        setAsConfirmed() {
            axios
                .post(this.confirmPrebookingAction, {
                    timeout: 6000
                })
                .then((response) => {
                    this.$noty.success(
                        "Vertreter erfolgreich bestätigt - Dienst ist jetzt vergeben!"
                    );
                    this.localDuty.status = "20_assigned";
                    this.$emit("status-changed", "20_assigned");
                })
                .catch((error) => {
                    this.$noty.error(
                        "Fehler: Die Änderung wurde nicht gespeichert!"
                    );
                });
        },
        assignToMe() {
            this.$confirm(
                    "Möchtest du den Dienst verbindlich übernehmen?",
                    "Offenen Dienst übernehmen", {
                        confirmButtonText: "JA",
                        cancelButtonText: "Nein, doch nicht",
                        type: "info",
                        center: true,
                    }
                )
                .then(() => {
                    axios
                        .post(this.assignToMeAction, {
                            timeout: 6000
                        })
                        .then((response) => {
                            this.$noty.success(
                                "Du bist für den Dienst eingetragen!"
                            );
                            this.localDuty.doc_on_duty = response.data;
                            this.localDuty.status = "20_assigned";
                            this.$emit("status-changed", "20_assigned");
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 422) {
                                this.$noty.error("Zuordnung wegen Dienstkonflikt nicht möglich!");
                            } else {
                                this.$noty.error(
                                    "Fehler: Die Änderung wurde nicht gespeichert!"
                                );
                            }
                        });
                })
                .catch(() => {
                    this.$noty.warning("Die Änderung wurde nicht gespeichert!");
                });
        },
    },
};
</script>

<style>
.table th,
.table td {
    padding: 0.2rem;
}

.table a,
.facts {
    text-decoration: none;
    color: #555555;
    font-size: 14px;
}

.fs-14 {
    font-size: 14px;
}

h5 {
    color: #ff6e21;
}

.card-people {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-people-body {
    padding: 12px;
    padding-bottom: 6px;
}

.card-people-footer {
    background: #e4eaf0;
    border: 0;
    padding: 12px;
}

.text-pebble {
    color: #9fb3c8;
}

svg.feather {
    width: 16px;
    height: 16px;
}
</style>
