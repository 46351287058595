<template>
  <button class="btn btn-primary btn-sm mt-0 mb-0" @click="confirm">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="btn--icon mr-1 feather feather-mail"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
      <polyline points="22,6 12,13 2,6" />
    </svg>
    Liste schicken
  </button>
</template>

<script>
import FormMixin from "../../../../FormMixin";
export default {
  mixins: [FormMixin],
  props: ["doc"],
  data() {
    return {
      action: "/intern/duties/prebooked/send/" + this.doc,
      fields: {}
    };
  },
  mounted() {
    this.fields.doc = this.doc;
  },
  methods: {
    confirm() {
      this.$confirm(
        "Möchtest du die E-Mail mit Diensten jetzt an den Vertreter senden?",
        "Jetzt senden?",
        {
          confirmButtonText: "Senden",
          cancelButtonText: "Abbrechen",
          type: "info"
        }
      )
        .then(() => {
          this.submit();
        })
        .catch(() => {
          this.$noty.info("Abbruch - E-Mail nicht verschickt!");
        });
    },
    handleResponseResult(response) {
      this.$noty.success("E-Mail wird verschickt!");
    }
  }
};
</script>

<style>
</style>