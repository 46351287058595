<template>
    <div>
        <div class="row mb-1">
            <div class="col">
                <h2 class="float-left">
                    {{ month.getFullYear() }} / {{ month | moment("MMMM") }}
                </h2>
                <div class="float-right">
                    <small class="mr-2 text-uppercase text-muted"
                        >Monat wählen:</small
                    >
                    <el-date-picker
                        class="d-print-none"
                        v-model="month"
                        type="month"
                        format="MMMM yyyy"
                        v-bind:clearable="false"
                        placeholder="Monat auswählen"
                        @change="changeMonth"
                    ></el-date-picker>
                </div>
            </div>
        </div>
        <div class="row mb-3 d-print-none">
            <div class="col text-center">
                <el-radio-group v-model="dutyCategory" size="medium">
                    <el-radio-button label="all">Alle</el-radio-button>
                    <el-radio-button label="before"
                        >Vor BD Online</el-radio-button
                    >
                    <el-radio-button label="after"
                        >Nach BD Online</el-radio-button
                    >
                </el-radio-group>
            </div>
        </div>
        <div
            v-if="Object.keys(dutydata).length !== 0"
            v-loading="loading"
            class="overflow-scroll"
        >
            <div class="float-left mb-3" v-if="!fullscreen">
                <a
                    :href="
                        '/intern/duties/planfullscreen/?year=' +
                        month.getFullYear() +
                        '&month=' +
                        (month.getMonth() + 1)
                    "
                    target="_blank"
                    class="btn btn-sm btn-default"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="mr-1 feather feather-maximize"
                    >
                        <path
                            d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                        />
                    </svg>
                    Vollbild-Ansicht öffnen
                </a>
            </div>
            <table
                class="table table-striped table-bordered show-empty-cells small-fonts d-print-table"
                id="dutyPlan"
            >
                <thead class="thead text-uppercase text-muted clean-head">
                    <tr>
                        <th scope="col" class="border-top-0 p-2 divider-border">
                            Tag
                        </th>

                        <template v-for="n in columncountdata">
                            <th
                                scope="col"
                                class="border-top-0 p-2"
                                v-bind:key="n"
                            >
                                Dienst
                            </th>
                            <th
                                scope="col"
                                class="border-top-0 p-2 divider-border"
                                v-bind:key="n + 8"
                            >
                                Vertreter
                            </th>
                        </template>

                        <th scope="col" class="border-top-0 p-2"></th>
                    </tr>
                </thead>

                <duty-plan-row
                    :duties="dutydata"
                    :details="details"
                    :reserves="reserves"
                ></duty-plan-row>
            </table>
            <div class="d-print-none toolbar" v-if="fullscreen">
                <div>
                    <a
                        href="#"
                        onclick="window.print()"
                        class="float-left btn btn-sm btn-info"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="mr-1 feather feather-printer"
                        >
                            <polyline points="6 9 6 2 18 2 18 9" />
                            <path
                                d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"
                            />
                            <rect x="6" y="14" width="12" height="8" />
                        </svg>
                        Drucken
                    </a>
                </div>
                <div class="float-right mb-4 d-print-none">
                    <el-switch
                        v-model="details"
                        active-text="Erklärend"
                        inactive-text="Kompakt"
                    ></el-switch>
                </div>
            </div>
        </div>
        <div v-else class="pt-4 pb-4 text-center">
            <img
                src="/img/undraw_tree.svg"
                alt="Keine Diente"
                class="img img-fluid mb-3"
                width="300px"
            />
            <br />Keine passenden Dienste gefunden
            <br />
        </div>

        <div class="row pl-3 mt-5" id="reservesTable">
            <div class="col-md-12">
                <h2>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-anchor"
                    >
                        <circle cx="12" cy="5" r="3" />
                        <line x1="12" y1="22" x2="12" y2="8" />
                        <path d="M5 12H2a10 10 0 0 0 20 0h-3" />
                    </svg>
                    Reserven
                </h2>
            </div>
            <div class="col">
                <reserve-list-compact
                    :reserves="reservesdata"
                ></reserve-list-compact>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "duties",
        "reserves",
        "columncount",
        "fullscreen",
        "yearpreset",
        "monthpreset",
    ],
    data() {
        return {
            month: new Date(),
            // monthMoment: {},
            // monthAsJs: new Date(),
            details: false,
            dutydata: {},
            reservesdata: {},
            columncountdata: 0,
            loading: false,
            dutyCategory: "all",
        };
    },
    created: function () {
        this.columncountdata = this.columncount;
        this.dutydata = this.duties;
        this.reservesdata = this.reserves;
        if (this.yearpreset && this.monthpreset) {
            this.month.setDate(1);
            this.month.setFullYear(this.yearpreset);
            this.month.setMonth(this.monthpreset - 1);
        } else {
            let lastSelectedMonth = localStorage.getItem('lastSelectedMonth');
            if (lastSelectedMonth) {
                this.month = new Date(JSON.parse(lastSelectedMonth));
                this.changeMonth(this.month);
            }
        }
    },
    watch: {
        dutyCategory: function () {
            this.changeMonth(this.month);
        },
    },
    methods: {
        changeMonth(newdate) {
            // window.location.href = '/intern/duties/planfullscreen/?year=' + newdate.getFullYear() + '&month=' + (newdate.getMonth() + 1)
            localStorage.setItem('lastSelectedMonth', JSON.stringify(newdate));

            var url = "/intern/duties/plan/data";
            loading: true;
            this.dutydata = {};
            this.reservesdata = {};

            axios
                .get(url, {
                    params: { newdate: newdate, category: this.dutyCategory },
                })
                .then((response) => {
                    this.dutydata = response.data.duties;
                    this.reservesdata = response.data.reserves;
                    this.columncountdata = response.data.columncount;
                    this.loading = false;
                    this.month = newdate;
                    // this.monthMoment = this.$moment(this.month)
                    this.$noty.success("Monat wurde geladen!");
                })
                .catch((error) => {
                    console.log(error);
                    this.$noty.error(
                        "Fehler beim Laden des Monats! Bitte noch einmal versuchen."
                    );
                });
        },
    },
};
</script>

<style>
.clean-head {
    border-top: 0;
    font-size: 14px;
}

.divider-border {
    border-right: 2px solid #333 !important;
}

@media print {
    .table-bordered .divider-border {
        border-right: 2px solid #333 !important;
    }
    .table-striped tr:nth-child(odd) td {
        background-color: #fff !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

.table-striped .divider-border {
    border-right: 2px solid #333 !important;
}

.overflow-scroll {
    overflow: auto;
}

.show-empty-cells {
    empty-cells: show !important;
}

.small-fonts {
    font-size: 12px !important;
}
</style>
