<template>
    <div>
        <div class="area__file" v-for="(file, key) in mediafiles" :key="key">
            <a :href="'/intern/download/byUUID/' + key">
                <strong>{{ file.file_name }}</strong>
            </a>
            <div>
                <button @click.prevent="deleteFile(file.uuid)">Löschen</button>
            </div>
            <br /><br />
        </div>
    </div>
</template>

<script>
export default {
    props: ["mediafiles"],
    methods: {
        deleteFile(mediafile) {
            var url = "/intern/media/byUUID/" + mediafile;
            axios
                .delete(url)
                .then((response) => {
                    // let itemDiv = document.getElementById("area_" + area.id);
                    // itemDiv.parentNode.removeChild(itemDiv);
                    this.$noty.success("Datei wurde erfolgreich gelöscht!");
                })
                .catch((error) => {
                    console.log(error);
                    this.$noty.error(
                        "Fehler beim Löschen! Bitte noch einmal versuchen."
                    );
                });
        },
    },
};
</script>

<style>
</style>
