<template>
    <div>
        <div v-for="b in orderedBirthdays" :key="b.id" class="mb-3 border-bottom-1">
            <a :href="'/intern/clients/' + b.client.id"><strong>{{ b.title }} {{ b.firstname }} {{ b.lastname }}</strong></a> <span class="text-muted">/ Praxis {{ b.client.praxis }}</span> <br>
            <span class="text-muted"> geboren am {{ b.birthday | formatDate }}, {{ moment(b.birthday).fromNow() }}</span><br>
        </div>
    </div>
</template>

<script>
var moment = require('moment')

export default {
    props: ['birthdays'],
    data() {
        return {
            moment: moment,
        }
    },
    computed: {
        orderedBirthdays: function() {
            return _.orderBy(this.birthdays, 'birthday_order')
        }
    }
}
</script>

<style scoped>
a {
    color: #102B43;
}
</style>

