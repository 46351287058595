<template>
  <div>
    <div v-if="report.length > 0">
      <el-table
        v-loading="loading"
        stripe
        id="report-results"
        :data="report"
        :default-sort="{prop: 'lastname', order: 'ascending'}"
        style="width: 100%"
      >
        <el-table-column label="Name" prop="lastname" sortable width="240px">
          <template slot-scope="scope">
            <a :href="'/intern/users/details/' + scope.row.id" class="text-normal" target="_blank">
              <strong>{{ scope.row.lastname }}, {{ scope.row.name }}</strong>
            </a>
          </template>
        </el-table-column>
        <el-table-column label="MA-Vereinbarung" prop="mitarbeitervereinbarung_ok" sortable>
          <template slot-scope="scope">
            <span class="text-success" v-if="scope.row.mitarbeitervereinbarung_ok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-danger" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Approbation" prop="approbation_ok" sortable>
          <template slot-scope="scope">
            <span class="text-success" v-if="scope.row.approbation_ok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-danger" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Haftpflicht" prop="haftpflicht_ok" sortable>
          <template slot-scope="scope">
            <span class="text-success" v-if="scope.row.haftpflicht_ok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-danger" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Lebenslauf" prop="lebenslauf_ok" sortable>
          <template slot-scope="scope">
            <span class="text-success" v-if="scope.row.lebenslauf_ok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-danger" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template
            slot-scope="scope"
          >{{ (scope.row.lebenslauf_ok + scope.row.approbation_ok + scope.row.haftpflicht_ok + scope.row.mitarbeitervereinbarung_ok) / 4 * 100 }}%</template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="pt-4 pb-4 text-center">
      <img src="/img/undraw_tree.svg" alt="Keine Diente" class="img img-fluid mb-3" width="300px" />
      <br />Keine Dienste gefunden
      <br />
    </div>
  </div>
</template>

<script>
export default {
  props: ["duties"],
  data() {
    return {
      loading: false,
      report: []
    };
  },
  mounted() {
    var url = "/intern/users/report/files";
    loading: true;

    axios
      .get(url, {})
      .then(response => {
        this.report = response.data.data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.$noty.error(
          "Fehler beim Laden des File Reports! Bitte noch einmal versuchen."
        );
      });
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.table-icon {
  width: 18px;
  height: 18px;
  stroke: #102b43;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
  margin-left: 12px;

  &--red {
    stroke: #fe6f5e;
  }
}
</style>