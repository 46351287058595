<template>
    <div
        class="row mt-4"
        v-if="
            ($can('manage billingforms') || isRightDoc) &&
            (duty.status == '30_performed' ||
                duty.status == '40_invoice_received' ||
                duty.status == '50_checked' ||
                duty.status == '60_done')
        "
    >
        <div class="col">
            <h2>Abrechnung</h2>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col mb-1">
                            <div
                                class="alert alert-info"
                                v-if="denyDeleting == 'deny'"
                            >
                                Die Abrechnung wurde eingereicht und kann nicht
                                mehr geändert werden!
                            </div>
                            <div
                                class="alert alert-info"
                                v-if="
                                    duty.status != '30_performed' &&
                                    $can('manage billingforms')
                                "
                            >
                                Die Abrechnung wurde durch den Vertreter
                                eingereicht!
                            </div>
                            Nach Dienstende durch den Vertreter auszufüllen.
                            Diese Daten dienen der internen Organisation und
                            sollen für uns alle die Handhabung vereinfachen.
                            Bitte pflegt sie deshalb gewissenhaft.<br />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 border-right">
                            <h4>Vermittlungsgebühr</h4>
                            <div class="mt-3 pb-2">
                                <div>
                                    <p>
                                        <span
                                            v-if="new Date(duty.start) < new Date('2024-01-01')"
                                            style="
                                                font-size: 34px;
                                                font-weight: 400;
                                            "
                                            >45 €</span
                                        ><span
                                            v-else
                                            style="
                                                font-size: 34px;
                                                font-weight: 400;
                                            "
                                            >65 €</span
                                        ><br />
                                    </p>
                                    <span class="text-muted"
                                        >Praxis Pauschale</span
                                    >
                                </div>
                                <button-management-fee-paid :duty="duty" />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-7 ml-2">
                            <h4>Honorar-Abrechnung - Schritt 1</h4>
                            <div class="mt-3">
                                <sum-field
                                    :duty="duty"
                                    v-on:sumentered="updateBillingSum"
                                    :denyedit="denyDeleting"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="dutysum">
                        <el-divider content-position="left" style="width: 100%"
                            ><span style="color: #102b43"
                                >Abrechnungsbogen hochladen</span
                            ></el-divider
                        >
                        <div class="col">
                            <div>
                                <file-list
                                    :url="
                                        '/intern/duties/' +
                                        duty.id +
                                        '/billing/upload/billingforms'
                                    "
                                    collection="billingforms"
                                    :allowdeleting="denyDeleting"
                                    isbillingform="true"
                                />
                            </div>
                            <div
                                style="width: 100%"
                                v-if="denyDeleting == 'allow'"
                            >
                                <h4>Neue Dateien hochladen</h4>
                                <filepond-upload-form
                                    class="mt-3"
                                    :url="
                                        '/intern/duties/' +
                                        duty.id +
                                        '/billing/upload/billingforms'
                                    "
                                    collection="billingforms"
                                    :csrf="csrf"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="dutysum && denyDeleting == 'allow'">
                        <el-divider content-position="left" style="width: 100%"
                            ><span style="color: #102b43"
                                >Einsenden</span
                            ></el-divider
                        >
                    </div>
                    <div class="row pt-1" v-if="dutysum">
                        <div class="col">
                            <div class="form-group">
                                <label for="billing_comment"
                                    >Möchtest du uns zusätzliche Infos
                                    mitgeben?</label
                                >
                                <textarea
                                    v-model="comment"
                                    maxlength="255"
                                    class="form-control"
                                    id="billing_comment"
                                    name="billing_comment"
                                    rows="2"
                                    :disabled="duty.status != '30_performed'"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button-submit-billing-form
                                :duty="duty"
                                :comment="this.comment"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Alert from "../../../../../vendor/laravel/horizon/resources/js/components/Alert.vue";
import ButtonManagementFeePaid from "./button-management-fee-paid.vue";
import ButtonSubmitBillingForm from "./button-submit-billing-form.vue";
import SumField from "./sum-field.vue";

export default {
    components: {
        ButtonManagementFeePaid,
        SumField,
        ButtonSubmitBillingForm,
    },
    props: ["duty", "csrf"],
    data() {
        return {
            dutysum: null,
            comment: "",
            btndisable: false,
            isRightDoc: false,
            denyDeleting: "allow",
        };
    },
    mounted() {
        this.dutysum = this.duty.billing_sum;
        this.comment = this.duty.billing_comment;
        this.isRightDoc =
            document
                .querySelector("meta[name='user-id']")
                .getAttribute("content") == this.duty.doc_on_duty.id;
        if (this.duty.status != "30_performed") {
            this.denyDeleting = "deny";
            // console.log(this.$can("manage billingforms"));
            if (this.$can("manage billingforms")) {
                this.denyDeleting = "allow";
            }
        }
    },
    methods: {
        updateBillingSum(givensum) {
            this.dutysum = givensum;
        },
        finalizeBilling() {
            this.btndisable = true;
            // check conditions
            // submit
            this.btndisable = false;
        },
    },
};
</script>

<style>
</style>
