<template>
  <div>
    <div v-if="reserves.length > 0">
      <div v-for="reserve in reserves" :key="reserve.id">
        <reserve-intro-compact :reserve="reserve" :shadow="true" :border="true"></reserve-intro-compact>
      </div>
    </div>
    <div v-else class="pt-4 pb-4 text-center">
      <img src="/img/undraw_yoga.svg" alt="Keine Diente" class="img img-fluid mb-3 low-opacity" width="220px"><br>
      Keine passenden Reserve-Dienste gefunden<br>
      <!-- <a href="/intern/duties/open" class="btn btn-primary btn-sm mt-3">Offene Dienste ansehen</a> -->
    </div>
  </div>
</template>

<script>
export default {
    props: ['reserves']
}
</script>

<style>
.low-opacity {
  opacity: 0.4;
}
</style>