var render = function render(){var _vm=this,_c=_vm._self._c;return (
        (_vm.$can('manage billingforms') || _vm.isRightDoc) &&
        (_vm.duty.status == '30_performed' ||
            _vm.duty.status == '40_invoice_received' ||
            _vm.duty.status == '50_checked' ||
            _vm.duty.status == '60_done')
    )?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("Abrechnung")]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-1"},[(_vm.denyDeleting == 'deny')?_c('div',{staticClass:"alert alert-info"},[_vm._v("\n                            Die Abrechnung wurde eingereicht und kann nicht\n                            mehr geändert werden!\n                        ")]):_vm._e(),_vm._v(" "),(
                                _vm.duty.status != '30_performed' &&
                                _vm.$can('manage billingforms')
                            )?_c('div',{staticClass:"alert alert-info"},[_vm._v("\n                            Die Abrechnung wurde durch den Vertreter\n                            eingereicht!\n                        ")]):_vm._e(),_vm._v("\n                        Nach Dienstende durch den Vertreter auszufüllen.\n                        Diese Daten dienen der internen Organisation und\n                        sollen für uns alle die Handhabung vereinfachen.\n                        Bitte pflegt sie deshalb gewissenhaft."),_c('br')])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 border-right"},[_c('h4',[_vm._v("Vermittlungsgebühr")]),_vm._v(" "),_c('div',{staticClass:"mt-3 pb-2"},[_c('div',[_c('p',[(new Date(_vm.duty.start) < new Date('2024-01-01'))?_c('span',{staticStyle:{"font-size":"34px","font-weight":"400"}},[_vm._v("45 €")]):_c('span',{staticStyle:{"font-size":"34px","font-weight":"400"}},[_vm._v("65 €")]),_c('br')]),_vm._v(" "),_c('span',{staticClass:"text-muted"},[_vm._v("Praxis Pauschale")])]),_vm._v(" "),_c('button-management-fee-paid',{attrs:{"duty":_vm.duty}})],1)]),_vm._v(" "),_c('div',{staticClass:"col-sm-12 col-md-7 ml-2"},[_c('h4',[_vm._v("Honorar-Abrechnung - Schritt 1")]),_vm._v(" "),_c('div',{staticClass:"mt-3"},[_c('sum-field',{attrs:{"duty":_vm.duty,"denyedit":_vm.denyDeleting},on:{"sumentered":_vm.updateBillingSum}})],1)])]),_vm._v(" "),(_vm.dutysum)?_c('div',{staticClass:"row"},[_c('el-divider',{staticStyle:{"width":"100%"},attrs:{"content-position":"left"}},[_c('span',{staticStyle:{"color":"#102b43"}},[_vm._v("Abrechnungsbogen hochladen")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('div',[_c('file-list',{attrs:{"url":'/intern/duties/' +
                                    _vm.duty.id +
                                    '/billing/upload/billingforms',"collection":"billingforms","allowdeleting":_vm.denyDeleting,"isbillingform":"true"}})],1),_vm._v(" "),(_vm.denyDeleting == 'allow')?_c('div',{staticStyle:{"width":"100%"}},[_c('h4',[_vm._v("Neue Dateien hochladen")]),_vm._v(" "),_c('filepond-upload-form',{staticClass:"mt-3",attrs:{"url":'/intern/duties/' +
                                    _vm.duty.id +
                                    '/billing/upload/billingforms',"collection":"billingforms","csrf":_vm.csrf}})],1):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.dutysum && _vm.denyDeleting == 'allow')?_c('div',{staticClass:"row"},[_c('el-divider',{staticStyle:{"width":"100%"},attrs:{"content-position":"left"}},[_c('span',{staticStyle:{"color":"#102b43"}},[_vm._v("Einsenden")])])],1):_vm._e(),_vm._v(" "),(_vm.dutysum)?_c('div',{staticClass:"row pt-1"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"billing_comment"}},[_vm._v("Möchtest du uns zusätzliche Infos\n                                mitgeben?")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"form-control",attrs:{"maxlength":"255","id":"billing_comment","name":"billing_comment","rows":"2","disabled":_vm.duty.status != '30_performed'},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}})])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button-submit-billing-form',{attrs:{"duty":_vm.duty,"comment":this.comment}})],1)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }