<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h2 class="fw-700 mb-0">{{ person.fullname }}</h2>
                        <small class="text-muted">{{
                            person.speciality
                        }}</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-print-none">
            <div class="col">
                <div class="card mt-4 mb-4">
                    <div
                        class="card-body quick-action"
                        onclick="window.print()"
                    >
                        <printer-icon class="quick-action--icon"></printer-icon>
                        <br />Drucken
                    </div>
                </div>
            </div>
            <!-- <div class="col">
                        <div class="card  mt-4">
                            <div class="card-body quick-action">
                                <log-out-icon class="quick-action--icon"></log-out-icon><br>
                                VCard
                            </div>
                        </div>
                  </div>-->
            <div class="col" v-if="$can('edit users')">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        @click="redirectToEditPage(person.id)"
                    >
                        <edit-2-icon class="quick-action--icon"></edit-2-icon>
                        <br />
                        <!-- <a :href="'/intern/users/edit/' + person.id"> -->
                        Bearbeiten
                        <!-- </a> -->
                    </div>
                </div>
            </div>
            <div class="col" v-if="$can('edit users')">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        @click="dialogFormVisible = true"
                    >
                        <message-square-icon
                            class="quick-action--icon"
                        ></message-square-icon>
                        <br />SMS senden
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2">
            <div class="col mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">Adresse</h4>
                        <address>
                            {{ person.fullname }}
                            <br />
                            {{ person.street }}
                            <br />
                            {{ person.zipcode }} {{ person.city }}
                        </address>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">Kontaktdaten</h4>
                        <table class="table table-borderless">
                            <tr>
                                <td width="32px" class="text-left">
                                    <send-icon class="table-icon"></send-icon>
                                </td>
                                <td>
                                    <a :href="'mailto:' + person.email">{{
                                        person.email
                                    }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td width="32px" class="text-left">
                                    <smartphone-icon
                                        class="table-icon"
                                    ></smartphone-icon>
                                </td>
                                <td>
                                    <a :href="'tel:' + person.phone_mobile">{{
                                        person.phone_mobile
                                    }}</a>
                                </td>
                            </tr>
                            <tr v-if="person.phone">
                                <td width="32px" class="text-left">
                                    <phone-icon class="table-icon"></phone-icon>
                                </td>
                                <td>
                                    <a :href="'tel:' + person.phone">{{
                                        person.phone
                                    }}</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row row-cols-1 row-cols-md-2">
                            <div class="col">
                                <h4 class="mb-3">Zur Person</h4>
                                <p>
                                    <star-icon class="muted-icon"></star-icon>
                                    Geboren am
                                    {{ person.birthday | moment("DD.MM.Y") }}
                                </p>
                                <p v-if="$can('edit users')">
                                    <user-plus-icon
                                        class="muted-icon"
                                    ></user-plus-icon>
                                    Angelegt seit
                                    {{ person.created_at | moment("DD.MM.Y") }}
                                    <span
                                        class="badge badge-info ml-2"
                                        v-if="person.is_after_bdonline"
                                        >Nach BD Online</span
                                    >
                                </p>
                                <p v-if="$can('edit users')">
                                    <lock-icon class="muted-icon"></lock-icon
                                    >Berechtigung als
                                    <strong>{{
                                        person.isMemberOfCoreteam == true
                                            ? "Normales Teammitglied"
                                            : "Kernteam Mitglied"
                                    }}</strong>
                                </p>
                                <p v-if="$can('edit users')">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="muted-icon"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                                        />
                                    </svg>

                                    Provision:
                                    {{ person.personalCommission }}%
                                </p>
                            </div>
                            <div
                                v-if="
                                    person.notes ||
                                    (person.internal_notes &&
                                        $can('edit users'))
                                "
                                class="col"
                            >
                                <h4 class="mb-3">Notizen</h4>
                                <p
                                    style="
                                        white-space: pre;
                                        background-color: #efefef;
                                        border-radius: 6px;
                                    "
                                    class="p-3"
                                    v-if="
                                        person.internal_notes &&
                                        $can('edit users')
                                    "
                                >
                                    <svg
                                        class="icon ml-0"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                        ></path></svg
                                    ><br />{{ person.internal_notes }}
                                </p>
                                <p style="white-space: pre">
                                    {{ person.notes }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="$can('edit users')">
            <div class="col-md-8 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <calendar-icon class="primary-icon"></calendar-icon
                            >Anstehende Dienste
                        </h4>
                        <duty-list-compact
                            :duties="duties"
                            v-if="duties.length > 0"
                        ></duty-list-compact>
                        <p class="text-center" v-else>
                            <img
                                src="/img/undraw_events.svg"
                                class="img img-fluid"
                                width="300px"
                                alt
                            />
                            <br />Hier erscheinen die Dienste, wenn vorhanden
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <calendar-icon class="primary-icon"></calendar-icon
                            >Anstehende Reserven
                        </h4>
                        <reserve-list-compact
                            :reserves="reserves"
                            v-if="reserves.length"
                        ></reserve-list-compact>
                        <p class="text-center" v-else>
                            <img
                                src="/img/undraw_events.svg"
                                class="img img-fluid"
                                width="300px"
                                alt
                            />
                            <br />Hier erscheinen die Dienste, wenn vorhanden
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- History of Duties -->
        <div class="row mt-4" v-if="$can('edit users')">
            <div class="col-md-8 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <skip-back-icon
                                class="primary-icon"
                            ></skip-back-icon
                            >Dienste letzte 13 Monate
                        </h4>
                        <duty-list-compact
                            :duties="dutiesPast"
                            v-if="dutiesPast.length > 0"
                        ></duty-list-compact>
                        <p class="text-center" v-else>
                            <img
                                src="/img/undraw_events.svg"
                                class="img img-fluid"
                                width="300px"
                                alt
                            />
                            <br />Hier erscheinen die Dienste, wenn vorhanden
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <skip-back-icon
                                class="primary-icon"
                            ></skip-back-icon
                            >Reserven letzte 13 Monate
                        </h4>
                        <reserve-list-compact
                            :reserves="reservesPast"
                            v-if="reservesPast.length"
                        ></reserve-list-compact>
                        <p class="text-center" v-else>
                            <img
                                src="/img/undraw_events.svg"
                                class="img img-fluid"
                                width="300px"
                                alt
                            />
                            <br />Hier erscheinen die Dienste, wenn vorhanden
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="$can('edit users')">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-4">
                            <check-square-icon
                                class="primary-icon"
                            ></check-square-icon
                            >Persönliche Nachweise
                        </h4>
                        <!-- <el-progress :percentage="50"></el-progress> -->
                        <br />
                        <template>
                            <el-tabs v-model="activeTabName">
                                <el-tab-pane
                                    label="Mitarbeitervereinbarung"
                                    name="tab_first"
                                >
                                    <file-list
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Mitarbeitervereinbarung'
                                        "
                                        collection="Mitarbeitervereinbarung"
                                    />
                                    <h4>Neue Dateien hochladen</h4>
                                    <filepond-upload-form
                                        class="mt-3"
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Mitarbeitervereinbarung'
                                        "
                                        collection="Mitarbeitervereinbarung"
                                        :csrf="csrf"
                                    />
                                </el-tab-pane>
                                <el-tab-pane
                                    label="Approbationsurkunde"
                                    name="tab_second"
                                >
                                    <file-list
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Approbationsurkunde'
                                        "
                                        collection="Approbationsurkunde"
                                    />
                                    <h4>Neue Dateien hochladen</h4>
                                    <filepond-upload-form
                                        class="mt-3"
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Approbationsurkunde'
                                        "
                                        collection="Approbationsurkunde"
                                        :csrf="csrf"
                                    />
                                </el-tab-pane>
                                <el-tab-pane
                                    label="Haftpflicht"
                                    name="tab_third"
                                >
                                    <file-list
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Haftpflicht'
                                        "
                                        collection="Haftpflicht"
                                    />
                                    <h4>Neue Dateien hochladen</h4>
                                    <filepond-upload-form
                                        class="mt-3"
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Haftpflicht'
                                        "
                                        collection="Haftpflicht"
                                        :csrf="csrf"
                                    />
                                </el-tab-pane>
                                <el-tab-pane
                                    label="Lebenslauf"
                                    name="tab_fourth"
                                >
                                    <file-list
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Lebenslauf'
                                        "
                                        collection="Lebenslauf"
                                    />
                                    <h4>Neue Dateien hochladen</h4>
                                    <filepond-upload-form
                                        class="mt-3"
                                        :url="
                                            '/intern/users/' +
                                            person.id +
                                            '/upload/Lebenslauf'
                                        "
                                        collection="Lebenslauf"
                                        :csrf="csrf"
                                    />
                                </el-tab-pane>
                            </el-tabs>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="person.sms && $can('edit users')">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-5">
                            <message-square-icon
                                class="primary-icon"
                            ></message-square-icon
                            >SMS
                        </h4>
                        <el-timeline :reverse="reverseSmsOrder">
                            <el-timeline-item
                                v-for="msg in person.sms"
                                :key="msg.id"
                                size="large"
                                :hide-timestamp="true"
                                :timestamp="msg.created_at"
                            >
                                <p
                                    v-if="msg.from == 'out'"
                                    class="font-italic text-muted"
                                >
                                    Ausgehende SMS:
                                </p>
                                {{ msg.message }}
                                <br />
                                <span class="text-muted">
                                    <small>{{
                                        msg.created_at | moment("from")
                                    }}</small>
                                </span>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>

        <!-- TODO: Remove this and place sms-send-form component !-->
        <el-dialog
            :title="'SMS an ' + person.fullname"
            :visible.sync="dialogFormVisible"
        >
            <form @submit.prevent="submit">
                <div class="row mb-4">
                    <div class="col text-center">
                        <textarea
                            name="smstext"
                            id="smstext"
                            rows="6"
                            class="form-control"
                            :class="
                                errors && errors.smstext ? 'form-error' : ''
                            "
                            placeholder="Dein SMS Text"
                            v-model="fields.smstext"
                        ></textarea>
                        <form-error
                            v-if="errors && errors.smstext"
                            :errortext="errors.smstext[0]"
                        ></form-error>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <button class="btn btn-primary btn-block" type="submit">
                            SMS senden
                        </button>
                    </div>
                </div>
            </form>
        </el-dialog>
    </div>
</template>

<script>
import {
    PercentIcon,
    CheckSquareIcon,
    LogOutIcon,
    SmartphoneIcon,
    CalendarIcon,
    PhoneIcon,
    StarIcon,
    UserPlusIcon,
    SendIcon,
    LockIcon,
    PrinterIcon,
    InfoIcon,
    Edit2Icon,
    Trash2Icon,
    MessageSquareIcon,
    SkipBackIcon,
} from "vue-feather-icons";
import FormMixin from "../FormMixin";

export default {
    mixins: [FormMixin],

    components: {
        CheckSquareIcon,
        LogOutIcon,
        SmartphoneIcon,
        PhoneIcon,
        StarIcon,
        UserPlusIcon,
        LockIcon,
        PrinterIcon,
        CalendarIcon,
        InfoIcon,
        SendIcon,
        Edit2Icon,
        Trash2Icon,
        MessageSquareIcon,
        SkipBackIcon,
    },
    props: [
        "person",
        "duties",
        "reserves",
        "dutiesPast",
        "reservesPast",
        "csrf",
    ],
    data() {
        return {
            loading: false,
            loadingfile: true,
            activeTabName: "tab_first",
            listOfObjects: [],
            search: "",
            dialogFormVisible: false,
            action: "/intern/sms/send/" + this.person.id,
            fields: {
                smstext: "",
            },
            reverseSmsOrder: true,
        };
    },
    computed: {
        // getTableContent() {
        //     var listOfObjectsResult = this.listOfObjects.filter((listOfObjects) => {
        //         return listOfObjects.fullname.toLowerCase().includes(this.search.toLowerCase());
        //     });
        //     return listOfObjectsResult;
        // },
    },
    mounted() {
        this.listOfObjects = Object.keys(this.person).map((key) => {
            return this.person[key];
        });
    },
    methods: {
        handleResponseResult(response) {
            this.fields.smstext = "";
            this.dialogFormVisible = false;
            this.$noty.success("SMS verschickt!");
            // location.reload()
        },
        redirectToEditPage(personId) {
            window.location.href = "/intern/users/edit/" + personId;
        },
    },
};
</script>


<style scoped lang="scss">
.table th,
.table td {
    padding: 0.5rem;
}

.quick-action {
    text-align: center;
    color: #6c757d;
    text-transform: uppercase;
    font-size: 14px;
    &:hover {
        cursor: pointer;
    }
}

.quick-action--icon {
    width: 24px;
    height: 24px;
    stroke: #6c757d;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-bottom: 12px;
}

.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer; // margin-left: 12px;
    &--red {
        stroke: #fe6f5e;
    }
}

.muted-icon {
    width: 14px;
    height: 14px;
    stroke: #6c757d;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;
    &--big {
        width: 16px;
        height: 16px;
    }
}

.primary-icon {
    width: 16px;
    height: 16px;
    stroke: #ff6e21;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;
}
</style>
