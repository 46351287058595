<template>
  <div>
    <h4 class="mb-3">Verlauf Vertreter</h4>
    <div v-loading="loading" class="pb-3">
      <div v-if="history.length > 0">
        <table class="table table-borderless fs12 mb-0 pb-0">
          <tr v-for="e in history" :key="e.id">
            <td class="pb-2">
              <template v-if="e.old_doc_name == ''" class="font-italic">offen</template>
              <template v-else>{{ e.old_doc_name }}</template>
              <br />
              <span class="text-muted">{{ e.created_at | moment('D.MM.Y hh:mm') }} Uhr</span>
            </td>
            <td>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                class="align-baseline"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L13.7071 14.7071C13.3166 15.0976 12.6834 15.0976 12.2929 14.7071C11.9024 14.3166 11.9024 13.6834 12.2929 13.2929L14.5858 11H3C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289Z"
                  fill="#9FB3C8"
                />
              </svg>
            </td>
            <td>
              <template v-if="e.new_doc_name == ''" class="font-italic">offen</template>
              <template v-else>{{ e.new_doc_name }}</template>
              <br />
              <span class="text-muted">{{ e.reason }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="font-italic">Es gibt noch Nichts zu berichten...</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["duty"],
  data() {
    return {
      history: [],
      loading: true
    };
  },
  created() {
    axios
      .get("/intern/duties/" + this.duty + "/history")
      .then(response => {
        this.history = response.data;
      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  }
};
</script>

<style>
</style>