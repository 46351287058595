<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body d-flex justify-content-between">
                        <div>

                        <h2 class="fw-700 mb-0">{{ client.praxis }}</h2>
                        <small class="text-muted"
                            >{{ client.city }} (Gebiet
                            {{ client.area.title }})</small
                        ><br />
                        <span
                            class="badge badge-secondary"
                            v-if="client.speciality"
                            >{{ client.speciality }}</span
                        >
                        <span
                            class="badge badge-success"
                            v-if="client.status == 'aktiv'"
                            >Aktive Praxis</span
                        >
                        <span
                            class="badge badge-danger"
                            v-if="client.status == 'inaktiv'"
                            >Inaktive Praxis</span
                        >
                        <span
                            class="badge badge-info"
                            v-if="client.is_after_bdonline == 1"
                            >Nach BD Online Praxis</span
                        >
                        </div>
                        <div class="col text-right">
                            <h5 class="card-title text-uppercase text-muted mb-0">DIENSTE GESAMT</h5>
                            <div class="h2 font-weight-bold">{{ totalduties }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-print-none">
            <div class="col">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        onclick="window.print()"
                    >
                        <printer-icon class="quick-action--icon"></printer-icon
                        ><br />
                        Drucken
                    </div>
                </div>
            </div>
            <!-- <div class="col">
            <div class="card  mt-4">
                <div class="card-body quick-action">
                    <log-out-icon class="quick-action--icon"></log-out-icon><br>
                    VCard
                </div>
            </div>
        </div> -->
            <div class="col" v-if="$can('edit clients')">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        @click="redirectToEditPage(client.id)"
                    >
                        <edit-2-icon class="quick-action--icon"></edit-2-icon
                        ><br />
                        <!-- <a :href="'/intern/users/edit/' + person.id"> -->
                        Bearbeiten
                        <!-- </a> -->
                    </div>
                </div>
            </div>
            <div class="col" v-if="$can('edit clients')">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        @click="deleteClient(client.id)"
                    >
                        <trash-2-icon class="quick-action--icon"></trash-2-icon
                        ><br />
                        Löschen
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mt-4">
                    <div
                        class="card-body quick-action"
                        @click="startRouteFinder"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-map quick-action--icon"
                        >
                            <polygon
                                points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"
                            ></polygon>
                            <line x1="8" y1="2" x2="8" y2="18"></line>
                            <line x1="16" y1="6" x2="16" y2="22"></line>
                        </svg>
                        <br />
                        Route berechnen
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 print-none" v-if="client.lat">
            <div class="col">
                <div class="card">
                    <div class="card-body p-0">
                        <GmapMap
                            :center="mapCenter"
                            :zoom="10"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: true,
                                disableDefaultUi: false,
                            }"
                            :streetViewControl="false"
                            map-type-id="roadmap"
                            style="width: 100%; height: 300px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="false"
                                :title="client.praxis"
                                @click="mapCenter = m.position"
                            />
                        </GmapMap>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">Praxis-Adresse</h4>
                        <address>
                            {{ client.praxis }}<br />
                            {{ client.street }}<br />
                            {{ client.zip }} {{ client.city }}
                        </address>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">Kontaktdaten</h4>
                        <table
                            class="table table-borderless"
                            v-if="
                                client.phone ||
                                client.phone_mobile ||
                                client.fax ||
                                client.email
                            "
                        >
                            <tr v-if="client.registration_path">
                                <td width="32px" class="text-left">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-radio table-icon"
                                    >
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path
                                            d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"
                                        ></path>
                                    </svg>
                                </td>
                                <td class="facts">
                                    <strong
                                        >Anmelden über
                                        {{ client.registration_path }}</strong
                                    >
                                </td>
                            </tr>
                            <tr v-if="client.email">
                                <td width="32px" class="text-left">
                                    <send-icon class="table-icon"></send-icon>
                                </td>
                                <td>
                                    <a :href="'mailto:' + client.email">{{
                                        client.email
                                    }}</a>
                                </td>
                            </tr>
                            <tr v-if="client.phone_mobile">
                                <td width="32px" class="text-left">
                                    <smartphone-icon
                                        class="table-icon"
                                    ></smartphone-icon>
                                </td>
                                <td>
                                    <a :href="'tel:' + client.phone_mobile">{{
                                        client.phone_mobile
                                    }}</a>
                                </td>
                            </tr>
                            <tr v-if="client.phone">
                                <td width="32px" class="text-left">
                                    <phone-icon class="table-icon"></phone-icon>
                                </td>
                                <td>
                                    <a :href="'tel:' + client.phone">{{
                                        client.phone
                                    }}</a>
                                </td>
                            </tr>
                            <tr v-if="client.fax">
                                <td width="32px" class="text-left">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-file-text table-icon"
                                    >
                                        <path
                                            d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                                        ></path>
                                        <polyline
                                            points="14 2 14 8 20 8"
                                        ></polyline>
                                        <line
                                            x1="16"
                                            y1="13"
                                            x2="8"
                                            y2="13"
                                        ></line>
                                        <line
                                            x1="16"
                                            y1="17"
                                            x2="8"
                                            y2="17"
                                        ></line>
                                        <polyline
                                            points="10 9 9 9 8 9"
                                        ></polyline>
                                    </svg>
                                </td>
                                <td>
                                    <a :href="'tel:' + client.fax">{{
                                        client.fax
                                    }}</a>
                                </td>
                            </tr>
                        </table>
                        <div v-else>
                            <span class="text-danger font-italic"
                                >Es wurden keine Kontaktdaten hinterlegt.</span
                            ><br />
                            <a
                                :href="'/intern/clients/edit/' + client.id"
                                class="btn btn-info btn-sm mt-3"
                            >
                                Jetzt bearbeiten
                            </a>
                        </div>
                        <div>
                            <contactlist-management-form
                                v-if="$can('edit clients') && client.email"
                                :listname="client.area.contactlist_match"
                                :listid="client.area.contactlist"
                                recipienttype="client"
                                :recipientid="client.id"
                                :subscribedto="client.subscribed"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row mt-3">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h4 class="mb-3">Infos zur Person</h4>
                    <p><star-icon class="muted-icon"></star-icon>Geboren am {{ person.birthday | moment("DD.MM.Y") }}</p>
                    <p><user-plus-icon class="muted-icon"></user-plus-icon>Angelegt seit {{ person.created_at | moment("DD.MM.Y") }}</p>
                    <p><lock-icon class="muted-icon"></lock-icon>Berechtigung als <strong>{{ person.isMemberOfCoreteam == true ? 'Normales Teammitglied' : 'Kernteam Mitglied' }}</strong></p>
                </div>
            </div>
        </div>
    </div> -->

        <div class="row mt-4">
            <div class="col">
                <div class="card">
                    <div class="card-body pb-0">
                        <h4 class="mb-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-users primary-icon"
                            >
                                <path
                                    d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                                ></path>
                                <circle cx="9" cy="7" r="4"></circle>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg
                            >Ärzte & Helferinnen
                        </h4>
                        <p class="text-center">
                            <people-cards
                                v-if="client.people"
                                :people="client.people"
                                :client_id="client.id"
                                :listname="client.area.contactlist_match"
                                :listid="client.area.contactlist"
                            ></people-cards>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <h4 class="mb-3">
                                <calendar-icon
                                    class="primary-icon"
                                ></calendar-icon
                                >Anstehende Dienste
                            </h4>
                            <!-- <a
                                class="btn btn-sm btn-outline-primary"
                                @click="
                                    dialogHistoryVisible = !dialogHistoryVisible
                                "
                                >Historie</a
                            > -->
                        </div>
                        <div v-if="duties.length > 0">
                            <div
                                v-for="e in duties"
                                :key="e.id"
                                :ref="'duty-' + e.id"
                                :id="'duty-' + e.id"
                            >
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <duty-intro-compact-ultra
                                            :duty="e"
                                            :enable-hiding="false"
                                            :hide-status="false"
                                        ></duty-intro-compact-ultra>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-center" v-else>
                            <img
                                src="/img/undraw_events.svg"
                                class="img img-fluid"
                                width="300px"
                                alt=""
                            />
                            <br />Hier erscheinen die Dienste, wenn vorhanden
                        </p>
                    </div>
                </div>
            </div>
        </div>

    <div class="row mt-4" v-if="$can('edit clients')">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <calendar-icon
                                    class="primary-icon"
                                ></calendar-icon
                                >Vergangene Dienste (1 Jahr)
                        </h4>
        <client-duty-history :clientid="client.id" />
                    </div>
                </div>
            </div>
    </div>

        <div class="row mt-4">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h4 class="mb-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clipboard primary-icon"
                            >
                                <path
                                    d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
                                ></path>
                                <rect
                                    x="8"
                                    y="2"
                                    width="8"
                                    height="4"
                                    rx="1"
                                    ry="1"
                                ></rect>
                            </svg>
                            Rechnungsadresse / Notizen
                        </h4>
                        <div v-if="client.invoicing_address">
                            <h5>Rechnungen an</h5>
                            <div class="multilinefield">
                                {{ client.invoicing_address }}
                            </div>
                        </div>

                        <div v-if="client.notes" class="mt-3">
                            <h5>Notizen</h5>
                            <div class="multilinefield">{{ client.notes }}</div>
                        </div>
                        <div
                            class="mt-3 text-muted"
                            v-if="!client.invoicing_address && !client.notes"
                        >
                            Keine Notizen oder Informationen vorhanden
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            title="Dienste Historie"
            :visible.sync="dialogHistoryVisible"
        >
            <client-duty-history :clientid="client.id" />
        </el-dialog>

        <el-dialog
            :title="'SMS an ' + client.praxis"
            :visible.sync="dialogFormVisible"
        >
            <form @submit.prevent="submit">
                <div class="row mb-4">
                    <div class="col text-center">
                        <textarea
                            name="smstext"
                            id="smstext"
                            rows="6"
                            class="form-control"
                            :class="
                                errors && errors.smstext ? 'form-error' : ''
                            "
                            placeholder="Dein SMS Text"
                            v-model="fields.smstext"
                        >
                        </textarea>
                        <form-error
                            v-if="errors && errors.smstext"
                            :errortext="errors.smstext[0]"
                        ></form-error>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <button class="btn btn-primary btn-block" type="submit">
                            SMS senden
                        </button>
                    </div>
                </div>
            </form>
        </el-dialog>
    </div>
</template>

<script>
import {
    LogOutIcon,
    SmartphoneIcon,
    CalendarIcon,
    PhoneIcon,
    StarIcon,
    UserPlusIcon,
    SendIcon,
    LockIcon,
    PrinterIcon,
    InfoIcon,
    Edit2Icon,
    Trash2Icon,
    MessageSquareIcon,
} from "vue-feather-icons";
import FormMixin from "../../../FormMixin";
import ClientDutyHistory from "./ClientDutyHistory.vue";

export default {
    mixins: [FormMixin],

    components: {
        LogOutIcon,
        SmartphoneIcon,
        PhoneIcon,
        StarIcon,
        UserPlusIcon,
        LockIcon,
        PrinterIcon,
        CalendarIcon,
        InfoIcon,
        SendIcon,
        Edit2Icon,
        Trash2Icon,
        MessageSquareIcon,
        ClientDutyHistory,
    },
    props: ["client", "duties", "totalduties"],
    data() {
        return {
            loading: false,
            dialogFormVisible: false,
            dialogHistoryVisible: false,
            action: "/intern/sms/send/" + this.client.id,
            fields: {
                smstext: "",
            },
            reverseSmsOrder: true,
            mapCenter: {
                lat: parseFloat(this.client.lat),
                lng: parseFloat(this.client.lng),
            },
            markers: [
                {
                    position: {
                        lat: parseFloat(this.client.lat),
                        lng: parseFloat(this.client.lng),
                    },
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {
        handleResponseResult(response) {
            this.fields.smstext = "";
            this.dialogFormVisible = false;
            this.$noty.success("SMS verschickt!");
            // location.reload()
        },
        redirectToEditPage(clientId) {
            window.location.href = "/intern/clients/edit/" + clientId;
        },
        startRouteFinder() {
            var url =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                encodeURIComponent(
                    this.client.street +
                        "," +
                        this.client.zip +
                        "," +
                        this.client.city
                );
            var win = window.open(url, "_blank");
            win.focus();
        },
        async deleteClient(clientId) {
            try {
                // First check if client has any duties
                const response = await fetch("/intern/clients/checkForDuties/" + clientId);
                const hasDuties = await response.json();

                if (hasDuties) {
                    this.$noty.error("Praxis kann nicht gelöscht werden, da noch Dienste zugewiesen sind.");
                    return;
                }

                // If no duties, ask for confirmation
                this.$confirm(
                    "Diese Aktion wird die Praxis dauerhaft löschen. Fortfahren?",
                    "Warnung",
                    {
                        confirmButtonText: "Ja, löschen",
                        cancelButtonText: "Abbrechen",
                        type: "warning",
                    }
                ).then(() => {
                    // Delete the client
                    window.location.href = "/intern/clients/" + clientId + "/delete";
                }).catch(() => {
                    this.$noty.info("Löschen abgebrochen!");
                });
            } catch (error) {
                console.error("Fehler bei der Prüfung auf existierende Dienste!", error);
                this.$noty.error("Fehler bei der Prüfung auf existierende Dienste!");
            }
        }
    },
};
</script>


<style scoped lang="scss">
.multilinefield {
    white-space: pre;
    word-wrap: break-word;
}

h5 {
    color: #9fb3c8;
}

.table th,
.table td {
    padding: 0.2rem;
}

.table a,
.facts {
    text-decoration: none;
    color: #555555;
    font-size: 14px;
}

.quick-action {
    text-align: center;
    color: #6c757d;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #ff6e21;
    }
}

.quick-action--icon {
    width: 20px;
    height: 20px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover {
        stroke: #fff;
    }
}

.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    // margin-left: 12px;

    &--red {
        stroke: #fe6f5e;
    }
}

.muted-icon {
    width: 14px;
    height: 14px;
    stroke: #6c757d;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;

    &--big {
        width: 16px;
        height: 16px;
    }
}

.primary-icon {
    width: 16px;
    height: 16px;
    stroke: #ff6e21;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-right: 8px;
    margin-top: -4px;
}
</style>
