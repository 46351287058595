export default {
    data() {
        return {
            errors: {},
            success: false,
            loaded: true,
            resultset: {},
        }
    },

    methods: {
        submit() {
            this.loading = true
            if (this.loaded) {
                this.loaded = false;
                this.success = false;
                this.errors = {};
                axios.post(this.action, this.fields, { timeout: 6000 }).then(response => {
                    this.loaded = true
                    this.success = true
                    this.handleResponseResult(response.data)
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                    }
                    this.$noty.error("Änderung fehlerhaft! Bitte noch einmal versuchen.");
                });
            }
            this.loading = false;
        },

    },
}