<template>
    <div>
        <form @submit.prevent="submit" v-if="!confirmationdate && !duty.management_fee_paid">
            <button class="btn btn-primary">Rechnung abgegeben</button>
            <!-- <form-error v-if="errors" :errortext="errors"></form-error> -->
        </form>
        <p v-else>
            <small><check-icon size="1.2x" class="custom-class text-icon"></check-icon> Am {{ confirmationdate | moment("DD.MM.Y") }} als abgegeben markiert<span v-if="!duty.management_fee_paid">. Fehler? <a href="#" @click.prevent="submit()">Rückgängig machen.</a></span>
            <span v-if="duty.management_fee_paid"><br><check-icon size="1.2x" class="custom-class text-icon"></check-icon> Bezahlt markiert</span>
            </small>
        </p>
    </div>
</template>

<script>
import {
    CheckIcon
} from 'vue-feather-icons'
import FormMixin from "../../../FormMixin";

export default {
    mixins: [FormMixin],
    components: {
        CheckIcon,
    },
    props: [
        "duty"
    ],
    data() {
        return {
            action: "/intern/duties/" + this.duty.id + "/billing/managementFee",
            confirmationdate: null,
            fields: {}
        }
    },
    mounted() {
        this.confirmationdate = this.duty.management_fee_handover;
    },
    methods: {
        handleResponseResult(response) {
            this.$noty.success("Daten gepseichert!");
            this.confirmationdate = response.managementFeeHandover
            // location.reload()
        },
    }
}
</script>
