<template>
    <div>
        <form @submit.prevent ref="updateForm">
            <div class="form-row mb-3">
                <div class="col">
                    <el-select v-model="updateReserveObject.doc_on_duty_id" v-loading="loading" filterable remote focus clearable ref="doc" reserve-keyword placeholder="Arzt (Vertreter)" :remote-method="findDocOnDuty" :loading="loading">
                        <!-- <template v-if="this.options.length > 0"> -->
                        <el-option v-for="item in options" :key="item.id" :label="item.lastname + ', ' + item.name" :value="item.id"></el-option>
                        <!--
</template>-->
                </el-select>
                <form-error
                v-if="errors && errors.doc_on_duty_id"
                :errortext="errors.doc_on_duty_id[0]"
                ></form-error>
            </div>
            </div>
            <div
            class="form-row mb-3"
            v-if="updateReserveObject.status == '10_open' || updateReserveObject.status == '15_prebooked'"
            >
            <div class="col">
                <el-switch v-model="updateReserveObject.prebooking" active-text="Nur vormerken"></el-switch>
            </div>
            </div>
            <div class="form-row mb-3">
            <div class="col">
                <el-date-picker
                v-model="updateReserveObject.start"
                type="date"
                :picker-options="{
                                firstDayOfWeek: 1
                            }"
                format="dd.MM.yyyy"
                start-placeholder="Start"
                class="w-100"
                ></el-date-picker>
                <form-error v-if="errors && errors.start" :errortext="errors.start[0]"></form-error>
            </div>
            </div>

            <div class="form-row mb-3">
            <div class="col text-center">
                <input
                type="text"
                class="form-control"
                :class="errors && errors.notes ? 'form-error' : ''"
                id="notes"
                placeholder="Notiz zum Dienst / Bonus"
                v-model="updateReserveObject.notes"
                />
                <form-error v-if="errors && errors.notes" :errortext="errors.notes[0]"></form-error>
            </div>
            </div>

            <div class="row mt-3">
            <div class="col">
                <button
                class="btn btn-primary"
                :disabled="loading"
                @click="updateFormSubmit"
                >Reserve speichern</button>
                <button class="btn btn-default" @click="returnBack">Abbrechen</button>
            </div>
            </div>
        </form>
    </div>
</template>

<script>
import FormMixin from "../../../FormMixin";

export default {
    mixins: [FormMixin],
    props: ["reserve"],
    data() {
        return {
            loading: false,
            options: [],
            updateReserveObject: {
                id: "",
                doc_on_duty_id: "",
                doc_on_duty: {
                    id: "",
                    name: "",
                    lastname: ""
                },
                start: "",
                notes: "",
                prebooking: false
            }
        };
    },
    computed: {
        action() {
            return "/intern/reserves/" + this.updateReserveObject.id;
        },
        fields() {
            return this.updateReserveObject;
        }
    },
    mounted() {
        this.updateReserveObject = this.reserve;
        setTimeout(() => {
            axios.get("/intern/reserves/getAllDocs").then(response => {
                this.options = response.data;
            });
        }, 1000);
    },
    methods: {
        returnBack() {
            window.history.back()
        },
        findDocOnDuty(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;

                    if (query.length > 2) {
                        axios
                            .get("/intern/reserves/searchUser", { params: { query: query } })
                            .then(response => {
                                this.options = response.data;
                            });
                    }
                }, 200);
            } else {
                // this.options = [];
                this.updateOptions(updateReserveObject.doc_on_duty_id);
            }
        },
        updateOptions() {
            // function could be refactored or replaced
            this.loading = true;
            setTimeout(() => {
                axios
                    .get("/intern/reserves/getDocById", {
                        params: { query: this.updateReserveObject.doc_on_duty_id }
                    })
                    .then(response => {
                        this.options = response.data;
                    });
                console.log("Updated Options");
            }, 300);
            this.loading = false;
        },
        updateFormSubmit() {
            this.submit();
        },
        handleResponseResult(response) {
            this.$noty.success("Reserve geändert!");
            setTimeout(function() {
                window.history.back()
            }, 700);
        }
    }
};
</script>

<style scoped lang="scss">
.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102b43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;
    &--red {
        stroke: #fe6f5e;
    }
}

.el-select {
    display: input;
    width: 100%;
}
</style>
