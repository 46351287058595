<template>
  <div
    class="form-row pl-2 pb-3 pt-2 bg-white"
    v-bind:class="{'shadow-md' : shadow == 1, 'mb-5' : border == 1}"
  >
    <div class="col">
      <div class="form-row mb-2">
        <div class="col-md-5 col-sm-12" style="max-width: 190px;">
          <small>{{ reserve.start | moment("dddd") }}</small>
          <br />
          <strong>{{ reserve.start | moment("DD.MM.YYYY") }}</strong>
          <br />
          <small class="rockblue">START</small>
        </div>
      </div>

      <div class="form-row pl-1 mb-2 text-sm" v-if="reserve.notes || reserve.doc_on_duty">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" v-if="reserve.doc_on_duty">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user mr-2 text-icon align-self-center"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
            <circle cx="12" cy="7" r="4" />
          </svg>
          {{ reserve.doc_on_duty.fullnameReverse }}
          <br />
        </div>
        <div class="col" v-if="reserve.notes">
          <p class>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-info mr-2 rockblue text-icon align-self-center"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="16" x2="12" y2="12" />
              <line x1="12" y1="8" x2="12" y2="8" />
            </svg>
            {{ reserve.notes }}
          </p>
        </div>
      </div>

      <span class="badge badge-secondary float-left mr-3">Reserve</span>
      <span
        class="badge badge-warning float-left mr-3"
        v-if="reserve.status == '10_open' || reserve.status == '15_prebooked'"
      >{{ reserve.status_label }}</span>
      <span class="badge badge-success float-left mr-3" v-else>{{ reserve.status_label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reserve", "shadow", "border"]
};
</script>

<style>
.bg-white {
  background-color: #fff;
}
</style>