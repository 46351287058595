<template>
  <div>
    <div v-if="duties.length > 0">
      <!-- <div v-for="duty in duties" :key="duty.id"> -->
          <el-table
            v-loading="loading"
            stripe
            id="report-results"
            :data="duties"
            :default-sort = "{prop: 'start', order: 'ascending'}"
            :row-class-name="tableRowClassName"
            style="width: 100%">
            <el-table-column
            label="Datum"
            prop="start"
            sortable
            width="140px">
              <template slot-scope="scope">
                  {{ scope.row.start | moment('DD.MM.YY - ddd') }}
              </template>
            </el-table-column>
            <el-table-column
            label="Gebiet"
            prop="area_short"
            sortable
            width="120px">
              <template slot-scope="scope">
                  {{ scope.row.area_short }}
                  <template v-if="scope.row.suffix">{{ scope.row.suffix }}</template>
              </template>
            </el-table-column>
            <el-table-column
            label="Dienst"
            prop="type"
            sortable
            width="170px">
              <template slot-scope="scope">
                  <template v-if="scope.row.type == 'Fahrdienst'">FD</template>
                  <template v-if="scope.row.type == 'Sitzdienst'">SD</template> / 
                  {{ scope.row.start | moment('HH:mm') }} - {{ scope.row.end | moment('HH:mm') }}
              </template>
            </el-table-column>
            <el-table-column
            label="Praxis"
            prop="orderer_lastname"
            sortable>
              <template slot-scope="scope">
                  {{ scope.row.orderer_lastname }}, {{ scope.row.orderer_firstname }}
              </template>
            </el-table-column>
            <el-table-column
            label="Vertreter"
            prop="doc_on_duty"
            sortable>
              <template slot-scope="scope">
                  <template v-if="scope.row.doc_on_duty">
                  {{ scope.row.doc_on_duty }}, <template v-if="scope.row.doc_on_duty_title">{{ scope.row.doc_on_duty_title }} </template> {{ scope.row.doc_on_duty_firstname }}
                  </template>
                  <template v-else>
                    <i>offen</i>
                  </template>
              </template>
            </el-table-column>
            <el-table-column
            label="Vertreter Mobil"
            prop="doc_on_duty_mobile"
            sortable>
              <template slot-scope="scope">
                  {{ scope.row.doc_on_duty_mobile }}
              </template>
            </el-table-column>
        </el-table>
    </div>
    <div v-else class="pt-4 pb-4 text-center">
      <img src="/img/undraw_tree.svg" alt="Keine Diente" class="img img-fluid mb-3" width="300px"><br>
      Keine Dienste gefunden<br>
    </div>
  </div>
</template>

<script>
export default {
    props: ['duties'],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
      tableRowClassName ({row, rowIndex}) {
        let momentdate = this.$moment(row.start)
        if (momentdate.isoWeekday() == 6 || momentdate.isoWeekday() == 7 || row.ispublicholiday) {
          return 'highlight-row-report';
        }
        return '';
      }
    }
}
</script>

<style scoped lang="scss">
.table-icon {
    width: 18px;
    height: 18px;
    stroke: #102B43;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-left: 12px;

    &--red {
      stroke: #FE6F5E;
    }
}
</style>