<template>
  <div>
    <div v-if="duties && duties.length > 0">
      <el-table
        v-loading="loading"
        stripe
        :data="duties"
        :default-sort="{prop: 'start', order: 'ascending'}"
        :row-class-name="tableRowClassName"
        style="width: 100%"
      >
        <el-table-column label="Monat" prop="start" sortable width="90px">
          <template slot-scope="scope">{{ scope.row.start | moment('MMM') }}</template>
        </el-table-column>
        <el-table-column label="Start" prop="start" sortable width="120px">
          <template slot-scope="scope">{{ scope.row.start | moment('DD.MM.YY - ddd') }}</template>
        </el-table-column>
        <el-table-column label="Vertreter" prop="doc_on_duty.fullnameReverse" sortable></el-table-column>
        <el-table-column label="Start" prop="status_label" sortable width="120px">
          <template slot-scope="scope">
            <span
              class="badge badge-secondary float-left mr-3"
              v-if="scope.row.status_label == 'offen'"
            >{{ scope.row.status_label }}</span>
            <span
              class="badge badge-warning float-left mr-3"
              v-if="scope.row.status_label == 'vorgemerkt'"
            >{{ scope.row.status_label }}</span>
            <span
              class="badge badge-success float-left mr-3"
              v-if="scope.row.status_label != 'offen' && scope.row.status_label != 'vorgemerkt'"
            >{{ scope.row.status_label }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Notiz" prop="notes" sortable></el-table-column>
        <el-table-column fixed="right" align="right" width="100px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Reserve bearbeiten" placement="top">
              <a @click.prevent="updateReserve(scope.row)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit-2 table-icon"
                >
                  <polygon points="16 3 21 8 8 21 3 21 3 16 16 3" />
                </svg>
              </a>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Reserve löschen"
              placement="top"
              v-if="withdelete"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                @click="deleteReserve(scope.row.id)"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-trash-2 table-icon table-icon--red"
              >
                <polyline points="3 6 5 6 21 6" />
                <path
                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
              </svg>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
    </div>
    <div v-else class="pt-4 pb-4 text-center">
      <img src="/img/undraw_tree.svg" alt="Keine Diente" class="img img-fluid mb-3" width="300px" />
      <br />Keine Reserven gefunden
      <br />
    </div>

    <el-dialog title="Reserve ändern" :visible.sync="showModal" destroy-on-close width="50%">
      <form @submit.prevent ref="updateForm">
        <div class="form-row mb-3">
          <div class="col">
            <el-select
              v-model="updateReserveObject.doc_on_duty_id"
              v-loading="loading"
              filterable
              remote
              focus
              clearable
              ref="doc"
              reserve-keyword
              placeholder="Arzt (Vertreter)"
              :remote-method="findDocOnDuty"
              :loading="loading"
            >
              <!-- <template v-if="this.options.length > 0"> -->
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.lastname + ', ' + item.name + ' ' + item.deleted_label"
                :value="item.id"
              ></el-option>
              <!-- </template>  -->
            </el-select>
            <form-error
              v-if="errors && errors.doc_on_duty_id"
              :errortext="errors.doc_on_duty_id[0]"
            ></form-error>
          </div>
        </div>
        <div
          class="form-row mb-3"
          v-if="updateReserveObject.status == '10_open' || updateReserveObject.status == '15_prebooked'"
        >
          <div class="col">
            <el-switch v-model="updateReserveObject.prebooking" active-text="Nur vormerken"></el-switch>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col">
            <el-date-picker
              v-model="updateReserveObject.start"
              type="date"
              :picker-options="{
                            firstDayOfWeek: 1
                        }"
              format="dd.MM.yyyy"
              start-placeholder="Start"
              class="w-100"
            ></el-date-picker>
            <form-error v-if="errors && errors.start" :errortext="errors.start[0]"></form-error>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col text-center">
            <input
              type="text"
              class="form-control"
              :class="errors && errors.notes ? 'form-error' : ''"
              id="notes"
              placeholder="Notiz zum Dienst / Bonus"
              v-model="updateReserveObject.notes"
            />
            <form-error v-if="errors && errors.notes" :errortext="errors.notes[0]"></form-error>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <button
              class="btn btn-primary"
              :disabled="loading"
              @click="updateFormSubmit"
            >Reserve speichern</button>
            <button class="btn btn-default" @click="toggleModal">Abbrechen</button>
          </div>
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import FormMixin from "../../../FormMixin";

export default {
  mixins: [FormMixin],
  props: ["duties", "withdelete"],
  data() {
    return {
      duties: [],
      loading: false,
      showModal: false,
      options: [],
      updateReserveObject: {
        id: "",
        doc_on_duty_id: "",
        doc_on_duty: {
          id: "",
          name: "",
          lastname: ""
        },
        start: "",
        notes: "",
        prebooking: false
      }
    };
  },
  computed: {
    action() {
      return "/intern/reserves/" + this.updateReserveObject.id;
    },
    fields() {
      return this.updateReserveObject;
    }
  },
  mounted() {
    setTimeout(() => {
      axios.get("/intern/reserves/getAllDocs").then(response => {
        this.options = response.data;
      });
    }, 300);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      let momentdate = this.$moment(row.start);
      if (
        momentdate.isoWeekday() == 6 ||
        momentdate.isoWeekday() == 7 ||
        row.ispublicholiday
      ) {
        return "highlight-row";
      }
      return "";
    },
    findDocOnDuty(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;

          if (query.length > 2) {
            axios
              .get("/intern/reserves/searchUser", { params: { query: query } })
              .then(response => {
                this.options = response.data;
              });
          }
        }, 200);
      } else {
        // this.options = [];
        this.updateOptions(updateReserveObject.doc_on_duty_id);
      }
    },
    updateOptions() {
      // function could be refactored or replaced
      this.loading = true;
      setTimeout(() => {
        axios
          .get("/intern/reserves/getDocById", {
            params: { query: this.updateReserveObject.doc_on_duty_id }
          })
          .then(response => {
            this.options = response.data;
          });
        console.log("Updated Options");
      }, 300);
      this.loading = false;
    },
    updateFormSubmit() {
      this.submit();
    },
    handleResponseResult(response) {
      this.$noty.success("Reserve geändert!");
      this.showModal = false;
      setTimeout(function() {
        window.location.reload(1);
      }, 1000);
    },
    updateReserve(reserve) {
      // this.updateOptions(reserve.doc_on_duty_id)
      this.updateReserveObject = reserve;
      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    deleteReserve(id, index) {
      this.$confirm("Möchtest du die Reserve endgültig löschen?", "Warnung", {
        confirmButtonText: "Ja, jetzt löschen",
        cancelButtonText: "Abbrechen",
        type: "warning"
      })
        .then(() => {
          // Delete the person and refresh page
          var url = "/intern/reserves/" + id;
          axios
            .delete(url)
            .then(response => {
              console.log("Item deleted with id " + id);
              this.$emit("reservedeleted", id);
              this.$noty.success("Person wurde erfolgreich gelöscht!");
            })
            .catch(error => {
              console.log(error);
              this.$noty.error(
                "Fehler beim Löschen! Bitte noch einmal versuchen."
              );
            });
        })
        .catch(() => {
          this.$noty.info("Löschen abgebrochen!");
        });
    }
  }
};
</script>

<style scoped lang="scss">
.table-icon {
  width: 18px;
  height: 18px;
  stroke: #102b43;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  cursor: pointer;
  margin-left: 12px;

  &--red {
    stroke: #fe6f5e;
  }
}

.el-select {
  display: input;
  width: 100%;
}
</style>
